import React, { Key } from 'react';
import { Radio, Space, theme } from 'antd';
import { useDispatch } from 'react-redux';
import { gapsOp } from 'store/ducks/Gaps';
import { clientReqOp } from 'store/ducks/ClientReq';
import * as Styled from './NestedTableFilterDropdown.styled';

const { useToken } = theme;

interface IFilterDropdownProps {
  setSelectedKeys: (selectedKeys: Key[]) => void;
  selectedKeys: Key[];
  confirm: any;
  options: IOption[];
  defaultValue: string;
  filterName: string;
}

interface IOption {
  value: string;
  text: string;
}

export const NestedTableFilterDropdown: React.FC<IFilterDropdownProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  options,
  defaultValue,
  filterName
}) => {
  const dispatch = useDispatch();
  const { token } = useToken();

  const handleFilterChange = (
    e: any,
    setFilters: (selectedKeys: Key[]) => void
  ) => {
    setFilters(e.target.value);
  };

  return (
    <Styled.StyledMainDiv token={token} onKeyDown={(e) => e.stopPropagation()}>
      <Radio.Group
        onChange={(e) => handleFilterChange(e, setSelectedKeys)}
        defaultValue={defaultValue}
      >
        <Space direction='vertical'>
          {options.map((option: IOption) => (
            <Radio key={option.value} value={option.value}>
              {option.text}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      <Styled.StyledDiv token={token}>
        <Styled.StyledButton
          type='primary'
          size='small'
          onClick={() => {
            confirm({ closeDropdown: true });
            filterName === 'gapNoticed'
              ? dispatch(gapsOp.setTableFilter({ [filterName]: selectedKeys }))
              : dispatch(
                  clientReqOp.setTableFilter({ [filterName]: selectedKeys })
                );
          }}
        >
          OK
        </Styled.StyledButton>
      </Styled.StyledDiv>
    </Styled.StyledMainDiv>
  );
};
