import { IClientReqState } from './types';

const initialClientReqState = (): IClientReqState => ({
  appliedFiltersResult: [],
  applicabilityStatuses: {},
  checkedElementsToBeMerged: [],
  checkedElementsToBeCopied: [],
  mergedElements: [],
  copiedElements: [],
  tableFilters: {
    applicability: ['all']
  },
  filteredData: [],
  checkedAurReqs: [],
  clientReqs: {},
  updated: false,
  error: null,
  loading: false
});

export default initialClientReqState;
