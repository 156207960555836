import styled from 'styled-components';
import { Button, Card } from 'antd';
import { IToken } from 'store/ducks/globalTypes';

const { Meta } = Card;

export const StyledLinkButton = styled(Button)`
  text-decoration: none;
`;

export const StyledCard = styled(Card)<IToken>`
  width: 300px;
  justify-content: center;
  border-radius: 15px;

  .ant-card-body {
    padding: ${(props) => `${props.token.paddingXXS}px`};
  }

  .ant-card-cover {
    width: 180px;
    height: 100px;
    margin: auto;
  }
`;

export const StyledMeta = styled(Meta)<IToken>`
  .ant-card-meta-title {
    font-weight: 400;
    margin-bottom: ${(props) => `${props.token.marginXXS}px`};
    text-align: center;
  }

  .ant-card-meta-description {
    padding: 0 10px 10px;
  }
`;
