import { CategoryList } from 'store/ducks/globalTypes';

const categoryList = [
  { label: CategoryList.OUTSOURCING, value: 'Outsourcing' },
  { label: CategoryList.IT_SECURITY, value: 'ITsecurity' },
  {
    label: CategoryList.BUSINESS_MANAGEMENT,
    value: 'Businesscontinuitymanagement'
  },
  { label: CategoryList.GENERAL_CATEGORY, value: 'General Category' },
  { label: CategoryList.DEFAULT_VALUE, value: 'Default Value' }
];

export default categoryList;
