import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommentItem, IComplianceEstState } from './types';
import initialComplianceEstState from './initialState';
import { IFilters } from '../globalTypes';

const complianceEstSlice = createSlice({
  name: 'complianceEst',
  initialState: initialComplianceEstState(),
  reducers: {
    set_draw_table(state: IComplianceEstState, action: PayloadAction<boolean>) {
      state.drawTable = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_save_disabled(
      state: IComplianceEstState,
      action: PayloadAction<boolean>
    ) {
      state.saveDisabled = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_comments_data(
      state: IComplianceEstState,
      action: PayloadAction<CommentItem[]>
    ) {
      state.commentsData = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_save_clicked(
      state: IComplianceEstState,
      action: PayloadAction<boolean>
    ) {
      state.saveClicked = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_filters_data(
      state: IComplianceEstState,
      action: PayloadAction<IFilters[]>
    ) {
      state.filtersData = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_loading(state: IComplianceEstState) {
      state.loading = true;
    },
    set_error(state: IComplianceEstState, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export default complianceEstSlice;
