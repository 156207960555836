import { notification } from 'antd';
import { Dispatch } from '@reduxjs/toolkit';
import complianceEstSlice from './complianceEstSlice';
import { CommentItem, CommentTable } from './types';
import ApiService from 'utils/ApiCalls';
import Helpers from 'utils/Helpers';
import { IFilters, LanguageList } from '../globalTypes';

interface IEntityProps {
  [key: string]: number;
}

const {
  set_filters_data,
  set_draw_table,
  set_loading,
  set_save_disabled,
  set_comments_data,
  set_save_clicked
} = complianceEstSlice.actions;

const setFilters = (lang: LanguageList) => async (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    const response = await ApiService.getFilters(lang);

    let fetchedFilters: IFilters[] = [];

    if (response.data && !Helpers.isObjEmpty(response.data)) {
      fetchedFilters = Object.keys(response.data).map((key) => {
        let filterName, filterOptions;

        filterName = key.charAt(0).toUpperCase() + key.slice(1);
        filterOptions = response.data[key];

        const options = filterOptions.map((option: string | IEntityProps) => {
          const value =
            typeof option === 'string' ? option : Object.keys(option)[0];

          return {
            value: value,
            label: value
          };
        });

        return {
          filter: filterName,
          options: options
        };
      });
    }

    dispatch(set_filters_data(fetchedFilters));
  } catch (error) {
    console.error(error);
  }
};

const applyFiltersApi = async (lang: LanguageList) => {
  try {
    const response = await ApiService.applyFilters(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchComments = async (table: CommentTable) => {
  try {
    const response = await ApiService.getComments(table);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const saveComment = async (data: CommentItem[]) => {
  try {
    const response = await ApiService.addComment(data);

    notification.success({
      message: response.status,
      description: response.data.message
    });
  } catch (error) {
    console.error(error);
  }
};

const deleteComment = async (comment_id: number) => {
  try {
    const response = await ApiService.removeComment(comment_id);

    notification.success({
      message: response.status,
      description: response.data.message
    });
  } catch (error) {
    console.error(error);
  }
};

const setDrawTable = (draw: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_draw_table(draw));
  } catch (error) {
    console.error(error);
  }
};

export const setSaveDisabled = (disabled: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_save_disabled(disabled));
  } catch (error) {
    console.error(error);
  }
};

const setCommentsData =
  (commentsData: CommentItem[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_comments_data(commentsData));
    } catch (error) {
      console.error(error);
    }
  };

const setSaveClicked = (saveClicked: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_save_clicked(saveClicked));
  } catch (error) {
    console.error(error);
  }
};

export default {
  setFilters,
  applyFiltersApi,
  fetchComments,
  saveComment,
  deleteComment,
  setDrawTable,
  setSaveDisabled,
  setCommentsData,
  setSaveClicked
};
