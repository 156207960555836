import i18n from 'components/translation/i18n';

function FilterNames() {
  const i18nInitialization = i18n();

  const filterNames = [
    i18nInitialization.t('INDUSTRY'),
    i18nInitialization.t('COUNTRY'),
    i18nInitialization.t('MODULE'),
    i18nInitialization.t('ENTITY'),
    i18nInitialization.t('CATEGORY'),
    i18nInitialization.t('PRODUCT'),
    i18nInitialization.t('REGULATOR')
  ];

  return filterNames;
}

export default FilterNames;
