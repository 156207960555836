import styled from 'styled-components';
import { Tag } from 'antd';
import CustomTable from '../CustomTable';
import TextBox from 'components/TextBox';

export const StyledCustomTable = styled(CustomTable)`
  .ant-table-row-expand-icon-cell {
    display: none;
  }

  .ant-table-row-level-0 {
    > .ant-table-cell {
      padding: 0 16px;

      > div {
        padding: 0 16px;
      }
    }
  }

  .ant-table-filter-column {
    flex-direction: row-reverse;

    .ant-dropdown-trigger {
      margin-inline: 0 5px;
    }
  }

  .ant-table-wrapper {
    .ant-table {
      margin: unset !important;
    }
  }

  .ant-spin-nested-loading {
    overflow-y: initial !important;
  }
`;

export const StyledTag = styled(Tag)`
  width: 100px;
  padding: 0 9px;
  font-size: 14px;
  text-align: center;
`;

export const StyledTextBox = styled(TextBox)`
  height: 0;
  padding: unset !important;
`;
