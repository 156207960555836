import React, { useState } from 'react';
import { Input, Form, theme } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import AuthLayout from 'components/Layouts/AuthLayout';
import { globalOp } from 'store/ducks/Global';
import * as Styled from './ForgotPass.styled';

const { useToken } = theme;

const ForgotPass: React.FC = () => {
  const { token } = useToken();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    globalOp.forgotPass(email);
  };

  return (
    <AuthLayout>
      <>
        <Styled.InfoContainer>
          <InfoCircleTwoTone />
          Please enter you email to reset password
        </Styled.InfoContainer>
        <Form form={form} name='login'>
          <Styled.StyledFormItem
            token={token}
            label='Email'
            name='email'
            rules={[
              {
                required: true,
                message: 'Please input your email!'
              }
            ]}
          >
            <Input
              type='email'
              onChange={(event) => setEmail(event.target.value)}
            />
          </Styled.StyledFormItem>
          <Styled.ButtonContainer>
            <Styled.StyledButton type='primary' onClick={handleSubmit}>
              Submit
            </Styled.StyledButton>
          </Styled.ButtonContainer>
        </Form>
      </>
    </AuthLayout>
  );
};

export default ForgotPass;
