import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { theme } from 'antd';
import ReportingLayout from 'components/Layouts/ReportingLayout/ReportingLayout';
import * as Styled from './Reporting.styled';
import TextBox from 'components/TextBox';
import { reportingOp } from 'store/ducks/Reporting';
import { RootState } from 'store/configureStore';

const { useToken } = theme;

const Reporting: React.FC = () => {
  const { token } = useToken();

  const { lang } = useSelector((state: RootState) => ({
    lang: state.global.lang
  }));

  const generateExcelData = () => {
    const data = [
      ['Column 1', 'Column 2', 'Column 3'],
      ['data for col. 1 ', 'data for col. 1', 'john@data for col. 1'],
      ['data for col. 2 ', 'data for col. 2', 'john@data for col. 2']
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    return wb;
  };

  const handleDownloadExcel = () => {
    const wb = generateExcelData();
    XLSX.writeFile(wb, 'tasks_gaps_solutions.xlsx');
  };

  useEffect(() => {
    reportingOp.fetchReport(lang).then((response) => {});
  }, []);

  return (
    <ReportingLayout>
      <Styled.ReportingRow>
        <Styled.TextBoxContainer token={token} width={300}>
          <h2>Information to be selected</h2>
          <Styled.TextBoxP token={token}>Solutions</Styled.TextBoxP>
          <Styled.TextBoxP token={token}>Evidences</Styled.TextBoxP>
          <Styled.TextBoxP token={token}>Business rules</Styled.TextBoxP>
        </Styled.TextBoxContainer>
        <TextBox width={300}>
          <h2>Information for reports</h2>
          <Styled.TextBoxP token={token}>Tasks</Styled.TextBoxP>
        </TextBox>
        <Styled.ButtonDiv>
          <Styled.ButtonElement type='primary' onClick={handleDownloadExcel}>
            tasks_gaps_solutions
          </Styled.ButtonElement>

          <Styled.ButtonElement type='primary'>tasks_gaps</Styled.ButtonElement>

          <Styled.ButtonElement type='primary'>
            gaps_evidences
          </Styled.ButtonElement>

          <Styled.ButtonElement type='primary'>
            business_rules_gaps_audit_findings_solutions
          </Styled.ButtonElement>
        </Styled.ButtonDiv>
      </Styled.ReportingRow>
    </ReportingLayout>
  );
};

export default Reporting;

// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import * as XLSX from 'xlsx';
// import { theme, Modal } from 'antd';
// import ReportingLayout from 'components/Layouts/ReportingLayout/ReportingLayout';
// import * as Styled from './Reporting.styled';
// import {
//   DndContext,
//   DragOverlay,
//   PointerSensor,
//   useSensor,
//   useSensors,
//   DragStartEvent,
//   DragEndEvent,
//   TouchSensor,
//   closestCenter
// } from '@dnd-kit/core';
// import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
// import Item from './DND/Item';
// import SortableItem from './DND/SortableItem';

// import TextBox from 'components/TextBox';
// import { reportingOp } from 'store/ducks/Reporting';
// import { RootState } from 'store/configureStore';

// export type TItem = {
//   id: number;
//   text: string;
// };
// const defaultItems1 = [
//   {
//     id: 1,
//     text: 'Solutions'
//   },
//   {
//     id: 2,
//     text: 'Evidences'
//   },
//   {
//     id: 3,
//     text: 'Business Rules'
//   }
// ];
// const defaultItems2 = [
//   {
//     id: 4,
//     text: 'Tasks'
//   }
// ];
// const Reporting: React.FC = () => {
//   const [items1, setItems1] = useState<TItem[]>(defaultItems1);
//   const [items2, setItems2] = useState<TItem[]>(defaultItems2);
//   const { useToken } = theme;

//   const { lang } = useSelector((state: RootState) => ({
//     lang: state.global.lang
//   }));

//   const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));
//   // for drag overlay
//   const [activeItem, setActiveItem] = useState<TItem>();

//   // triggered when dragging starts
//   const handleDragStart = (event: DragStartEvent) => {
//     const { active } = event;
//     const itemId = Number(active.id);
//     const item =
//       items1.find((item1) => item1.id === itemId) ||
//       items2.find((item2) => item2.id === itemId);
//     setActiveItem(item);
//   };

//   const handleDragEnd = (event: DragEndEvent) => {
//     const { active, over } = event;
//     const activeItem1 = items1.find((item) => item.id === active.id);
//     const activeItem2 = items2.find((item) => item.id === active.id);
//     const overItem1 = items1.find((item) => item.id === over?.id);
//     const overItem2 = items2.find((item) => item.id === over?.id);

//     if (activeItem1 && overItem2) {
//       setItems1((prevItems) =>
//         prevItems.filter((item) => item.id !== active.id)
//       );
//       setItems2((prevItems) => [...prevItems, activeItem1]);
//     } else if (activeItem2 && overItem1) {
//       setItems2((prevItems) =>
//         prevItems.filter((item) => item.id !== active.id)
//       );
//       setItems1((prevItems) => [...prevItems, activeItem2]);
//     }

//     if (items1.length === 0) {
//       setItems1([{ id: 0, text: '' }]);
//     }
//     if (items2.length === 0) {
//       setItems2([{ id: 0, text: '' }]);
//     }

//     setActiveItem(undefined);
//   };

//   useEffect(() => {
//     reportingOp.fetchReport(lang).then((response) => {
//       console.log(response, 'response of reports');
//     });
//   }, [lang]);

//   return (
//     <ReportingLayout>
//       <Styled.ReportingRow>
//         <DndContext
//           sensors={sensors}
//           collisionDetection={closestCenter}
//           onDragStart={handleDragStart}
//           onDragEnd={handleDragEnd}
//         >
//           <Styled.DNDRow>
//             <Styled.DNDElement>
//               <SortableContext items={items1} strategy={rectSortingStrategy}>
//                 {items1.map((item) => (
//                   <SortableItem key={item.id} item={item} />
//                 ))}
//               </SortableContext>
//             </Styled.DNDElement>
//             <Styled.DNDElement>
//               <SortableContext items={items2} strategy={rectSortingStrategy}>
//                 {items2.map((item) => (
//                   <SortableItem key={item.id} item={item} />
//                 ))}
//               </SortableContext>
//             </Styled.DNDElement>
//           </Styled.DNDRow>
//           <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
//             {activeItem ? <Item item={activeItem} isDragging /> : null}
//           </DragOverlay>
//         </DndContext>
//       </Styled.ReportingRow>
//     </ReportingLayout>
//   );
// };

// export default Reporting;
