import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, theme } from 'antd';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import CustomSelect from 'components/CustomSelect';
import { gapsOp } from 'store/ducks/Gaps';
import { RootState } from 'store/configureStore';
import { clientReqOp } from 'store/ducks/ClientReq';
import { IFilters } from 'store/ducks/globalTypes';
import Helpers from 'utils/Helpers';
import * as Styled from './SelectModule.styled';

const { useToken } = theme;

const FiltersParam = withDefault(ArrayParam, []);

const SelectModule = () => {
  const dispatch = useDispatch();
  const { token } = useToken();

  const { filtersData, lang } = useSelector((state: RootState) => ({
    lang: state.global.lang,
    filtersData: state.complianceEst.filtersData
  }));

  const [hasChanged, setHasChanged] = useState(false);
  const [query, setQuery] = useQueryParams({
    industry: FiltersParam,
    country: FiltersParam,
    module: FiltersParam,
    entity: FiltersParam,
    category: FiltersParam,
    product: FiltersParam,
    regulator: FiltersParam
  });

  const handleChange = (value: string[], item: string) => {
    setQuery((latestQuery: any) => ({
      ...latestQuery,
      [item.toLowerCase()]: value
    }));

    setHasChanged(true);
  };

  const getFilterObj = (item: string): IFilters[] =>
    filtersData.filter((obj: IFilters) => obj.filter === item);

  const applyFilters = () => {
    if (!Helpers.isObjEmpty(query)) {
      gapsOp.applyFiltersModuleApi(query, lang).then((data) => {
        dispatch(clientReqOp.setAppliedFilersResult(data));
        if (hasChanged) {
          dispatch(gapsOp.setSelectedEvidenceModule(true));
        }
      });
      dispatch(gapsOp.setSelectedEvidenceModule(false));
    }
  };

  return (
    <Row>
      <Col lg={16}>
        <CustomSelect
          mode='multiple'
          placeholder='Module'
          onChange={(value: string[]) => handleChange(value, 'Module')}
          options={
            getFilterObj('Module').length && getFilterObj('Module')[0]?.options
          }
          filters
        />
      </Col>
      <Styled.StyledCol token={token} lg={16}>
        <Styled.StyledButton
          token={token}
          type='primary'
          onClick={applyFilters}
        >
          Select Module
        </Styled.StyledButton>
      </Styled.StyledCol>
    </Row>
  );
};

export default SelectModule;
