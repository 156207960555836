import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

const newTaskObj = (solId: number) => ({
  tsk_short_name: '',
  tsk_desc: '',
  tsk_tkt_ref: 'IT',
  tsk_tst_ref: 'Pending',
  tsk_owner: 'TBD@TBD.comq',
  tsk_due_date: dayjs().format(dateFormat).toString(),
  tsk_sol_ref: solId
});

export default newTaskObj;
