import React from 'react';
import { theme } from 'antd';
import { useDispatch } from 'react-redux';
import CustomTitle from '../CustomTitle';
import CustomSelect from '../CustomSelect';
import { LanguageList, ThemeList, TitleList } from 'store/ducks/globalTypes';
import { globalOp } from 'store/ducks/Global';
import ColorPalettes from 'constants/colorPalettes';
import languagesPage from 'constants/languages';
import * as Styled from './TitleBar.styled';

const { useToken } = theme;

interface ITitleBarProps {
  title: TitleList;
}

const TitleBar: React.FC<ITitleBarProps> = ({ title }) => {
  const { token } = useToken();
  const dispatch = useDispatch();
  const colorPalettes = ColorPalettes();
  const handleThemeChange = (value: ThemeList) => {
    localStorage.setItem('theme', value);
    dispatch(globalOp.setTheme(value));
  };

  const handleLangChange = (value: LanguageList) => {
    localStorage.setItem('lang', value);
    dispatch(globalOp.setLang(value));
  };

  const languages = languagesPage();

  return (
    <Styled.StyledNav token={token}>
      <Styled.StyledLogo />
      <CustomTitle title={title} level={2} />
      <Styled.DropdownContainer>
        <CustomSelect
          size='md'
          options={languages}
          defaultValue={localStorage.getItem('lang')}
          onChange={handleLangChange}
        />
        <CustomSelect
          size='lg'
          options={colorPalettes}
          defaultValue={localStorage.getItem('theme')}
          onChange={handleThemeChange}
        />
      </Styled.DropdownContainer>
    </Styled.StyledNav>
  );
};

export default TitleBar;
