import styled from 'styled-components';
import { Row } from 'antd';
import TextBox from 'components/TextBox';
import { IToken } from 'store/ducks/globalTypes';

interface IItemWidth extends IToken {
  width?: number;
}

export const CheckboxItem = styled('div')<IItemWidth>`
  height: 60px;
  width: ${(props) => (props.width ? `${props.width}px` : '85px')};
  padding: ${(props) => `0 ${props.token.padding}px`};
`;

export const ContentWrapper = styled('div')<IToken>`
  width: 100%;
  padding: ${(props) => `${props.token.paddingLG}px`};

  .ant-table-wrapper .ant-table {
    .ant-table-filter-column {
      flex-direction: row-reverse;

      .ant-dropdown-trigger {
        width: 27px;
        margin-inline: 0 5px;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="8 10 10 20" fill="white"><path d="M11.97 3c-.27 0-.52.11-.71.29L6.7 7.7a1.003 1.003 0 0 0 1.41 1.42l4.65-4.65 4.65 4.65a1.003 1.003 0 1 0 1.42-1.42l-5.53-5.53c-.18-.18-.43-.29-.7-.29zm0 18c.27 0 .52-.11.71-.29l4.65-4.65a1.003 1.003 0 1 0-1.42-1.42l-4.24 4.24-4.24-4.24a1.003 1.003 0 1 0-1.42 1.42l4.65 4.65c.19.18.44.29.71.29z"/></svg>');
        background-repeat: no-repeat;
        background-size: 96% 95%;
        top: 4px;
        span svg {
          display: none !important;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
    .ant-table-content {
      border-radius: 10px
    }
    .ant-table-thead tr .ant-table-cell{
      white-space: nowrap;
    }
`;

export const Container = styled('div')`
  padding: 0 15px;
`;

export const MergedColumnsContainer = styled('div')`
  display: flex;
  gap: 5px;
`;

export const MergedColumnSm = styled('div')<IItemWidth>`
  width: ${(props) => (props.width ? `${props.width}px` : '80px')};
  position: relative;
  padding: ${(props) => `0 ${props.token.padding}px`};

  &:before {
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    width: 1px;
    height: 1.6em;
    background-color: ${(props) => props.token.colorBgMask};
    transform: translateY(-50%);
    transition: background-color 0.2s;
    content: '';
    display: block;
  }
`;

export const MergedColumnMd = styled('div')<IToken>`
  width: 400px;
  padding: ${(props) => `0 ${props.token.padding}px`};
`;

export const MergedColumnLg = styled('div')<IItemWidth>`
  width: ${(props) => (props.width ? `${props.width}px` : '515px')};
  position: relative;
  padding: ${(props) => `0 ${props.token.padding}px`};

  &:before {
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    width: 1px;
    height: 1.6em;
    background-color: ${(props) => props.token.colorBgMask};
    transform: translateY(-50%);
    transition: background-color 0.2s;
    content: '';
    display: block;
  }
`;

export const CheckboxContainer = styled('div')`
  min-width: 116px;
  display: flex;
  justify-content: flex-start;
`;

export const ColumnTextBox = styled(TextBox)`
  text-align: left;
`;

export const AurReqsTextBox = styled(TextBox)<IToken>`
  text-align: left;
  height: fit-content;
`;

export const StyledRow = styled(Row)<IToken>`
  margin-bottom: ${(props) => `${props.token.marginMD}px`};
  padding: 0 !important;
  flex-flow: nowrap;
`;
