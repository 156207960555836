import { ISolutionsState } from './types';

const initialSolutionsState = (): ISolutionsState => ({
  solutions: [],
  solutionsToShow: {},
  solutionsToSave: {},
  error: null,
  loading: false
});

export default initialSolutionsState;
