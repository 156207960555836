const filterNames = [
  'Industry',
  'Country',
  'Module',
  'Entity',
  'Category',
  'Product',
  'Regulator'
];

export default filterNames;
