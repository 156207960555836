import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';
import CustomIconButton from '../CustomIconButton';

export const StyledContainer = styled('div')<{
  withCheckbox?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-width: ${(props) => (props.withCheckbox ? '501px' : '337px')};
  padding: unset !important;
`;

export const CheckboxContainer = styled('div')`
  min-width: 116px;
  margin-right: 16px;
  display: flex;
  justify-content: flex-start;
`;

export const StyledButtonSection = styled('div')<IToken>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => `${props.token.marginSM}px`};
`;

export const TextAreaElementsContainer = styled('div')`
  display: flex;
`;

export const TextAreaContainer = styled('div')<{
  withCheckbox?: boolean;
}>`
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  min-width: 337px;
  max-width: ${(props) => (props.withCheckbox ? '337px' : '100%')};

  .ant-input {
    ::placeholder {
      color: white;
    }
  }
`;

export const StyledCustomIconButton = styled(CustomIconButton)<IToken>`
  margin-top: ${(props) => `${props.token.marginXXS}px`};
`;

export const ButtonContainer = styled('div')<{
  withCheckbox?: boolean;
}>`
  min-width: 367px;
  display: flex;
  justify-content: center;
`;

export const FlexContainer = styled('div')<{
  withCheckbox?: boolean;
}>`
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
`;
