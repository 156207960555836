import { YesNo } from 'store/ducks/globalTypes';

const newEvidenceObj = (regReqId: number, solId: number, evdId: string) => ({
  evd_file: [],
  bru_id: regReqId,
  sol_id: solId,
  evd_valid_from: null,
  evd_valid_to: null,
  evd_approver_ref: 'TBD@TBD.comq',
  evd_desc: '',
  evd_reviewer_ref: 'TBD@TBD.comq',
  evd_id: evdId,
  evd_reviewed: YesNo.YES,
  evd_approved: YesNo.NO
});

export default newEvidenceObj;
