import { IComplianceEstState } from './types';

const initialComplianceEstState = (): IComplianceEstState => ({
  error: null,
  loading: false,
  filtersData: [],
  drawTable: false,
  saveDisabled: true,
  commentsData: [],
  saveClicked: false
});

export default initialComplianceEstState;
