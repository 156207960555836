import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { theme } from 'antd';
import CustomSelect from 'components/CustomSelect';
import CustomDatePicker from 'components/CustomDatePicker';
import { IAudits } from 'store/ducks/Gaps/types';
import * as Styled from './AuditsSelectBox.styled';

const { useToken } = theme;

export interface IAuditsSelectBoxProps {
  audits: IAudits[];
  valueKey: string;
  dateEl?: boolean;
}

export const AuditsSelectBox: React.FC<IAuditsSelectBoxProps> = ({
  audits,
  valueKey,
  dateEl = false
}) => {
  const { token } = useToken();

  return (
    <Styled.Container>
      {audits.map((audit: IAudits) => (
        <Styled.InfoContainer key={uuidv4()}>
          <Styled.IdContainer token={token}>
            <Styled.StyledSpan className='color-typography' token={token}>
              Id:
            </Styled.StyledSpan>
            <span className='color-typography'>{audit.afg_id}</span>
          </Styled.IdContainer>
          {dateEl ? (
            <CustomDatePicker defaultValue={audit.afg_due_date} disabled />
          ) : (
            <CustomSelect
              size='md'
              options={[]}
              defaultValue={audit[valueKey as keyof typeof audit]}
              disabled
            />
          )}
        </Styled.InfoContainer>
      ))}
    </Styled.Container>
  );
};
