import styled from 'styled-components';
import { Button, Layout } from 'antd';
import { ReactComponent as ReactBrightLogo } from 'assets/svg/brightlogo1.svg';
import { IToken, ThemeList } from 'store/ducks/globalTypes';
import { variablesList } from 'constants/variablesList';
import { IThemeSelectorProps } from 'components/ThemeSelector/ThemeSelector';

interface StyledLayoutProps {
  filtersvisible: boolean;
}
const { Header } = Layout;

export const StyledLayout = styled(Layout)<IToken & StyledLayoutProps>`
  min-height: 100vh;

  > .ant-layout {
    min-height: initial;

    .ant-layout-header {
      height: fit-content;
    }
  }
  aside {
    padding-right: ${(props) => `${props.token.paddingLG}px`};
    flex: initial !important;
    text-align: center;
    max-width: fit-content !important;
    min-width: fit-content !important;
    width: fit-content !important;
    position: relative !important;
    right: 0px;

    ${({ filtersvisible }) =>
      !filtersvisible &&
      `
    position: absolute !important;
    right: 0px;
  `}
  }
`;

export const StyledHeader = styled(Header)<IToken>`
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-inline: ${(props) => `${props.token.paddingLG}px`};
  line-height: ${(props) => `${props.token.lineHeightHeading2}px`};
`;

export const StyledButton = styled(Button)`
  width: 110px;
  text-transform: uppercase;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
`;

export const FilterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  gap: 8px;
`;

export const StyledLogo = styled(ReactBrightLogo)`
  position: absolute;
  left: -50px;
`;

export const HorizontalLayout = styled('div')<IThemeSelectorProps>`
  padding: 0 24px 24px;
  border-bottom: 1px solid
    ${(props) => variablesList[props.theme as ThemeList].defaultTabBg};
`;

export const OpenCloseButton = styled(Button)`
  width: 30px;
  text-transform: uppercase;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
`;
