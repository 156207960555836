import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import {
  ISolution,
  ISolutions,
  ISolutionsResult,
  ISolutionsState
} from './types';
import initialSolutionsState from './initialState';
import Helpers from 'utils/Helpers';
import newSolObj from 'constants/newSolution';

const solutionsSlice = createSlice({
  name: 'solutions',
  initialState: initialSolutionsState(),
  reducers: {
    set_solutions(
      state: ISolutionsState,
      action: PayloadAction<ISolutionsResult[]>
    ) {
      state.solutions = action.payload;
      state.loading = false;
    },
    set_solutions_to_save(
      state: ISolutionsState,
      action: PayloadAction<ISolutionsResult[]>
    ) {
      state.solutionsToShow = action.payload;
      state.loading = false;
    },
    edit_solution(
      state: ISolutionsState,
      action: PayloadAction<{
        solId: string | number;
        solData: ISolution;
        key: string;
        value: string;
      }>
    ) {
      const oldState = current(state);
      const { solId, solData, key, value } = action.payload;
      const { reviewer, sol_approved, ...rest } = solData;

      state.solutionsToSave = {
        ...oldState.solutionsToSave,
        [solId]: {
          ...rest,
          [key]: value
        }
      };
      state.loading = false;
    },
    reset_solutions_to_save(state: ISolutionsState) {
      state.solutionsToSave = {};
    },
    add_new_solution(state: ISolutionsState, action: PayloadAction<number>) {
      const oldState = current(state);
      const regReqObj = oldState.solutions.filter(
        (item: ISolutions) => item.id === action.payload
      )[0];

      const newId = uuidv4();

      state.solutionsToShow = Helpers.isObjEmpty(
        oldState.solutionsToShow[action.payload]
      )
        ? {
            ...oldState.solutionsToShow,
            [action.payload]: {
              [newId]: {
                ...regReqObj,
                sol_id: newId,
                SOLUTIONS: newSolObj(regReqObj.id)
              }
            }
          }
        : {
            ...oldState.solutionsToShow,
            [action.payload]: {
              ...oldState.solutionsToShow[action.payload],
              [newId]: {
                ...regReqObj,
                sol_id: newId,
                SOLUTIONS: newSolObj(regReqObj.id)
              }
            }
          };
      state.loading = false;
    },
    set_loading(state: ISolutionsState) {
      state.loading = true;
    },
    set_error(state: ISolutionsState, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export default solutionsSlice;
