import styled from 'styled-components';
import { ITextBoxProps } from './TextBox';
import levels from 'constants/levels';

export const StyledBox = styled('div')<ITextBoxProps>`
  color: ${(props) => props.token.colorPrimaryText};
  padding: ${(props) => `${props.token.padding}px`};
  background: ${(props) => props.token.colorFillContent};
  border-radius: 8px;
  margin-bottom: ${(props) => `${props.token.borderRadiusMD}px`};
  font-size: ${(props) =>
    props.level && Object.keys(levels).includes(props.level.toString())
      ? levels[props.level]
      : '11px'};
  width: ${(props) =>
    // props.width ? `${props.width}px !important` : 'fit-content'};
    props.width ? `${props.width}px !important` : '-webkit-fill-available'};
  max-width: ${(props) =>
    // props.width ? `${props.width}px !important` : 'fit-content'};
    props.width ? `${props.width}px !important` : '-webkit-fill-available'};
`;
