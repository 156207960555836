import React from 'react';
import { Input, theme } from 'antd';
import * as Styled from './CustomTextarea.styled';

const { useToken } = theme;
const { TextArea } = Input;

interface ICustomTextareaProps {
  placeholder: string;
  onChange?: (e: any) => void;
  [rest: string]: any;
}

export const CustomTextarea: React.FC<ICustomTextareaProps> = ({
  placeholder,
  onChange,
  ...rest
}) => {
  const { token } = useToken();

  return (
    <Styled.TextareaWrapper token={token}>
      <TextArea
        rows={1}
        placeholder={placeholder}
        onChange={onChange}
        {...rest}
      />
    </Styled.TextareaWrapper>
  );
};
