import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Dayjs } from 'dayjs';
import CustomTable from '../CustomTable';
import TextBox from 'components/TextBox';
import NestedTable from '../NestedTable';
import CustomSelect from 'components/CustomSelect';
import additionalInfoSaveKeys from 'constants/additionalInfoSaveKeys';
import Helpers from 'utils/Helpers';
import CustomDatePicker from 'components/CustomDatePicker';
import AuditsSelectBox from 'components/AuditsSelectBox';
import { RootState } from 'store/configureStore';
import { gapsOp } from 'store/ducks/Gaps';
import {
  IAdditionalInfo,
  IAdditionalInfoData,
  IAudits
} from 'store/ducks/Gaps/types';
import { YesNo } from 'store/ducks/globalTypes';
import { globalOp } from 'store/ducks/Global';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import { GapAdditionalInfoColumns } from 'constants/tableColumns';
import * as Styled from './AdditionalInfoTable.styled';

const AdditionalInfoTable: React.FC = () => {
  const dispatch = useDispatch();

  const [prioritization, setPrioritization] = useState<string[]>([]);
  const [gapSource, setGapSource] = useState<string[]>([]);
  const [ownersList, setOwnersList] = useState<string[]>([]);
  const gapAdditionalInfoColumns = GapAdditionalInfoColumns();

  const {
    lang,
    drawTable,
    additionalInfo,
    saveClicked,
    infoSave,
    infoUpdated,
    loading
  } = useSelector((state: RootState) => ({
    lang: state.global.lang,
    drawTable: state.complianceEst.drawTable,
    loading: state.gaps.loading,
    additionalInfo: state.gaps.additionalInfo,
    saveClicked: state.complianceEst.saveClicked,
    infoSave: state.gaps.infoSave,
    infoUpdated: state.gaps.infoUpdated
  }));

  useEffect(() => {
    if (!Helpers.isObjEmpty(infoSave) && saveClicked) {
      gapsOp.saveGapsInfo(Object.values(infoSave)).then(() => {
        dispatch(complianceEstOp.setSaveClicked(false));
        dispatch(gapsOp.setInfoSaveData({}));
      });
    }
  }, [saveClicked]);

  const handleSelectChange = (
    value: string,
    type: string,
    data: IAdditionalInfo
  ) => {
    const updatedData = () => {
      const newState = { ...infoSave };

      const newData = additionalInfoSaveKeys.reduce(
        (obj, item) =>
          Object.assign(obj, { [item]: data[item as keyof typeof data] }),
        {}
      );

      newState[data.gap_id as number] = { ...newData, [type]: value };

      return newState;
    };

    dispatch(gapsOp.setInfoSaveData(updatedData()));
  };

  const expandedRow = (record: IAdditionalInfoData) => {
    const dataArr: IAdditionalInfo[] = Object.values(record)[0];

    const nestedTableColumns = [
      {
        title: 'Id',
        dataIndex: 'gap_id',
        key: 'gap_id',
        width: 80,
        render: (id: number) => <TextBox width={80}>{id}</TextBox>
      },
      {
        title: 'Gaps',
        dataIndex: 'gap_desc',
        key: 'gap_desc',
        width: 335,
        render: (desc: string) => <TextBox width={335}>{desc}</TextBox>
      },
      {
        title: 'Gap Due Date',
        dataIndex: 'gap_due_date',
        key: 'gap_due_date',
        width: 120,
        render: (dueDate: string, dataObj: IAdditionalInfo) => (
          <CustomDatePicker
            onChange={(date: Dayjs, dateString: string) =>
              handleSelectChange(dateString, 'gap_due_date', dataObj)
            }
            defaultValue={dueDate}
          />
        )
      },
      {
        title: 'Due Date Audit',
        dataIndex: 'AUDITS',
        key: 'AUDITS',
        width: 195,
        render: (audits: IAudits[]) =>
          audits.length ? (
            <AuditsSelectBox audits={audits} valueKey='afg_due_date' dateEl />
          ) : (
            <Styled.StyledTextBox width={195} />
          )
      },
      {
        title: 'Gap Prioritization',
        dataIndex: 'gap_pri_ref',
        key: 'gap_pri_ref',
        width: 100,
        render: (prio: string, dataObj: IAdditionalInfo) => (
          <CustomSelect
            size='md'
            options={Helpers.convertToSelectOptions(prioritization)}
            defaultValue={prio}
            onChange={(value: string) =>
              handleSelectChange(value, 'gap_pri_ref', dataObj)
            }
          />
        )
      },
      {
        title: 'Audit Prioritization',
        dataIndex: 'AUDITS',
        key: 'AUDITS',
        width: 195,
        render: (audits: IAudits[]) =>
          audits.length ? (
            <AuditsSelectBox audits={audits} valueKey='afg_prio_short_name' />
          ) : (
            <Styled.StyledTextBox width={195} />
          )
      },
      {
        title: 'Gap Source',
        dataIndex: 'gap_src_ref',
        key: 'gap_src_ref',
        width: 100,
        render: (gap_source: string, dataObj: IAdditionalInfo) => (
          <CustomSelect
            size='md'
            options={Helpers.convertToSelectOptions(gapSource)}
            defaultValue={gap_source}
            onChange={(value: string) =>
              handleSelectChange(value, 'gap_src_ref', dataObj)
            }
          />
        )
      },
      {
        title: 'Source Audit',
        dataIndex: 'AUDITS',
        key: 'AUDITS',
        width: 195,
        render: (audits: IAudits[]) =>
          audits.length ? (
            <AuditsSelectBox audits={audits} valueKey='ads_short_name' />
          ) : (
            <Styled.StyledTextBox width={195} />
          )
      },
      {
        title: 'Owner Notified',
        dataIndex: 'gap_owner_notified',
        key: 'gap_owner_notified',
        width: 100,
        render: (ownerNotified: string) => (
          <Styled.StyledTag
            color={ownerNotified === YesNo.YES ? 'green' : 'red'}
          >
            {ownerNotified === YesNo.YES ? 'Notified' : 'Not notified'}
          </Styled.StyledTag>
        )
      },
      {
        title: 'Gap Owner',
        dataIndex: 'gap_owner_ref',
        key: 'gap_owner_ref',
        width: 150,
        render: (gap_owner_ref: string, dataObj: IAdditionalInfo) => (
          <CustomSelect
            size='lg'
            options={Helpers.convertToSelectOptions(ownersList)}
            defaultValue={gap_owner_ref}
            onChange={(value: string) =>
              handleSelectChange(value, 'gap_owner_ref', dataObj)
            }
          />
        )
      },
      {
        title: 'Audit Owner',
        dataIndex: 'AUDITS',
        key: 'AUDITS',
        width: 195,
        render: (audits: IAudits[]) =>
          audits.length ? (
            <AuditsSelectBox audits={audits} valueKey='afg_owner_ref' />
          ) : (
            <Styled.StyledTextBox width={195} />
          )
      }
    ];

    return (
      <NestedTable
        className='nested-table'
        key={dataArr[0].gap_id}
        columns={nestedTableColumns}
        dataSource={dataArr}
        pagination={false}
      />
    );
  };

  useEffect(() => {
    if (Helpers.isObjEmpty(infoSave)) {
      dispatch(complianceEstOp.setSaveDisabled(true));
    } else {
      dispatch(complianceEstOp.setSaveDisabled(false));
    }
  }, [infoSave]);

  useEffect(() => {
    gapsOp.fetchPrioritization(lang).then((response) => {
      setPrioritization(response?.prioritization);
    });

    gapsOp.fetchGapSource(lang).then((response) => {
      setGapSource(response?.gap_sources);
    });

    globalOp
      .fetchOwnersList()
      .then((response: string[]) => setOwnersList(response));
  }, []);

  return (
    <>
      {drawTable && additionalInfo?.length > 0 && !infoUpdated ? (
        <Styled.StyledCustomTable
          columns={gapAdditionalInfoColumns}
          loading={loading}
          dataSource={additionalInfo}
          itemsCount={additionalInfo.length}
          expandable={{
            expandedRowKeys: additionalInfo.map(
              (item: IAdditionalInfoData, index: number) => item[index]
            ),
            expandedRowRender: (record: IAdditionalInfoData) =>
              expandedRow(record)
          }}
          scroll={{ x: 1000 }}
        />
      ) : (
        <CustomTable
          drawTable={drawTable}
          columns={gapAdditionalInfoColumns}
          itemsCount={0}
          dataSource={[]}
        />
      )}
    </>
  );
};

export default AdditionalInfoTable;
