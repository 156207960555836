import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { Layout, theme } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import qs from 'qs';
import HorizontalNavBar from 'components/NavigationBar/HorizontalNavBar';
import CustomSelect from 'components/CustomSelect';
import TitleBar from 'components/TitleBar';
import GoBackButton from 'components/GoBackButton';
import { managementOp } from 'store/ducks/Management';
import { RootState } from 'store/configureStore';
import {
  IFilters,
  TitleListEN,
  TitleListDE,
  TitleListES,
  TitleListFR,
  TitleListIT,
  TitleListPT
} from 'store/ducks/globalTypes';
import filterNames from 'constants/filterNames';
import FilterNames from 'constants/filterNamesTranslations';
import * as Styled from 'components/Layouts/Layout.styled';

const { Sider } = Layout;
const { useToken } = theme;

const FiltersParam = withDefault(ArrayParam, []);

interface IDataManagementLayoutProps {
  activeTab?: string;
  activeKey?: string;
  children: JSX.Element;
}

const DataManagementLayout: React.FC<IDataManagementLayoutProps> = ({
  children
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { token } = useToken();

  const [filtersvisible, setFiltersVisible] = useState(false);

  const toggleFilters = () => {
    setFiltersVisible(!filtersvisible);
  };

  const page = location.pathname.split('/').pop();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  const filterPlaceholder = FilterNames();

  const [query, setQuery] = useQueryParams({
    industry: FiltersParam,
    country: FiltersParam,
    module: FiltersParam,
    entity: FiltersParam,
    category: FiltersParam,
    product: FiltersParam,
    regulator: FiltersParam
  });

  const { lang, saveDisabled, drawTable, filtersData } = useSelector(
    (state: RootState) => ({
      lang: state.global.lang,
      drawTable: state.management.drawTable,
      saveDisabled: state.management.saveDisabled,
      filtersData: state.management.filtersData
    })
  );

  const { scheme } = useSelector((state: RootState) => state.global);

  const handleFilterChange = (value: string[], item: string) => {
    setQuery((latestQuery: any) => ({
      ...latestQuery,
      [item.toLowerCase()]: value
    }));
  };

  const applyFilters = () => {
    switch (page) {
      case 'auditFindings':
        managementOp.fetchMgmtAudits(lang).then((data) => {
          dispatch(managementOp.setMgmtAudits(data));
        });
        break;
      case 'category':
        managementOp.fetchCategory(lang).then((data) => {
          dispatch(managementOp.setCategory(data));
        });
        break;
      case 'product':
        managementOp.fetchProduct(lang).then((data) => {
          dispatch(managementOp.setProduct(data));
        });
        break;
      default:
        return;
    }
    dispatch(managementOp.setDrawTable(true));
  };

  const getFilterObj = (item: string): IFilters[] => {
    return filtersData.length > 0
      ? filtersData.filter((obj) => obj.filter === item)
      : [];
  };

  const handleSave = () => {
    dispatch(managementOp.setSaveClicked(true));
  };

  useEffect(() => {
    dispatch(managementOp.setFilters(lang));
  }, []);

  return (
    <Styled.StyledLayout token={token} filtersvisible={filtersvisible}>
      <TitleBar
        title={(() => {
          switch (lang) {
            case 'en':
              return TitleListEN.DATA_MANAGEMENT;
            case 'de':
              return TitleListDE.DATA_MANAGEMENT;
            case 'es':
              return TitleListES.DATA_MANAGEMENT;
            case 'fr':
              return TitleListFR.DATA_MANAGEMENT;
            case 'it':
              return TitleListIT.DATA_MANAGEMENT;
            case 'pt':
              return TitleListPT.DATA_MANAGEMENT;
            default:
              return TitleListEN.DATA_MANAGEMENT;
          }
        })()}
      />
      <Styled.StyledHeader token={token}>
        <GoBackButton />
      </Styled.StyledHeader>
      <Styled.HorizontalLayout theme={scheme}>
        <HorizontalNavBar />
      </Styled.HorizontalLayout>
      <Styled.StyledLayout token={token} filtersvisible={filtersvisible}>
        <Layout>
          <Content>{children}</Content>
        </Layout>
        <Sider>
          <Styled.OpenCloseButton type='primary' onClick={toggleFilters}>
            {filtersvisible ? <CloseOutlined /> : <CheckOutlined />}
          </Styled.OpenCloseButton>
          {filtersvisible && (
            <Styled.FilterContainer>
              {filterNames.map((item: string, index: number) => (
                <CustomSelect
                  key={item}
                  mode='multiple'
                  placeholder={filterPlaceholder[index]}
                  onChange={(value: string[]) =>
                    handleFilterChange(value, item)
                  }
                  options={
                    getFilterObj(item).length !== 0 &&
                    getFilterObj(item)[0]?.options
                  }
                  defaultValue={queryParams[item.toLowerCase()]}
                  filters
                />
              ))}
              <Styled.StyledButton type='primary' onClick={applyFilters}>
                Display
              </Styled.StyledButton>
              {drawTable && (
                <Styled.StyledButton
                  type='primary'
                  disabled={saveDisabled}
                  onClick={handleSave}
                >
                  Save
                </Styled.StyledButton>
              )}
            </Styled.FilterContainer>
          )}
        </Sider>
      </Styled.StyledLayout>
    </Styled.StyledLayout>
  );
};

export default DataManagementLayout;
