import React, { ReactNode } from 'react';
import { theme } from 'antd';
import * as Styled from './TextBox.styled';

const { useToken } = theme;

export interface ITextBoxProps {
  width?: number;
  level?: number;
  children?: string | number | ReactNode;
  [rest: string]: any;
  token?: any;
}

const TextBox: React.FC<ITextBoxProps> = ({
  width,
  level,
  children,
  ...rest
}) => {
  const { token } = useToken();

  return (
    <Styled.StyledBox token={token} width={width} level={level} {...rest}>
      {children}
    </Styled.StyledBox>
  );
};

export default TextBox;
