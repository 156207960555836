import React, { ReactNode, useState } from 'react';
import { Button, Modal } from 'antd';
import { AliasToken } from 'antd/es/theme/interface';
import { InfoCircleTwoTone } from '@ant-design/icons';
import * as Styled from './SaveModal.styled';

export interface ISaveModalProps {
  width?: number;
  children?: string | ReactNode;
  token?: AliasToken;
  modalOpen: boolean;
  handleOpen: () => void;
  handleOk: () => void;
  handleCancel: () => void;
  handleDiscard: () => void;
  [rest: string]: any;
}

const SaveModal: React.FC<ISaveModalProps> = ({
  width,
  modalOpen,
  handleOpen,
  handleCancel,
  handleDiscard,
  handleOk,
  children,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      open={modalOpen}
      title={
        <Styled.StyledTitle>
          <InfoCircleTwoTone twoToneColor='#faad14' />
          Changes are not saved
        </Styled.StyledTitle>
      }
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          Return
        </Button>,
        <Button
          key='submit'
          type='default'
          loading={loading}
          onClick={handleDiscard}
        >
          Discard changes
        </Button>,
        <Button key='link' type='primary' loading={loading} onClick={handleOk}>
          Save
        </Button>
      ]}
      {...rest}
    >
      <p>Do you want to save changes before switching to the next step?</p>
    </Modal>
  );
};

export default SaveModal;
