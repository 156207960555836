import React, { useEffect, useState } from 'react';
import { Popover, theme, message, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  DeleteOutlined,
  CommentOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { RootState } from 'store/configureStore';
import { clientReqOp } from 'store/ducks/ClientReq';
import { IAurReq, IClientReq } from 'store/ducks/ClientReq/types';
import Helpers from 'utils/Helpers';
import { CommentItem, CommentTable } from 'store/ducks/ComplienceEst/types';
import CustomTextarea from '../CustomTextarea';
import CustomIconButton from '../CustomIconButton';
import CommentSection from '../CommentSection';
import TextBox from '../TextBox';
import * as Styled from './TextAreaBox.styled';

const { useToken } = theme;

interface ITextAreaBoxProps {
  type: string | number;
  placeholder?: string;
  aurReq: IAurReq;
  aurReqId: string | number;
  plusDisabled?: boolean;
}

interface IPopoverState {
  [id: number | string]: boolean;
}

export const TextAreaBox: React.FC<ITextAreaBoxProps> = ({
  placeholder,
  aurReq,
  aurReqId,
  plusDisabled = false
}) => {
  const { token } = useToken();
  const dispatch = useDispatch();

  const { commentsData, clientReqs } = useSelector((state: RootState) => ({
    // lang: state.global.lang,
    commentsData: state.complianceEst.commentsData,
    clientReqs: state.clientReq.clientReqs
  }));

  const [clnReqsToShow, setClnReqsToShow] = useState<{
    [key: number | string]: IClientReq;
  }>({});
  const [popoverState, setPopoverState] = useState<IPopoverState>(() => {
    const initialState: IPopoverState = {};

    !Helpers.isObjEmpty(aurReq.CLN_REQ) &&
      Object.values(aurReq.CLN_REQ).forEach((item: IClientReq) => {
        initialState[item.id as keyof typeof initialState] = false;
      });

    return initialState;
  });

  const hide = (id: number | string) => {
    if (!id) return;

    setPopoverState((prevState) => ({
      ...prevState,
      [id]: false
    }));
  };

  const handleOpenChange = (id: number | string) => {
    if (!id) return;

    setPopoverState((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleClientReqChange = (value: string, clnReqKey: string) => {
    const { aur_parent_key, aur_child_key } = aurReq;

    dispatch(
      clientReqOp.updateClientReq(
        { aur_parent_key, aur_child_key, value },
        clnReqKey,
        aurReqId
      )
    );
  };

  const handleDeleteClick = (id?: number | string) => {
    if (!id) return;

    //TODO fix delete when backend flag will be ready
    // clientReqOp.deleteClientReq({ bru_id: id }, lang);
  };

  const handlePlusClick = () => {
    const newId = uuidv4();

    const { id, aur_parent_key, aur_child_key } = aurReq;

    const newClientReqElement = [
      {
        aurReqId: id,
        updatedAurReqs: {
          [newId]: { aur_parent_key, aur_child_key, value: '' }
        }
      }
    ];

    dispatch(clientReqOp.setNewClientReqs(newClientReqElement));
  };

  const cancel = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    message.error('Evidence was not deleted');
  };

  const drawComments = (id: number) => {
    const filteredComments = commentsData.length
      ? commentsData.filter(
          (commentsDataItem: CommentItem) =>
            commentsDataItem.res_table_pk_ref === id
        )
      : [];

    return (
      <>
        <CommentSection
          reqId={id}
          data={filteredComments}
          tableRef={CommentTable.BUSINESS_RULES}
        />
        <a onClick={() => hide(id)}>Close</a>
      </>
    );
  };

  useEffect(() => {
    if (clientReqs[aurReqId]) {
      const { client_reqs } = clientReqs[aurReqId];

      setClnReqsToShow(client_reqs);
    }
  }, [clientReqs]);

  return (
    <Styled.StyledContainer
      clnReqsExist={!Helpers.isObjEmpty(clnReqsToShow)}
      token={token}
    >
      {!Helpers.isObjEmpty(clnReqsToShow) ? (
        Object.keys(clnReqsToShow).map((clnReqKey: string) => (
          <Styled.StyledDiv key={clnReqKey}>
            {clnReqsToShow[clnReqKey].bru_id ? (
              <Styled.Container>
                <TextBox width={60}>{clnReqsToShow[clnReqKey].bru_id}</TextBox>
              </Styled.Container>
            ) : (
              <Styled.Container>
                <Styled.StyledTextBox width={60} />
              </Styled.Container>
            )}
            <Styled.TextAreaContainer>
              <CustomTextarea
                placeholder={placeholder || 'Client Requirement'}
                defaultValue={clnReqsToShow[clnReqKey].value}
                onChange={(e) =>
                  handleClientReqChange(e.target.value, clnReqKey)
                }
                autoSize
              />
            </Styled.TextAreaContainer>
            <Styled.StyledButtonSection token={token}>
              <Popover
                placement='leftBottom'
                trigger='click'
                content={drawComments(Number(clnReqKey))}
                title='Comments'
                open={!!(clnReqKey && popoverState[clnReqKey])}
                onOpenChange={() => handleOpenChange(clnReqKey)}
              >
                <CustomIconButton
                  type='primary'
                  shape='circle'
                  icon={<CommentOutlined />}
                  size='small'
                  cursor='pointer'
                />
              </Popover>
              <Popconfirm
                title='Delete the client interpreted regulatory requirements'
                description='Are you sure to delete this client interpreted regulatory requirements?'
                onConfirm={() => handleDeleteClick(clnReqKey)}
                onCancel={cancel}
                okText='Yes'
                cancelText='No'
              >
                <Styled.StyledCustomIconButton
                  token={token}
                  type='primary'
                  shape='circle'
                  icon={<DeleteOutlined />}
                  cursor='pointer'
                  size='small'
                />
              </Popconfirm>
            </Styled.StyledButtonSection>
          </Styled.StyledDiv>
        ))
      ) : (
        <Styled.Container>
          <Styled.StyledTextBox width={487} />
        </Styled.Container>
      )}
      <Styled.ButtonContainer>
        <CustomIconButton
          type='primary'
          shape='circle'
          icon={<PlusOutlined />}
          size='middle'
          onClick={handlePlusClick}
          disabled={plusDisabled}
        />
      </Styled.ButtonContainer>
    </Styled.StyledContainer>
  );
};
