import styled from 'styled-components';
import TextBox from '../TextBox';
import { ITextBoxProps } from '../TextBox/TextBox';

export const StyledTextBox = styled(TextBox)<ITextBoxProps>`
  background: ${(props) =>
    props.children ? props.token.colorFillContent : 'transparent'};
`;

export const Container = styled('div')<ITextBoxProps>`
  padding: unset !important;

  > div {
    margin-bottom: ${(props) => `${props.token.marginMD}px`};

    :last-child {
      margin: 0;
    }
  }
`;
