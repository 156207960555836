import React from 'react';
import { theme } from 'antd';
import * as Styled from './CustomCheckbox.styled';

const { useToken } = theme;

interface ICustomCheckboxProps {
  label?: string;
  [rest: string]: any;
}

export const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({
  label,
  ...rest
}) => {
  const { token } = useToken();

  return (
    <Styled.StyledCheckbox token={token} {...rest}>
      {label}
    </Styled.StyledCheckbox>
  );
};
