import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import TextBox from 'components/TextBox';
import CustomSelect from 'components/CustomSelect';
import NestedTableFilterDropdown from 'components/NestedTableFilterDropdown';
import TextBoxesContainer from 'components/TextBoxesContainer';
import Helpers from 'utils/Helpers';
import TextAreaBoxGap from 'components/TextAreaBoxGap';
import { RootState } from 'store/configureStore';
import { gapsOp } from 'store/ducks/Gaps';
import {
  IAuditData,
  IEvidence,
  IGaps,
  IGapsData,
  IGapsTableData
} from 'store/ducks/Gaps/types';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import CustomTable from '../CustomTable';
import NestedTable from '../NestedTable';
import { gapNoticedOptions } from 'constants/filterOptions';
import i18n from 'components/translation/i18n';
import * as Styled from './GapsDescriptionTable.styled';

const { useToken } = theme;

const MemoizedChildComponent = React.memo(TextAreaBoxGap);

const GapsDescriptionTable: React.FC = () => {
  const { token } = useToken();
  const dispatch = useDispatch();
  const i18nInitialization = i18n();

  const { gapsSaveData, gapsUpdated } = useSelector(
    (state: RootState) => state.gaps
  );

  const [filteredData, setFilteredData] = useState<IGapsTableData[]>([]);

  const handleChildChange = (updatedData: IGaps) => {
    const updatedState = () => {
      const newState = { ...gapsSaveData };

      Object.keys(updatedData).forEach((key: number | string) => {
        if (newState.hasOwnProperty(key)) {
          newState[key] = {
            ...newState[key],
            ...updatedData[key]
          };
        } else {
          newState[key] = updatedData[key];
        }
      });

      return newState;
    };

    dispatch(gapsOp.setGapsSaveData(updatedState()));
  };

  const handleChildDelete = (key: number | string) => {
    const updatedState = { ...gapsSaveData };
    delete updatedState[key];

    dispatch(gapsOp.setGapsSaveData(updatedState));
  };

  const {
    lang,
    loading,
    saveClicked,
    drawTable,
    gapsDescription,
    tableFilters,
    gapsTableData
  } = useSelector((state: RootState) => ({
    lang: state.global.lang,
    drawTable: state.complianceEst.drawTable,
    loading: state.gaps.loading,
    gapsDescription: state.gaps.gapsDescription,
    tableFilters: state.gaps.tableFilters,
    gapsTableData: state.gaps.gapsTableData,
    saveClicked: state.complianceEst.saveClicked
  }));

  useEffect(() => {
    if (!Helpers.isObjEmpty(gapsSaveData) && saveClicked) {
      gapsOp.saveGaps(Object.values(gapsSaveData), lang).then(() => {
        dispatch(complianceEstOp.setSaveClicked(false));
        dispatch(gapsOp.setGapsSaveData({}));
      });
    }
  }, [saveClicked]);

  useEffect(() => {
    if (Helpers.isObjEmpty(gapsSaveData)) {
      dispatch(complianceEstOp.setSaveDisabled(true));
    } else {
      dispatch(complianceEstOp.setSaveDisabled(false));
    }
  }, [gapsSaveData]);

  const gapDefinitionColumns = [
    {
      title: i18nInitialization.t('ID'),
      width: 100,
      render: () => <Styled.StyledTextBox width={100} />
    },
    {
      title: i18nInitialization.t('CLN_REQ'),
      width: 500,
      render: () => <Styled.StyledTextBox width={500} />
    },
    {
      title: i18nInitialization.t('CLN_REQ'),
      width: 100,
      render: () => <Styled.StyledTextBox width={100} />
    },
    {
      title: i18nInitialization.t('DROPPED_EVIDENCES'),
      width: 500,
      render: () => <Styled.StyledTextBox width={500} />
    },
    {
      title: i18nInitialization.t('DROPPED_AUDIT_FINDINGS'),
      width: 500,
      render: () => <Styled.StyledTextBox width={500} />
    },
    {
      title: () => (
        <Styled.MergedColumnsContainer>
          <Styled.MergedColumnSm token={token}>
            {i18nInitialization.t('GAP_NOTICED')}
          </Styled.MergedColumnSm>
          <Styled.MergedColumnMd token={token}>
            {i18nInitialization.t('GAPS')}
          </Styled.MergedColumnMd>
        </Styled.MergedColumnsContainer>
      ),
      width: 501,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm
      }: FilterDropdownProps) => (
        <NestedTableFilterDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          options={gapNoticedOptions}
          defaultValue='all'
          filterName='gapNoticed'
        />
      ),
      render: () => <Styled.StyledTextBox width={501} />
    }
  ];

  const expandedRow = (record: IGapsTableData) => {
    const dataArr: IGapsData[] = Object.values(record)[0];

    const nestedTableColumns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        render: (id: number) => <TextBox width={100}>{id}</TextBox>
      },
      {
        title: 'CLN Req',
        dataIndex: [],
        key: 'value',
        width: 500,
        render: (dataObj: IGapsData) => (
          <TextBox width={500} level={dataObj.level}>
            {dataObj.value}
          </TextBox>
        )
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        width: 100,
        render: (category: string) => (
          <CustomSelect options={[]} defaultValue={category} disabled />
        )
      },
      {
        title: 'Dropped evidences',
        dataIndex: 'EVIDENCES',
        key: 'EVIDENCES',
        width: 500,
        render: (evidences: IEvidence) => (
          <TextBoxesContainer data={evidences} descName='evd_desc' />
        )
      },
      {
        title: 'Dropped audit findings',
        dataIndex: 'AUDITS',
        key: 'AUDITS',
        width: 500,
        render: (audits: IAuditData) => (
          <TextBoxesContainer data={audits} descName='desc' />
        )
      },
      {
        title: () => (
          <Styled.MergedColumnsContainer>
            <Styled.MergedColumnSm token={token}>
              Gap noticed
            </Styled.MergedColumnSm>
            <Styled.MergedColumnMd token={token}>Gaps</Styled.MergedColumnMd>
          </Styled.MergedColumnsContainer>
        ),
        dataIndex: [],
        key: 'GAPS',
        width: 501,
        render: (data: IGapsData) => (
          <MemoizedChildComponent
            placeholder='Gap description'
            data={data.GAPS}
            refId={data?.id}
            withCheckbox
            onChange={handleChildChange}
            onDelete={handleChildDelete}
          />
        )
      }
    ];

    return (
      <NestedTable
        key={dataArr[0]?.id}
        className='nested-table'
        columns={nestedTableColumns}
        dataSource={dataArr}
        pagination={false}
      />
    );
  };

  useEffect(() => {
    const data = gapsDescription;

    if (data?.length) {
      switch (tableFilters.gapNoticed[0]) {
        case 'noticed':
          setFilteredData(
            data.map((item: IGapsTableData) => ({
              0: item[0]?.filter(
                (gapData: IGapsData) => !Helpers.isObjEmpty(gapData?.GAPS)
              )
            }))
          );
          break;
        case 'notNoticed':
          setFilteredData(
            data.map((item: IGapsTableData) => ({
              0: item[0]?.filter((gapData: IGapsData) =>
                Helpers.isObjEmpty(gapData?.GAPS)
              )
            }))
          );
          break;
        default:
          return setFilteredData(gapsDescription);
      }
    }
  }, [tableFilters, gapsDescription]);

  useEffect(() => {
    const filteredDataCopy = filteredData;

    dispatch(
      gapsOp.setGapsTableData(
        filteredDataCopy.filter((item: IGapsTableData) => item[0]?.length)
      )
    );
  }, [filteredData]);

  return (
    <>
      {drawTable && gapsDescription?.length > 0 && !gapsUpdated ? (
        <CustomTable
          drawTable
          token={token}
          columns={gapDefinitionColumns}
          dataSource={gapsTableData}
          itemsCount={gapsTableData.length}
          loading={loading}
          expandable={{
            expandedRowKeys: gapsDescription.map(
              (item: IGapsTableData, index: number) => item[index]
            ),
            expandedRowRender: (record: IGapsTableData) => expandedRow(record)
          }}
          scroll={{ x: 1000 }}
        />
      ) : (
        <CustomTable
          drawTable={drawTable}
          columns={gapDefinitionColumns}
          itemsCount={0}
          dataSource={[]}
        />
      )}
    </>
  );
};

export default GapsDescriptionTable;
