import { notification } from 'antd';
import { Dispatch } from '@reduxjs/toolkit';
import ApiService from 'utils/ApiCalls';
import solutionsSlice from './solutionsSlice';
import { ISolution, ISolutionsResult } from './types';
import { LanguageList } from '../globalTypes';

const {
  set_loading,
  set_solutions,
  set_solutions_to_save,
  add_new_solution,
  edit_solution,
  reset_solutions_to_save
} = solutionsSlice.actions;

const fetchSolutions = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getSolutions(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchApproversList = async () => {
  try {
    const response = await ApiService.getSolutionApproverList();

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchSolutionTypes = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getSolutionTypes(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const setSolutions =
  (solutions: ISolutionsResult[]) => async (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_solutions(solutions));
    } catch (error) {
      console.error(error);
    }
  };

const setSolutionsToShow = (solutions: any) => async (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_solutions_to_save(solutions));
  } catch (error) {
    console.error(error);
  }
};

const addNewSolution = (cln_req_id: number) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(add_new_solution(cln_req_id));
  } catch (error) {
    console.error(error);
  }
};

const editSolutionValue =
  (solId: string | number, solData: ISolution, key: string, value: string) =>
  (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(edit_solution({ solId, solData, key, value }));
    } catch (error) {
      console.error(error);
    }
  };

const resetSolutionsToSave = () => (dispatch: Dispatch) => {
  dispatch(set_loading());
  dispatch(reset_solutions_to_save());
};

const deleteSolution = async (sol_id: number | string) => {
  try {
    const response = await ApiService.removeSolution(sol_id);

    notification.success({
      message: response.status,
      description: 'Solution successfully deleted'
    });
  } catch (error) {
    console.error(error);
  }
};

const saveSolutions = async (solData: ISolution[], lang: LanguageList) => {
  try {
    const response = await ApiService.saveSolutions(solData, lang);

    notification.success({
      message: response.status,
      description: 'Solutions successfully saved'
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

const approveSolution = async (solId: string | number) => {
  try {
    const response = await ApiService.approveSolution(solId);

    notification.success({
      message: response.status,
      description: 'Solutions successfully approved'
    });
  } catch (error) {
    console.error(error);
  }
};

const rejectSolution = async (solId: string | number) => {
  try {
    const response = await ApiService.rejectSolution(solId);

    notification.success({
      message: response.status,
      description: 'Solutions successfully rejected'
    });
  } catch (error) {
    console.error(error);
  }
};

export default {
  fetchSolutions,
  setSolutions,
  deleteSolution,
  fetchApproversList,
  saveSolutions,
  fetchSolutionTypes,
  setSolutionsToShow,
  addNewSolution,
  editSolutionValue,
  approveSolution,
  rejectSolution,
  resetSolutionsToSave
};
