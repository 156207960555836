import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Popconfirm, theme, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { RootState } from 'store/configureStore';
import CustomTable from 'components/Tables/CustomTable';
import { globalOp } from 'store/ducks/Global';
import Helpers from 'utils/Helpers';
import { managementOp } from 'store/ducks/Management';
import DataManagementLayout from 'components/Layouts/DataManagementLayout/DataManagementLayout';
import CustomSelect from 'components/CustomSelect';
import { CustomDatePicker } from 'components/CustomDatePicker/CustomDatePicker';
import { IAuditFindingsResult } from 'store/ducks/Management/types';
import i18n from 'components/translation/i18n';
import * as Styled from './AuditFindingsManagement.styled';

type IPrioritizationItem = {
  options: string;
};

type IAuditSource = {
  options: string;
};

const { useToken } = theme;

const AuditFindingsManagement: React.FC = () => {
  const { token } = useToken();
  const i18nInitialization = i18n();
  const { drawTable, lang, mgmtAudits, filtersData } = useSelector(
    (state: RootState) => ({
      drawTable: state.management.drawTable,
      lang: state.global.lang,
      mgmtAudits: state.management.mgmtAudits,
      filtersData: state.management.filtersData
    })
  );

  const [prioritizationData, setPrioritizationData] = useState<
    IPrioritizationItem[]
  >([]);

  const [auditSourceData, setAuditSourceData] = useState<IAuditSource[]>([]);
  const [ownersList, setOwnersList] = useState<string[]>([]);

  const [rows, setRows] = useState<IAuditFindingsResult[]>([]);

  const handleAddRow = () => {
    const newRow: IAuditFindingsResult = {
      key: String(rows.length + 1),
      ads_value_en: '',
      afg_desc_en: '',
      afg_due_date: null,
      afg_id: 0,
      afg_owner_ref: 0,
      cat_value_en: '',
      cty_value_en: '',
      ent_value_en: '',
      ind_value_en: '',
      mod_value_en: '',
      pfn_value_en: '',
      pri_value_en: '',
      reg_value_en: ''
    };
    setRows((prevRows) => [...prevRows, newRow]);
  };

  useEffect(() => {
    if (mgmtAudits && Array.isArray(mgmtAudits)) {
      setRows(
        mgmtAudits.map((audit: any, index: number) => ({
          key: String(index + 1),
          ads_value_en: audit.ads_value_en,
          afg_desc_en: audit.afg_desc_en,
          afg_due_date: audit.afg_due_date,
          afg_id: audit.afg_id,
          afg_owner_ref: audit.afg_owner_ref,
          cat_value_en: audit.cat_value_en,
          cty_value_en: audit.cty_value_en,
          ent_value_en: audit.ent_value_en,
          ind_value_en: audit.ind_value_en,
          mod_value_en: audit.mod_value_en,
          pfn_value_en: audit.pfn_value_en,
          pri_value_en: audit.pri_value_en,
          reg_value_en: audit.reg_value_en
        }))
      );

      managementOp.fetchMngmPrioritization(lang).then((response) => {
        const transformedData: IPrioritizationItem[] =
          response.prioritization.map((priority: string) => {
            return {
              options: priority
            };
          });
        setPrioritizationData(transformedData);
      });

      managementOp.fetchAuditSource(lang).then((response) => {
        const transformedDataAuditSource: IAuditSource[] =
          response.audit_sources.map((aud_source: string) => {
            return {
              options: aud_source
            };
          });
        setAuditSourceData(transformedDataAuditSource);
      });

      globalOp
        .fetchOwnersList()
        .then((response: string[]) => setOwnersList(response));
    }
  }, [mgmtAudits, lang]);

  const handleDeleteClick = (id?: number | string) => {
    if (!id) return;
    const afg_id = typeof id === 'string' ? parseInt(id, 10) : id;
    try {
      managementOp.deleteAuditFindings({ afg_id });
    } catch (error) {
      console.error('Failed to fetch audits:', error);
    }
  };

  const cancel = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    message.error(i18nInitialization.t('AUD_FINDING_NOT_DELETED'));
  };

  const isLastRow = (index: number) => index === rows.length - 1;

  const columns: ColumnsType<IAuditFindingsResult> = [
    {
      title: i18nInitialization.t('ACTION'),
      key: 'action',
      render: (_, record, index) => (
        <Styled.StyledButtonSection token={token}>
          {isLastRow(index) ? (
            <>
              <Popconfirm
                title={i18nInitialization.t('DELETE_AUDIT_FINDING')}
                description={i18nInitialization.t(
                  'CONFIRM_DELETE_AUDIT_FINDING'
                )}
                onConfirm={() => handleDeleteClick(record.key)}
                onCancel={cancel}
                okText={i18nInitialization.t('YES')}
                cancelText={i18nInitialization.t('NO')}
              >
                <Button
                  type='primary'
                  size='small'
                  shape='circle'
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </>
          ) : (
            <Popconfirm
              title={i18nInitialization.t('DELETE_AUDIT_FINDING')}
              description={i18nInitialization.t('CONFIRM_DELETE_AUDIT_FINDING')}
              onConfirm={() => handleDeleteClick(record.key)}
              onCancel={cancel}
              okText={i18nInitialization.t('YES')}
              cancelText={i18nInitialization.t('NO')}
            >
              <Button
                type='primary'
                size='small'
                shape='circle'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )}
        </Styled.StyledButtonSection>
      )
    },
    {
      title: i18nInitialization.t('AUDIT_FINDINGS'),
      dataIndex: 'ads_value_en',
      key: 'ads_value_en',
      width: 200,
      render: (val, data) => (
        <Styled.ColumnTextBox width={200} placeholder={data.ads_value_en} />
      )
    },
    {
      title: i18nInitialization.t('INDUSTRY'),
      dataIndex: 'ind_value_en',
      key: 'ind_value_en',
      width: 150,
      render: (val, data) => (
        <CustomSelect
          options={
            filtersData.find((filter) => filter.filter === 'Industry')
              ?.options || []
          }
          defaultValue={data.ind_value_en}
          onChange={() => console.log()}
        />
      )
    },
    {
      title: i18nInitialization.t('COUNTRY'),
      dataIndex: 'cty_value_en',
      key: 'cty_value_en',
      width: 150,
      render: (val, data) => (
        <CustomSelect
          options={
            filtersData.find((filter) => filter.filter === 'Country')
              ?.options || []
          }
          defaultValue={data.cty_value_en}
          onChange={() => console.log()}
        />
      )
    },
    {
      title: i18nInitialization.t('MODULE'),
      dataIndex: 'mod_value_en',
      key: 'mod_value_en',
      width: 150,
      render: (val, data) => (
        <CustomSelect
          options={
            filtersData.find((module) => module.filter === 'Module')?.options ||
            []
          }
          defaultValue={data.mod_value_en}
          onChange={() => console.log()}
        />
      )
    },
    {
      title: i18nInitialization.t('ENTITY'),
      dataIndex: 'ent_value_en',
      key: 'ent_value_en',
      width: 150,
      render: (val, data) => (
        <CustomSelect
          options={
            filtersData.find((entity) => entity.filter === 'Entity')?.options ||
            []
          }
          defaultValue={data.ent_value_en}
          onChange={() => console.log()}
        />
      )
    },
    {
      title: i18nInitialization.t('CATEGORY'),
      dataIndex: 'cat_value_en',
      key: 'cat_value_en',
      width: 150,
      render: (val, data) => (
        <CustomSelect
          options={
            filtersData.find((category) => category.filter === 'Category')
              ?.options || []
          }
          defaultValue={data.cat_value_en}
          onChange={() => console.log()}
        />
      )
    },
    {
      title: i18nInitialization.t('PRODUCT'),
      dataIndex: 'pfn_value_en',
      key: 'pfn_value_en',
      width: 150,
      render: (val, data) => (
        <CustomSelect
          options={
            filtersData.find((product) => product.filter === 'Product')
              ?.options || []
          }
          defaultValue={data.pfn_value_en}
          onChange={() => console.log()}
        />
      )
    },
    {
      title: i18nInitialization.t('REGULATOR'),
      dataIndex: 'reg_value_en',
      key: 'reg_value_en',
      width: 150,
      render: (val, data) => (
        <CustomSelect
          options={
            filtersData.find((regulator) => regulator.filter === 'Regulator')
              ?.options || []
          }
          defaultValue={data.reg_value_en}
          onChange={() => console.log()}
        />
      )
    },
    {
      title: i18nInitialization.t('PRIORITY'),
      dataIndex: 'pri_value_en',
      key: 'pri_value_en',
      width: 150,
      render: (val, data) => {
        const prData = prioritizationData.map((item) => ({
          value: item.options,
          label: item.options
        }));
        return (
          <>
            {prData && (
              <>
                <CustomSelect
                  options={prData}
                  defaultValue={data.pri_value_en}
                  onChange={() => console.log()}
                />
              </>
            )}
          </>
        );
      }
    },
    {
      title: i18nInitialization.t('AUDIT_SOURCE'),
      dataIndex: 'aud_source',
      key: 'aud_source',
      width: 500,
      render: (val, data) => {
        const audSrcData = auditSourceData.map((item) => ({
          value: item.options,
          label: item.options
        }));
        return (
          <>
            {audSrcData && (
              <>
                <CustomSelect
                  options={audSrcData}
                  defaultValue=''
                  onChange={() => console.log()}
                />
              </>
            )}
          </>
        );
      }
    },
    {
      title: i18nInitialization.t('AUDIT_DUE_DATE'),
      dataIndex: 'afg_due_date',
      key: 'afg_due_date',
      width: 150,
      render: (val, data) => (
        <CustomDatePicker onChange={console.log()} defaultValue={val} />
      )
    },
    {
      title: i18nInitialization.t('AUDIT_OWNER'),
      dataIndex: 'afg_owner_ref',
      key: 'afg_owner_ref',
      width: 150,
      render: (val, data) => (
        <CustomSelect
          size='lg'
          options={Helpers.convertToSelectOptions(ownersList)}
          defaultValue={data.afg_owner_ref.toString()}
          onChange={() => console.log()}
        />
      )
    }
  ];

  return (
    <DataManagementLayout>
      <Styled.ContentWrapper>
        {drawTable && (
          <>
            <CustomTable
              drawTable={drawTable}
              columns={columns}
              dataSource={rows}
              itemsCount={rows.length}
              pagination={false}
              scroll={{ x: 1000 }}
            />
            <Button
              type='primary'
              onClick={handleAddRow}
              style={{ marginTop: '15px', marginLeft: '45px' }}
              icon={<PlusOutlined />}
              shape='circle'
            />
          </>
        )}
      </Styled.ContentWrapper>
    </DataManagementLayout>
  );
};

export default AuditFindingsManagement;
