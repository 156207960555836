import styled from 'styled-components';
import CustomTable from '../CustomTable';
import { ITextBoxProps } from 'components/TextBox/TextBox';
import TextBox from 'components/TextBox';
import { IToken } from 'store/ducks/globalTypes';
import { Button } from 'antd';

export const StyledCustomTable = styled(CustomTable)<IToken>`
  .ant-table-row-expand-icon-cell {
    display: none;
  }

  .ant-table-cell:last-child {
    &:before {
      position: absolute;
      top: 50%;
      inset-inline-end: 0;
      width: 1px;
      height: 1.6em;
      background-color: ${(props) => props.token.colorBgMask};
      transform: translateY(-50%);
      transition: background-color 0.2s;
      content: '';
      display: block;
    }
  }

  .ant-table-row-level-0 {
    > .ant-table-cell {
      padding: 0 16px;

      &:before {
        display: none;
      }

      > div {
        padding: 0 16px;
      }
    }
  }

  .ant-table-wrapper {
    .ant-table {
      margin: unset !important;
    }
  }

  .ant-spin-nested-loading {
    overflow-y: initial !important;
  }

  .ant-table .ant-table-container .ant-table-content {
    overflow: visible;

    .ant-pagination {
      display: none;
    }
  }
`;

export const AuditsContainer = styled('div')<ITextBoxProps>`
  padding: unset !important;

  > div {
    margin-bottom: ${(props) => `${props.token.marginMD}px`};

    :last-child {
      margin: 0;
    }
  }
`;

export const StyledTextBox = styled(TextBox)<ITextBoxProps>`
  background: ${(props) =>
    props.children ? props.token.colorFillContent : 'transparent'};
`;

export const StyledButton = styled(Button)<IToken>`
  border: unset;
  margin-bottom: 5px;
  min-width: 24px;
  border-radius: 50%;
  padding: 0;
  width: 24px !important;
  height: 24px;
  align-self: flex-end;
  margin-left: 6px;
`;

export const StyledAssignedFindings = styled('div')`
  display: flex;
`;

export const StyledDeleteDiv = styled('div')`
  display: flex;
`;
