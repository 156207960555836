import React, { useState } from 'react';
import { theme, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import {
  useSortable,
  verticalListSortingStrategy,
  SortableContext
} from '@dnd-kit/sortable';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { UploadOutlined } from '@ant-design/icons';
import Helpers from 'utils/Helpers';
import * as Styled from './CustomFileUpload.styled';

const { useToken } = theme;

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: UploadFile<any>;
}

const DraggableUploadListItem = ({
  originNode,
  file
}: DraggableUploadListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: file.uid
  });

  const isImageFile =
    file.originFileObj instanceof Blob &&
    file.originFileObj.type.startsWith('image/');

  return (
    <Styled.DraggableEl
      ref={setNodeRef}
      isDragging={isDragging}
      transition={transition}
      transform={transform}
      {...attributes}
      {...listeners}
    >
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>{originNode}</div>
        </div>
        {isImageFile && (
          <img
            width={80}
            src={URL.createObjectURL(file.originFileObj as Blob)}
            alt='Uploaded'
          />
        )}
      </div>
    </Styled.DraggableEl>
  );
};

interface ICustomFileUploadProps {
  defaultFileList: UploadFile[];
}

const CustomFileUpload: React.FC<ICustomFileUploadProps> = ({
  defaultFileList
}) => {
  const { token } = useToken();

  const [fileList, setFileList] = useState<UploadFile<string>[]>([]);

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 }
  });

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (newFileList) {
      setFileList(newFileList);
    }
  };

  return (
    <Styled.UploadFileWrapper
      token={token}
      fileListExist={fileList.length !== 0}
    >
      <DndContext
        sensors={[sensor]}
        onDragEnd={({ active, over }: any) =>
          Helpers.onDragEnd(
            {
              active,
              over
            },
            setFileList,
            'uid'
          )
        }
      >
        <SortableContext
          items={fileList.map((i) => i.uid)}
          strategy={verticalListSortingStrategy}
        >
          <Upload
            fileList={fileList}
            defaultFileList={defaultFileList}
            multiple
            onChange={onChange}
            itemRender={(originNode, file) => (
              <DraggableUploadListItem originNode={originNode} file={file} />
            )}
          >
            <Styled.StyledButton
              type='primary'
              token={token}
              icon={<UploadOutlined />}
            >
              Click to Upload
            </Styled.StyledButton>
          </Upload>
        </SortableContext>
      </DndContext>
    </Styled.UploadFileWrapper>
  );
};

export default CustomFileUpload;
