import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers';

const enhancers: any[] = [];
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false
  })
];

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: true,
  enhancers
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
