import React from 'react';
import { Input, Form, theme, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { RuleObject } from 'antd/lib/form';
import AuthLayout from 'components/Layouts/AuthLayout';
import { globalOp } from 'store/ducks/Global';
import { IResetPass } from 'store/ducks/globalTypes';
import resetPassInputs from 'constants/resetPassInputs';
import validationRules from 'constants/validationRules';
import * as Styled from './ResetPass.styled';

const { useToken } = theme;

const ResetPass: React.FC = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  const customPasswordRule = async (_: RuleObject, value: string) => {
    try {
      for (const rule of validationRules) {
        if (!rule.test(value)) {
          throw new Error(rule.message);
        }
      }

      return Promise.resolve();
    } catch (error: any) {
      return Promise.reject(error.message);
    }
  };

  const handleSubmit = (values: IResetPass) => {
    if (values.password === values.confirm_password) {
      if (queryParams.token) {
        globalOp.resetPass(values, queryParams.token).then(() => navigate('/'));
      }
    } else {
      notification.warning({
        message: 'Warning',
        description: 'Password and Confirm Password values should be the same'
      });
    }
  };

  return (
    <AuthLayout>
      <Form form={form} name='reset_pass' onFinish={handleSubmit}>
        {resetPassInputs.map((item) => (
          <Styled.StyledFormItem
            key={item.label}
            token={token}
            label={item.label}
            name={item.name}
            rules={[
              {
                required: true,
                message: 'Please input your new password!'
              },
              { validator: customPasswordRule }
            ]}
          >
            <Input.Password />
          </Styled.StyledFormItem>
        ))}
        <Styled.ButtonContainer>
          <Styled.StyledButton
            type='primary'
            htmlType='submit'
            disabled={!queryParams.token}
          >
            Submit
          </Styled.StyledButton>
        </Styled.ButtonContainer>
      </Form>
    </AuthLayout>
  );
};

export default ResetPass;
