import ApiService from 'utils/ApiCalls';
import { LanguageList, IFilters } from '../globalTypes';
import { Dispatch } from '@reduxjs/toolkit';
import reportingSlice from './reportingSlice';
import Helpers from 'utils/Helpers';

interface IEntityProps {
  [key: string]: number;
}

const {
  set_draw_table,
  set_loading,
  set_filters_data,
  set_save_disabled,
  set_save_clicked
} = reportingSlice.actions;

const setDrawTable = (draw: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_draw_table(draw));
  } catch (error) {
    console.error(error);
  }
};

const setFilters = (lang: LanguageList) => async (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    const response = await ApiService.getFilters(lang);

    let fetchedFilters: IFilters[] = [];

    if (response.data && !Helpers.isObjEmpty(response.data)) {
      fetchedFilters = Object.keys(response.data).map((key) => {
        let filterName, filterOptions;

        filterName = key.charAt(0).toUpperCase() + key.slice(1);
        filterOptions = response.data[key];

        const options = filterOptions.map((option: string | IEntityProps) => {
          const value =
            typeof option === 'string' ? option : Object.keys(option)[0];

          return {
            value: value,
            label: value
          };
        });

        return {
          filter: filterName,
          options: options
        };
      });
    }

    dispatch(set_filters_data(fetchedFilters));
  } catch (error) {
    console.error(error);
  }
};

const applyFiltersApi = async (lang: LanguageList) => {
  try {
    const response = await ApiService.applyFilters(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const setSaveDisabled = (disabled: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_save_disabled(disabled));
  } catch (error) {
    console.error(error);
  }
};

const setSaveClicked = (saveClicked: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_save_clicked(saveClicked));
  } catch (error) {
    console.error(error);
  }
};

const fetchReport = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getReports(lang);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export default {
  setDrawTable,
  setFilters,
  applyFiltersApi,
  setSaveClicked,
  fetchReport
};
