import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme, Popover } from 'antd';
import { MergeCellsOutlined, CopyOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RootState } from 'store/configureStore';
import { clientReqOp } from 'store/ducks/ClientReq';
import {
  IApplicability,
  IAurReq,
  ICategory,
  IClientReq,
  INewClientReqs,
  IRegulatoryReq
} from 'store/ducks/ClientReq/types';
import { CategoryList, YesNo } from 'store/ducks/globalTypes';
import { applicabilityOptions } from 'constants/filterOptions';
import NestedTableFilterDropdown from 'components/NestedTableFilterDropdown';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomTable from 'components/Tables/CustomTable';
import ComplianceEstLayout from 'components/Layouts/ComplianceEstLayout';
import categoryList from 'constants/categorieNames';
import TextBox from 'components/TextBox';
import Helpers from 'utils/Helpers';
import TextAreaBox from 'components/TextAreaBox_clnReqScreen';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import i18n from 'components/translation/i18n';
import * as Styled from './ClientRequirements.styled';

const { useToken } = theme;

const ClientRequirements: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const i18nInitialization = i18n();

  const {
    lang,
    drawTable,
    appliedFiltersResult,
    tableFilters,
    checkedElementsToBeMerged,
    checkedElementsToBeCopied,
    mergedElements,
    copiedElements,
    filteredData,
    checkedAurReqs,
    saveClicked,
    clientReqs,
    loading
  } = useSelector((state: RootState) => ({
    lang: state.global.lang,
    drawTable: state.complianceEst.drawTable,
    appliedFiltersResult: state.clientReq.appliedFiltersResult,
    tableFilters: state.clientReq.tableFilters,
    checkedElementsToBeMerged: state.clientReq.checkedElementsToBeMerged,
    checkedElementsToBeCopied: state.clientReq.checkedElementsToBeCopied,
    mergedElements: state.clientReq.mergedElements,
    copiedElements: state.clientReq.copiedElements,
    filteredData: state.clientReq.filteredData,
    checkedAurReqs: state.clientReq.checkedAurReqs,
    saveClicked: state.complianceEst.saveClicked,
    clientReqs: state.clientReq.clientReqs,
    loading: state.clientReq.loading
  }));

  const [changedApplicability, setChangedApplicability] = useState<{
    [key: number]: IApplicability;
  }>({});
  const [changedCategories, setChangedCategory] = useState<{
    [key: string]: ICategory;
  }>({});

  const isAurReqUnchecked = (aurReq: IAurReq) =>
    aurReq.is_app === 'N' || !checkedAurReqs.includes(aurReq.id);

  const setCheckedAurReqs = () => {
    appliedFiltersResult.map((regReq: IRegulatoryReq) =>
      Object.values(regReq.AUR_REQ).map((aurReq: IAurReq) => {
        if (
          aurReq.is_app === YesNo.YES &&
          !checkedAurReqs.includes(aurReq.id)
        ) {
          dispatch(clientReqOp.setCheckedAurReqs(aurReq.id));
        }
      })
    );
  };

  const setClientReqs = () => {
    if (appliedFiltersResult.length) {
      const result = appliedFiltersResult.reduce(
        (acc: INewClientReqs, item: IRegulatoryReq) => {
          Object.values(item.AUR_REQ).forEach((aurReq: IAurReq) => {
            const aurReqId = aurReq.id;

            const clnReqValues = Object.values(aurReq.CLN_REQ).map(
              (clnReq: IClientReq) => ({
                aur_parent_key: aurReq.aur_parent_key,
                aur_child_key: aurReq.aur_parent_key,
                value: clnReq.value,
                bru_id: clnReq.id
              })
            );

            const newClnReqs = clnReqValues.reduce(
              (o, key) => ({ ...o, [key.bru_id]: key }),
              {}
            );

            if (acc[aurReqId]) {
              acc[aurReqId].client_reqs = {
                ...acc[aurReqId].client_reqs,
                ...newClnReqs
              };
            } else {
              acc[aurReqId] = {
                client_reqs: newClnReqs,
                updated_client_reqs: {}
              };
            }
          });

          return acc;
        },
        {}
      );

      dispatch(clientReqOp.setClientReqs(result));
    }
  };

  const filterRegReqs = () => {
    if (!Helpers.isObjEmpty(appliedFiltersResult)) {
      switch (tableFilters.applicability[0]) {
        case YesNo.YES:
          const filteredRegReqs = Helpers.filterRegReqs(
            appliedFiltersResult,
            checkedAurReqs,
            true
          );

          dispatch(clientReqOp.setFilteredData(filteredRegReqs));
          break;
        case YesNo.NO:
          const newRegReqs = Helpers.filterRegReqs(
            appliedFiltersResult,
            checkedAurReqs,
            false
          );

          dispatch(clientReqOp.setFilteredData(newRegReqs));
          break;
        default:
          dispatch(clientReqOp.setFilteredData(appliedFiltersResult));
      }
    }
  };

  const handleCheckboxChange = (id: number, checked: boolean) => {
    dispatch(clientReqOp.setCheckedAurReqs(id));

    setChangedApplicability((prevState) => ({
      ...prevState,
      [id]: { applic_value: checked ? YesNo.YES : YesNo.NO, aur_req_id: id }
    }));
  };

  const handleCategoryChange = (regReqKey: string, value: CategoryList) => {
    setChangedCategory((prevState) => ({
      ...prevState,
      [regReqKey]: { reg_req_key: regReqKey, category: value }
    }));
  };

  const handleMergeChange = (aurReqEl: IAurReq) => {
    if (!checkedElementsToBeMerged.includes(aurReqEl.id)) {
      const elements = [...checkedElementsToBeMerged, aurReqEl.id];

      dispatch(clientReqOp.setCheckedElementsToBeMerged(elements));
    } else {
      const elements = checkedElementsToBeMerged.filter(
        (el: number) => el !== aurReqEl.id
      );

      dispatch(clientReqOp.setCheckedElementsToBeMerged(elements));
    }
  };

  const handleCopyCheck = (e: CheckboxChangeEvent, aurReqEl: IAurReq) => {
    if (!checkedElementsToBeCopied.includes(aurReqEl.id)) {
      const elements = [...checkedElementsToBeCopied, aurReqEl.id];

      dispatch(clientReqOp.setCheckedElementsToBeCopied(elements));
    } else {
      const elements = checkedElementsToBeCopied.filter(
        (el) => el !== aurReqEl.id
      );

      dispatch(clientReqOp.setCheckedElementsToBeCopied(elements));
    }
  };

  const extractedClientReqs = () => {
    return Object.values(clientReqs)
      .map(
        (clientReq: {
          updated_client_reqs: { [key: number]: IClientReq };
          client_reqs: { [key: number]: IClientReq };
        }) => Object.values(clientReq.updated_client_reqs)
      )
      .flat();
  };

  useEffect(() => {
    filterRegReqs();
  }, [tableFilters.applicability]);

  useEffect(() => {
    setCheckedAurReqs();
    setClientReqs();
  }, [appliedFiltersResult]);

  useEffect(() => {
    if (saveClicked) {
      const saveData = extractedClientReqs();

      if (saveData.length > 0) {
        dispatch(clientReqOp.saveClientReqs(saveData, lang));
      }

      if (!Helpers.isObjEmpty(changedApplicability)) {
        dispatch(
          clientReqOp.updateApplicability(
            Object.values(changedApplicability),
            lang
          )
        );
      }

      if (!Helpers.isObjEmpty(changedCategories)) {
        dispatch(
          clientReqOp.updateCategory(Object.values(changedCategories), lang)
        );
      }
    }

    dispatch(complianceEstOp.setSaveClicked(false));
  }, [saveClicked]);

  useEffect(() => {
    const updatedMergedElements = checkedElementsToBeMerged.filter(
      (el: number) => checkedAurReqs.includes(el)
    );
    const updatedCopiedElements = checkedElementsToBeCopied.filter(
      (el: number) => checkedAurReqs.includes(el)
    );

    dispatch(clientReqOp.setCheckedElementsToBeMerged(updatedMergedElements));
    dispatch(clientReqOp.setCheckedElementsToBeCopied(updatedCopiedElements));
  }, [checkedAurReqs]);

  useEffect(() => {
    const newClnReqs = Object.values(clientReqs).map(
      (clnReqs) => clnReqs.updated_client_reqs
    );

    if (
      newClnReqs.every((clnReq) => Helpers.isObjEmpty(clnReq)) &&
      Helpers.isObjEmpty(changedApplicability) &&
      Helpers.isObjEmpty(changedCategories)
    ) {
      dispatch(complianceEstOp.setSaveDisabled(true));
    } else {
      dispatch(complianceEstOp.setSaveDisabled(false));
    }
  }, [clientReqs, changedApplicability, changedCategories]);

  const columns = [
    {
      title: i18nInitialization.t('ID'),
      dataIndex: 'id',
      key: 'id',
      width: 60,
      render: (id: number) => <TextBox width={60}>{id}</TextBox>
    },
    {
      title: i18nInitialization.t('REGULATORY_REQUIREMENTS'),
      dataIndex: [],
      key: 'value',
      width: 445,
      render: (data: IRegulatoryReq) => (
        <Styled.ColumnTextBox width={445} level={data.level}>
          {data.value}
        </Styled.ColumnTextBox>
      )
    },
    {
      title: i18nInitialization.t('CATEGORY'),
      dataIndex: [],
      key: 'category',
      width: 100,
      render: (regReq: IRegulatoryReq) => (
        <CustomSelect
          options={categoryList}
          defaultValue={regReq.category}
          onChange={(value: CategoryList) =>
            handleCategoryChange(regReq.reg_req_key, value)
          }
        />
      )
    },
    {
      title: () => (
        <Styled.MergedColumnsContainer>
          <Styled.MergedColumnSm
            token={token}
            style={{ padding: '0' }}
            width={26}
          ></Styled.MergedColumnSm>
          <Styled.MergedColumnSm token={token} width={82}>
            {i18nInitialization.t('AUR_ID')}
          </Styled.MergedColumnSm>
          <Styled.MergedColumnLg token={token} width={465}>
            {i18nInitialization.t('AUR_INTERPRETED_REQUIREMENTS')}
          </Styled.MergedColumnLg>
          <Styled.MergedColumnSm token={token}>
            <Popover title={i18nInitialization.t('MERGE')}>
              <MergeCellsOutlined
                style={{ fontSize: '17px', paddingTop: '3px' }}
              />
            </Popover>
          </Styled.MergedColumnSm>
          <Styled.MergedColumnSm token={token}>
            <Popover title={i18nInitialization.t('COPY')}>
              <CopyOutlined style={{ fontSize: '17px', paddingTop: '3px' }} />
            </Popover>
          </Styled.MergedColumnSm>
          <Styled.MergedColumnSm token={token} width={70}>
            {i18nInitialization.t('REG_ID')}
          </Styled.MergedColumnSm>
          <Styled.MergedColumnMd token={token}>
            {i18nInitialization.t('CLIENT_INTERPRETED_REGULATORY_REQUIREMENTS')}
          </Styled.MergedColumnMd>
        </Styled.MergedColumnsContainer>
      ),
      dataIndex: [],
      key: 'clnReqs',
      width: 1399,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm
      }: FilterDropdownProps) => (
        <>
          <p style={{ textAlign: 'center' }}>
            {i18nInitialization.t('APPLICABILITY')}
          </p>
          <NestedTableFilterDropdown
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            options={applicabilityOptions}
            defaultValue='all'
            filterName='applicability'
          />
        </>
      ),
      render: (data: IRegulatoryReq) => (
        <>
          {!Helpers.isObjEmpty(data.AUR_REQ) &&
            Object.values(data.AUR_REQ).map((aurReqEl: IAurReq) => (
              <Styled.StyledRow token={token} key={aurReqEl.id}>
                <Styled.CheckboxItem token={token} width={65}>
                  <CustomCheckbox
                    checked={checkedAurReqs.includes(aurReqEl.id)}
                    onChange={(e: CheckboxChangeEvent) =>
                      handleCheckboxChange(aurReqEl.id, e.target.checked)
                    }
                  />
                </Styled.CheckboxItem>
                <Styled.Container>
                  <TextBox width={60}>{aurReqEl.id}</TextBox>
                </Styled.Container>
                <Styled.Container>
                  <Styled.AurReqsTextBox
                    token={token}
                    width={445}
                    level={aurReqEl.level}
                  >
                    {aurReqEl.value}
                  </Styled.AurReqsTextBox>
                </Styled.Container>
                <Styled.CheckboxItem token={token}>
                  <CustomCheckbox
                    checked={
                      checkedElementsToBeMerged &&
                      checkedElementsToBeMerged.includes(aurReqEl.id)
                    }
                    disabled={
                      isAurReqUnchecked(aurReqEl) ||
                      checkedElementsToBeCopied.includes(aurReqEl.id) ||
                      mergedElements.includes(aurReqEl.id) ||
                      copiedElements.includes(aurReqEl.id)
                    }
                    onChange={() => handleMergeChange(aurReqEl)}
                  />
                </Styled.CheckboxItem>
                <Styled.CheckboxItem token={token}>
                  <CustomCheckbox
                    checked={
                      checkedElementsToBeCopied &&
                      checkedElementsToBeCopied.includes(aurReqEl.id)
                    }
                    disabled={
                      isAurReqUnchecked(aurReqEl) ||
                      checkedElementsToBeMerged.includes(aurReqEl.id) ||
                      copiedElements.includes(aurReqEl.id) ||
                      mergedElements.includes(aurReqEl.id)
                    }
                    onChange={(e: CheckboxChangeEvent) =>
                      handleCopyCheck(e, aurReqEl)
                    }
                  />
                </Styled.CheckboxItem>
                <Styled.Container>
                  <TextAreaBox
                    type='cln_req'
                    aurReq={aurReqEl}
                    aurReqId={aurReqEl.id}
                    plusDisabled={isAurReqUnchecked(aurReqEl)}
                  />
                </Styled.Container>
              </Styled.StyledRow>
            ))}
        </>
      )
    }
  ];

  return (
    <ComplianceEstLayout>
      <Styled.ContentWrapper
        token={token}
        className='nested-table cln-req-wrapper'
      >
        {appliedFiltersResult.length ? (
          <CustomTable
            drawTable={drawTable}
            columns={columns}
            dataSource={
              filteredData.length ? filteredData : appliedFiltersResult
            }
            itemsCount={filteredData.length}
            scroll={{ x: 1399 }}
            loading={loading}
          />
        ) : (
          <CustomTable
            drawTable={drawTable}
            itemsCount={0}
            columns={columns}
            dataSource={[]}
            pagination={false}
          />
        )}
      </Styled.ContentWrapper>
    </ComplianceEstLayout>
  );
};

export default ClientRequirements;
