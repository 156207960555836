import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'antd';
import qs from 'qs';
import { ICustomMenuItem } from 'store/ducks/globalTypes';
import horizontalNavBar from 'constants/horizontalNavBar';
import managementHorizontalNavItemsPage from 'constants/horizontalNavBarAFM';
import * as Styled from './HorizontalNavBar.styled';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';

const { useToken } = theme;

const HorizontalNavBar = () => {
  const { token } = useToken();
  const location = useLocation();
  const navigate = useNavigate();
  const managementHorizontalNavItems = managementHorizontalNavItemsPage();
  const managementHorizontalNavBar = horizontalNavBar();
  const defaultSelectedItem = location.pathname.split('/').pop();

  const lang = useSelector((state: RootState) => state.global.lang);

  const [current, setCurrent] = useState<string | undefined>(
    defaultSelectedItem
  );

  const [itemsToDisplay, setItemsToDisplay] = useState<ICustomMenuItem[]>(
    managementHorizontalNavBar
  );

  const onClick = (e: any) => {
    setCurrent(e.key);

    const selectedItem = itemsToDisplay.find(
      (item: ICustomMenuItem) => item.key === e.key
    );

    if (selectedItem) {
      const queryParams = qs.parse(location.search, {
        ignoreQueryPrefix: true
      });

      const updatedSearchParams = {
        ...queryParams,
        ...queryParams
      };

      const searchString = qs.stringify(updatedSearchParams);

      navigate(`${selectedItem.path}${searchString ? `?${searchString}` : ''}`);
    }
  };

  const handleUpdateLocation = () => {
    if (location.pathname.includes('dataManagement')) {
      setItemsToDisplay(managementHorizontalNavItems);
    } else {
      setItemsToDisplay(managementHorizontalNavBar);
    }
  };

  useEffect(() => {
    handleUpdateLocation();
  }, [location.pathname]);

  const updateItemsToDisplay = () => {
    if (lang === 'en' && location.pathname.includes('dataManagement')) {
      setItemsToDisplay(managementHorizontalNavItems);
    } else if (lang === 'de' && location.pathname.includes('dataManagement')) {
      setItemsToDisplay(managementHorizontalNavItems);
    } else {
      setItemsToDisplay(managementHorizontalNavBar);
    }
  };

  useEffect(() => {
    updateItemsToDisplay();
  }, [lang]);

  useEffect(() => {
    if (defaultSelectedItem) {
      setCurrent(defaultSelectedItem);
    }
  }, [defaultSelectedItem]);

  return (
    <Styled.StyledMenu
      token={token}
      disabledOverflow
      onClick={onClick}
      selectedKeys={[current || '']}
      mode='horizontal'
      items={itemsToDisplay}
    />
  );
};

export default HorizontalNavBar;
