import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGlobalState } from './types';
import initialGlobalState from './initialState';
import { LanguageList, ThemeList } from '../globalTypes';

const globalSlice = createSlice({
  name: 'global',
  initialState: initialGlobalState(),
  reducers: {
    set_loading(state: IGlobalState) {
      state.loading = true;
    },
    set_error(state: IGlobalState, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    set_theme(state: IGlobalState, action: PayloadAction<ThemeList>) {
      state.scheme = action.payload;
      state.loading = false;
    },
    set_lang(state: IGlobalState, action: PayloadAction<LanguageList>) {
      state.lang = action.payload;
      state.loading = false;
    },
    set_search_params(state: IGlobalState, action: PayloadAction<string>) {
      state.searchParams = action.payload;
      state.loading = false;
    }
  }
});

export default globalSlice;
