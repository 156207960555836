import { IFilters } from '../globalTypes';

export interface IComplianceEstState {
  error: boolean | null;
  loading: boolean;
  drawTable: boolean;
  saveDisabled: boolean;
  saveClicked: boolean;
  commentsData: CommentItem[];
  filtersData: IFilters[];
}

export interface ISelectedOptions {
  [key: string]: string[];
}

export interface IAssignedEls {
  afg_id: any;
  bru_id: any;
  txt?: string;
}

export interface IAssignedEvidences {
  evd_id: any;
  bru_id: any;
}

export interface CommentItem {
  res_id?: number;
  res_table_ref: string;
  res_table_pk_ref: number;
  res_comment: string;
  res_comment_time?: string;
  user?: string;
}

export enum CommentTable {
  BUSINESS_RULES = 'BUSINESS_RULES',
  GAPS = 'GAPS',
  SOLUTIONS = 'SOLUTIONS',
  TASKS = 'TASKS',
  EVIDENCES = 'EVIDENCES'
}
