import { v4 as uuidv4 } from 'uuid';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { ITask, ITasksResult, ITasksState, ITasksToShow } from './types';
import initialTasksState from './initialState';
import Helpers from 'utils/Helpers';
import newTaskObj from 'constants/newTask';

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: initialTasksState(),
  reducers: {
    set_tasks(state: ITasksState, action: PayloadAction<ITasksResult[]>) {
      state.tasks = action.payload;
      state.loading = false;
    },
    set_tasks_to_show(state: ITasksState, action: PayloadAction<ITasksToShow>) {
      state.tasksToShow = action.payload;
      state.loading = false;
    },
    add_new_task(state: ITasksState, action: PayloadAction<number>) {
      const oldState = current(state);
      const regReqObj = oldState.tasks.filter(
        (item: ITasksResult) => item.sol_id === action.payload
      )[0];

      const newId = uuidv4();

      state.tasksToShow = Helpers.isObjEmpty(
        oldState.tasksToShow[action.payload]
      )
        ? {
            ...oldState.tasksToShow,
            [action.payload]: {
              [newId]: {
                ...regReqObj,
                task_id: newId,
                TASKS: newTaskObj(regReqObj.sol_id)
              }
            }
          }
        : {
            ...oldState.tasksToShow,
            [action.payload]: {
              ...oldState.tasksToShow[action.payload],
              [newId]: {
                ...regReqObj,
                task_id: newId,
                TASKS: newTaskObj(regReqObj.sol_id)
              }
            }
          };
      state.loading = false;
    },
    edit_task(
      state: ITasksState,
      action: PayloadAction<{
        tskId: string | number;
        taskData: ITask;
        key: string;
        value: string;
      }>
    ) {
      const oldState = current(state);
      const { tskId, taskData, key, value } = action.payload;
      const { ...rest } = taskData;
      const taskToSave =
        oldState.tasksToSave[tskId] &&
        !Helpers.isObjEmpty(oldState.tasksToSave[tskId])
          ? { ...oldState.tasksToSave[tskId] }
          : { ...rest };

      state.tasksToSave = {
        ...oldState.tasksToSave,
        [tskId]: {
          ...taskToSave,
          [key]: value
        }
      };
      state.loading = false;
    },
    reset_tasks_to_save(state: ITasksState) {
      state.tasksToSave = {};
    },
    delete_task: (
      state,
      action: PayloadAction<{ taskId: string | number; solId: number }>
    ) => {
      const oldState = current(state);
      const { taskId, solId } = action.payload;

      let filteredTasksToShow;
      let filteredTasksToSave;

      if (oldState.tasksToShow[solId] && oldState.tasksToShow[solId][taskId]) {
        const { [taskId]: _, ...rest } = oldState.tasksToShow[solId];

        filteredTasksToShow = rest;
      } else {
        filteredTasksToShow = oldState.tasksToShow[solId];
      }

      if (oldState.tasksToSave[taskId]) {
        const { [taskId]: _, ...rest } = oldState.tasksToSave;

        filteredTasksToSave = rest;
      } else {
        filteredTasksToSave = oldState.tasksToSave;
      }

      state.tasksToShow = {
        ...oldState.tasksToShow,
        [solId]: filteredTasksToShow
      };
      state.tasksToSave = filteredTasksToSave;
      state.error = false;
      state.loading = false;
    },
    set_loading(state: ITasksState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    set_error(state: ITasksState, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export default tasksSlice;
