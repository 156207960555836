import React from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import RouterOutlet from './RouterOutlet';
import { theme } from 'constants/theme';
import { RootState } from 'store/configureStore';
import { themesList } from 'constants/themesList';
import { ThemeList } from 'store/ducks/globalTypes';
import ThemeSelector from './components/ThemeSelector';

const App: React.FC = () => {
  const { scheme } = useSelector((state: RootState) => state.global);

  return (
    <ThemeSelector>
      <React.Suspense fallback={<div>Loading</div>}>
        <ConfigProvider theme={theme(themesList[scheme as ThemeList])}>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <RouterOutlet />
          </QueryParamProvider>
        </ConfigProvider>
      </React.Suspense>
    </ThemeSelector>
  );
};

export default App;
