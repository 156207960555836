import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';

export const TextareaWrapper = styled('div')<IToken>`
  .ant-input {
    min-width: 330px;
    padding: ${(props) => `${props.token.padding}px`};
    border: unset;
    background: ${(props) => props.token.colorFillContent};
    margin-right: ${(props) => `${props.token.marginXXS}px`};
    color: ${(props) => props.token.colorPrimaryText};
  }
`;
