import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { gapsOp } from 'store/ducks/Gaps';
import { ISelectionOption } from 'store/ducks/globalTypes';
import * as Styled from './DragTextBox.styled';

interface ITextBoxProps {
  children: string | ReactNode;
  onIdChange?: (
    newId: number | null,
    dragText: string | null,
    selectedValue: string | null
  ) => void;
}

interface ClnReqItem {
  category: string;
  id: number;
  is_app: string;
  level: { level: number; font: string; size: number };
  tech_child: string;
  value: null;
}

const DragTextBox: React.FC<ITextBoxProps> = ({ children, onIdChange }) => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState<string>('Search for id');
  const [selectedChild, setSelectedChild] = useState<any | null>();
  const [options, setOptions] = useState<ISelectionOption[]>([]);
  const [assignedEl, setAssignedEl] = useState({
    afg_id: 0,
    bru_id: ''
  });
  const [assignedEls, setAssignedEls] = useState<
    { afg_id: number; bru_id: string }[]
  >([]);

  const { auditFindingMapping, clickedHandleMoveButton } = useSelector(
    (state: RootState) => state.gaps
  );

  const handleSelectChange = (value: any) => {
    setSelectedValue(value);
    setSelectedChild(children);
    if (onIdChange) {
      onIdChange(
        parseInt(value) || null,
        children as string,
        selectedValue || null
      );
    }
  };

  useEffect(() => {
    setAssignedEl((currentState) => ({
      ...currentState,
      bru_id: selectedChild,
      afg_id: parseInt(selectedValue)
    }));
  }, [selectedValue, selectedChild]);

  useEffect(() => {
    setAssignedEls((currentEls) => [...currentEls, assignedEl]);
  }, [assignedEl]);

  useEffect(() => {
    if (clickedHandleMoveButton) {
      setSelectedValue('Search for id');
      dispatch(gapsOp.setClickedHandleMoveButton(false));
    }
  }, [clickedHandleMoveButton]);

  useEffect(() => {
    if (assignedEl.bru_id && assignedEl.afg_id) {
      dispatch(
        gapsOp.setAssignedEls([
          {
            id: assignedEl.afg_id,
            txt: assignedEl.bru_id
          }
        ])
      );
    }
  }, [assignedEl]);

  useEffect(() => {
    if (auditFindingMapping.length) {
      const clnReqs = auditFindingMapping.map((item: any) => {
        return item[0].map((clnReqItem: ClnReqItem) =>
          clnReqItem.id.toString()
        );
      });
      const newOptions = clnReqs.flat().map((id: string) => ({
        value: id,
        label: id
      }));

      setOptions(newOptions);
    }
  }, [auditFindingMapping]);

  return (
    <Styled.StyledMainDiv className='drag-text-box'>
      <div>
        <Styled.StyledSelect
          className='select-search'
          defaultActiveFirstOption
          showSearch
          optionFilterProp='children'
          filterOption={(input, option) => {
            const label = option?.label ?? '';
            if (typeof label !== 'string') {
              return false;
            }
            return (
              label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              label.toLowerCase().indexOf(' ' + input.toLowerCase()) >= 0 ||
              label.toLowerCase().indexOf(input.toLowerCase() + ' ') >= 0
            );
          }}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '')
              .toLowerCase()
              .localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={options}
          onChange={handleSelectChange}
          value={selectedValue}
        />
      </div>
      <Styled.StyledSpan>{children}</Styled.StyledSpan>
    </Styled.StyledMainDiv>
  );
};

export default DragTextBox;
