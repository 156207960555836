import React, { useEffect, useState } from 'react';
import { Tabs, theme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  DndContext,
  PointerSensor,
  useSensor,
  DragEndEvent
} from '@dnd-kit/core';
import {
  horizontalListSortingStrategy,
  SortableContext,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import AuditFindingsTable from 'components/Tables/AuditFindingsTable';
import Helpers from 'utils/Helpers';
import ComplianceEstLayout from 'components/Layouts/ComplianceEstLayout';
import EvidenceMappingTable from 'components/Tables/EvidenceMappingTable';
import GapsDescriptionTable from 'components/Tables/GapsDescriptionTable';
import AdditionalInfoTable from 'components/Tables/AdditionalInfoTable';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import { GapTabs } from 'store/ducks/Gaps/types';
import SaveModal from 'components/SaveModal';
import { RootState } from 'store/configureStore';
import { gapsOp } from 'store/ducks/Gaps';
import i18n from 'components/translation/i18n';
import * as Styled from './GapsAndAuditFindings.styled';

const { useToken } = theme;

interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-node-key': string;
  onActiveBarTransform: (className: string) => void;
}

const DraggableTabNode = ({
  className,
  onActiveBarTransform,
  ...props
}: DraggableTabPaneProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting
  } = useSortable({
    id: props['data-node-key']
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move'
  };

  useEffect(() => {
    if (!isSorting) {
      onActiveBarTransform('');
    } else if (className?.includes('ant-tabs-tab-active')) {
      onActiveBarTransform(
        css`
          .ant-tabs-ink-bar {
            transform: ${CSS.Transform.toString(transform)};
            transition: ${transition} !important;
          }
        `
      );
    }
  }, [className, isSorting, transform]);

  return React.cloneElement(props.children as React.ReactElement, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners
  });
};

const GapsTable: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const i18nInitialization = i18n();

  const { assignedEls, assignedEvidences, infoSave, gapsSaveData } =
    useSelector((state: RootState) => state.gaps);
  const lang = useSelector((state: RootState) => state.global.lang);

  const [activeTab, setActiveTab] = useState<GapTabs | string>(
    GapTabs.AUDIT_FINDINGS
  );
  const [activeKey, setActiveKey] = useState<string>('1');
  const [className, setClassName] = useState('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [keyTobeSwitched, setKeyTobeSwitched] = useState<GapTabs | string>('');
  const [items, setItems] = useState([
    {
      key: '1',
      label: i18nInitialization.t('AUDIT_FINDINGS_MAPPING'),
      children: <AuditFindingsTable />
    },
    {
      key: '2',
      label: i18nInitialization.t('EVIDENCE_MAPPING'),
      children: <EvidenceMappingTable />
    },
    {
      key: '3',
      label: i18nInitialization.t('GAPS_DESCRIPTION'),
      children: <GapsDescriptionTable />
    },
    {
      key: '4',
      label: i18nInitialization.t('ADDITIONAL_DESCRIPTION'),
      children: <AdditionalInfoTable />
    }
  ]);

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 }
  });

  const handleTabClick = (key: string, tabValue: GapTabs) => {
    if (
      (activeTab === GapTabs.AUDIT_FINDINGS && assignedEls.length) ||
      (activeTab === GapTabs.EVIDENCES && assignedEvidences.length) ||
      (activeTab === GapTabs.GAPS_DESC && !Helpers.isObjEmpty(gapsSaveData)) ||
      (activeTab === GapTabs.ADDITIONAL_INFO && !Helpers.isObjEmpty(infoSave))
    ) {
      setKeyTobeSwitched(key);
      setModalOpen(true);

      return;
    } else {
      setActiveTab(tabValue);
      setActiveKey(key);
      dispatch(complianceEstOp.setDrawTable(false));
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalOk = () => {
    dispatch(complianceEstOp.setSaveClicked(true));
    setModalOpen(false);

    if (keyTobeSwitched) {
      setActiveKey(keyTobeSwitched);

      const foundObject = items.find((obj) => obj.key === keyTobeSwitched);

      if (foundObject) {
        setActiveTab(foundObject.label);
      }

      setKeyTobeSwitched('');
    }
  };

  const handleModalCancel = () => {
    setModalOpen(false);
  };

  const handleDiscardChanges = () => {
    handleModalCancel();

    switch (activeTab) {
      case GapTabs.AUDIT_FINDINGS:
        if (assignedEls.length) {
          dispatch(gapsOp.setAssignedEls([]));
          dispatch(gapsOp.setAssignedAuditFindingsUpdated(false));
        }
        break;
      case GapTabs.EVIDENCES:
        if (assignedEvidences.length) {
          dispatch(gapsOp.setAssignedEvidences([]));
          dispatch(gapsOp.setAssignedEvidencesUpdated(true));
        }
        break;
      case GapTabs.GAPS_DESC:
        if (!Helpers.isObjEmpty(gapsSaveData)) {
          dispatch(gapsOp.setGapsSaveData({}));
          dispatch(gapsOp.setGapsUpdated(true));
        }
        break;
      case GapTabs.ADDITIONAL_INFO:
        if (!Helpers.isObjEmpty(infoSave)) {
          dispatch(gapsOp.setInfoSaveData({}));
          dispatch(gapsOp.setInfoUpdated(true));
        }
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    const updatedItems = items.map((item) => {
      switch (item.key) {
        case '1':
          return {
            ...item,
            label: i18nInitialization.t('AUDIT_FINDINGS_MAPPING')
          };
        case '2':
          return { ...item, label: i18nInitialization.t('EVIDENCE_MAPPING') };
        case '3':
          return { ...item, label: i18nInitialization.t('GAPS_DESCRIPTION') };
        case '4':
          return {
            ...item,
            label: i18nInitialization.t('ADDITIONAL_DESCRIPTION')
          };
        default:
          return item;
      }
    });
    setItems(updatedItems);
  }, [lang]);

  return (
    <ComplianceEstLayout activeTab={activeTab} activeKey={activeKey}>
      <Styled.ContentWrapper token={token}>
        <Tabs
          className={className}
          defaultActiveKey='1'
          activeKey={activeKey}
          onTabClick={(key: string, event: any) =>
            handleTabClick(key, event.target.textContent)
          }
          items={items}
          renderTabBar={(tabBarProps, DefaultTabBar) => (
            <DndContext
              sensors={[sensor]}
              onDragEnd={({ active, over }: DragEndEvent) =>
                Helpers.onDragEnd(
                  {
                    active,
                    over
                  },
                  setItems,
                  'key'
                )
              }
            >
              <SortableContext
                items={items.map((i) => i.key)}
                strategy={horizontalListSortingStrategy}
              >
                <DefaultTabBar {...tabBarProps}>
                  {(node) => (
                    <DraggableTabNode
                      {...node.props}
                      key={node.key}
                      onActiveBarTransform={setClassName}
                    >
                      {node}
                    </DraggableTabNode>
                  )}
                </DefaultTabBar>
              </SortableContext>
            </DndContext>
          )}
        />
        <SaveModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleOk={handleModalOk}
          handleCancel={handleModalCancel}
          handleOpen={handleModalOpen}
          handleDiscard={handleDiscardChanges}
        />
      </Styled.ContentWrapper>
    </ComplianceEstLayout>
  );
};

export default GapsTable;
