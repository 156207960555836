export const environment = {
  production: false,
  serverUrl: 'https://devbackapi.auriscom.de',
  loginUrl: 'https://devbackapi.auriscom.de/login',
  signUpUrl: 'https://devbackapi.auriscom.de/signup',
  application: {},
  config: {
    api: false,
    url: './assets/params/json/crud/'
  }
};
