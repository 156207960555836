import styled from 'styled-components';
import { Input } from 'antd';
import { IToken } from 'store/ducks/globalTypes';

export const StyledMainDiv = styled('div')<IToken>`
  background-color: ${(props) => props.token.colorBgContainer};
  padding: ${(props) => `${props.token.paddingSM}px`};
  border-radius: ${(props) => `${props.token.borderRadiusSM}px`};
`;

export const StyledDiv = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledInput = styled(Input)<IToken>`
  width: 225px;
  margin-right: ${(props) => `${props.token.marginXXS}px`};
`;
