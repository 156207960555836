import { notification } from 'antd';
import { Dispatch } from '@reduxjs/toolkit';
import ApiService from 'utils/ApiCalls';
import tasksSlice from './tasksSlice';
import complianceEstSlice from '../ComplienceEst';
import { ITask, ITasksResult, ITasksToShow } from './types';
import { LanguageList } from '../globalTypes';

const {
  set_loading,
  set_tasks,
  set_tasks_to_show,
  add_new_task,
  delete_task,
  edit_task,
  reset_tasks_to_save
} = tasksSlice.actions;

const { set_save_clicked } = complianceEstSlice.actions;

const fetchTasks = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getTasks(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const setTasks = (tasks: ITasksResult[]) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading(true));
    dispatch(set_tasks(tasks));
  } catch (error) {
    console.error(error);
  }
};

const addNewTask = (sol_id: number) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading(true));
    dispatch(add_new_task(sol_id));
  } catch (error) {
    console.error(error);
  }
};

const setTasksToShow = (tasksToShow: ITasksToShow) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading(true));
    dispatch(set_tasks_to_show(tasksToShow));
  } catch (error) {
    console.error(error);
  }
};

const editTaskValue =
  (tskId: string | number, taskData: ITask, key: string, value: string) =>
  (dispatch: Dispatch) => {
    try {
      dispatch(set_loading(true));
      dispatch(edit_task({ tskId, taskData, key, value }));
    } catch (error) {
      console.error(error);
    }
  };

const deleteTask =
  (taskId: number | string, solId: number) => async (dispatch: Dispatch) => {
    try {
      if (typeof taskId === 'number') {
        const response = await ApiService.removeTask(taskId);

        notification.success({
          message: response.status,
          description: 'Task successfully deleted'
        });
      }

      dispatch(delete_task({ taskId, solId }));
    } catch (error) {
      console.error(error);
    }
  };

const saveTasks =
  (data: ITask[], lang: LanguageList) => async (dispatch: Dispatch) => {
    try {
      dispatch(set_loading(true));
      const response = await ApiService.saveTasks(data, lang);

      notification.success({
        message: response.status,
        description: 'Tasks successfully saved'
      });

      dispatch(set_loading(false));
      dispatch(set_save_clicked(false));
      dispatch(reset_tasks_to_save());
    } catch (error) {
      dispatch(set_loading(false));
      console.error(error);
    }
  };

export default {
  fetchTasks,
  setTasks,
  setTasksToShow,
  addNewTask,
  deleteTask,
  saveTasks,
  editTaskValue
};
