import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import initialReportingState from './initialState';
import { IReportingState } from './types';
import { IFilters } from '../globalTypes';

const reportingSlice = createSlice({
  name: 'reporting',
  initialState: initialReportingState(),
  reducers: {
    set_draw_table(state: IReportingState, action: PayloadAction<boolean>) {
      state.drawTable = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_loading(state: IReportingState) {
      state.loading = true;
    },
    set_save_disabled(state: IReportingState, action: PayloadAction<boolean>) {
      state.saveDisabled = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_save_clicked(state: IReportingState, action: PayloadAction<boolean>) {
      state.saveClicked = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_filters_data(
      state: IReportingState,
      action: PayloadAction<IFilters[]>
    ) {
      state.filtersData = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_error(state: IReportingState, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export default reportingSlice;
