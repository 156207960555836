import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import { notification } from 'antd';
import gapsSlice from './gapsSlice';
import {
  IAdditionalInfoData,
  IAssignedEvidence,
  IAuditData,
  IEvidenceData,
  IGaps,
  IGapsInfo,
  IGapsTableData,
  ISaveInfo,
  ISaveInfoData,
  ITableFilters
} from './types';
import ApiService from 'utils/ApiCalls';
import { IAssignedEls, IAssignedEvidences } from '../ComplienceEst/types';
import { LanguageList } from '../globalTypes';

const {
  set_loading,
  set_assigned_audit_findings_updated,
  set_assigned_evidences_updated,
  set_auditFindings_mapping,
  set_assigned_els,
  set_assigned_evidences,
  set_table_filters,
  set_evidence_mapping,
  set_gaps_description,
  set_gaps_table_data,
  set_additional_info,
  set_selected_evidence_module,
  set_clicked_handle_move_button,
  set_clicked_handle_evidence_move_button,
  set_info_save,
  set_gaps_save_data,
  set_gaps_updated,
  set_info_updated
} = gapsSlice.actions;

const fetchAuditsSave = async (data: IAssignedEls[]) => {
  try {
    const response = await ApiService.saveAudits(data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteAudits = async (params: { afg_id: number }) => {
  try {
    const response = await ApiService.removeAudits(params);
    const { status, data } = response;

    notification.success({
      message: status,
      description: data.message
    });
  } catch (error) {
    console.error(error);
  }
};

const deleteEvidences = async (params: { evd_id: number }) => {
  try {
    const response = await ApiService.removeEvidences(params);
    const { status, data } = response;

    notification.success({
      message: status,
      description: data.message
    });
  } catch (error) {
    console.error(error);
  }
};

const applyFiltersModuleApi = async (filtersData: any, lang: LanguageList) => {
  try {
    const response = await ApiService.setFiltersModule(filtersData, lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchEvidencesSave = async (data: IAssignedEvidences[]) => {
  try {
    await ApiService.saveEvidences(data);
  } catch (error) {
    console.error(error);
  }
};

const fetchAudits = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getAuditFindings(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchEvidences = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getUnassignedEvidences(lang);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const setAssignedAuditFindingsUpdated =
  (updated: boolean) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_assigned_audit_findings_updated(updated));
    } catch (error) {
      console.error(error);
    }
  };

const setSelectedEvidenceModule =
  (selected: boolean) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_selected_evidence_module(selected));
    } catch (error) {
      console.error(error);
    }
  };

const setClickedHandleMoveButton =
  (clicked: boolean) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_clicked_handle_move_button(clicked));
    } catch (error) {
      console.error(error);
    }
  };

const setClickedHandleEvidenceMoveButton =
  (clicked: boolean) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_clicked_handle_evidence_move_button(clicked));
    } catch (error) {
      console.error(error);
    }
  };

const setAssignedEvidencesUpdated =
  (updated: boolean) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_assigned_evidences_updated(updated));
    } catch (error) {
      console.error(error);
    }
  };

const setGapsTableData = (data: IGapsTableData[]) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_gaps_table_data(data));
  } catch (error) {
    console.error(error);
  }
};

const setAssignedEls =
  (data: IAssignedEvidence | IAssignedEvidence[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_assigned_els(data));
    } catch (error) {
      console.error(error);
    }
  };

const setAssignedEvidences =
  (data: IAssignedEvidence | IAssignedEvidence[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_assigned_evidences(data));
    } catch (error) {
      console.error(error);
    }
  };

const setTableFilter = (filter: ITableFilters) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_table_filters(filter));
  } catch (error) {
    console.error(error);
  }
};

const setAuditFindingsMapping =
  (audits: IAuditData[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_auditFindings_mapping(audits));
    } catch (error) {
      console.error(error);
    }
  };

const setEvidenceData =
  (evidences: IEvidenceData[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_evidence_mapping(evidences));
    } catch (error) {
      console.error(error);
    }
  };

const setAdditionalInfo =
  (data: IAdditionalInfoData[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_additional_info(data));
    } catch (error) {
      console.error(error);
    }
  };

const setGapsDescription =
  (gapsDesc: IGapsTableData[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_gaps_description(gapsDesc));
    } catch (error) {
      console.error(error);
    }
  };

const fetchPrioritization = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getPrioritization(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchAuditFindingsMapping = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getAuditFindingMapping(lang);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchEvidenceMapping = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getEvidenceMapping(lang);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchGapSource = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getGapSource(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchGapsDescription = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getGapsDescription(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchAdditionalInfo = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getAdditionalInfo(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const saveGaps = async (data: IGapsInfo[], lang: LanguageList) => {
  try {
    const response = await ApiService.addGaps(data, lang);

    notification.success({
      message: response.status,
      description: response.data.message
    });
  } catch (error) {
    console.error(error);
  }
};

const saveGapsInfo = async (data: ISaveInfo[]) => {
  try {
    const response = await ApiService.addGapsInfo(data);

    notification.success({
      message: response.status,
      description: response.data.message
    });
  } catch (error) {
    console.error(error);
  }
};

const deleteGap = async (id: number) => {
  try {
    const response = await ApiService.removeGap(id);
    const { status, data } = response;

    notification.success({
      message: status,
      description: data.message
    });
  } catch (error) {
    console.error(error);
  }
};

const setInfoSaveData = (data: ISaveInfoData) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_info_save(data));
  } catch (error) {
    console.error(error);
  }
};

const setGapsSaveData = (data: IGaps) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_gaps_save_data(data));
  } catch (error) {
    console.error(error);
  }
};

const setGapsUpdated = (updated: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_gaps_updated(updated));
  } catch (error) {
    console.error(error);
  }
};

const setInfoUpdated = (updated: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_info_updated(updated));
  } catch (error) {
    console.error(error);
  }
};

export default {
  deleteAudits,
  deleteEvidences,
  applyFiltersModuleApi,
  fetchAuditsSave,
  fetchEvidencesSave,
  fetchAudits,
  fetchEvidences,
  setAssignedAuditFindingsUpdated,
  setSelectedEvidenceModule,
  setClickedHandleMoveButton,
  setClickedHandleEvidenceMoveButton,
  setAssignedEvidencesUpdated,
  fetchPrioritization,
  fetchGapSource,
  setEvidenceData,
  setAdditionalInfo,
  setAssignedEls,
  setAssignedEvidences,
  fetchAuditFindingsMapping,
  fetchEvidenceMapping,
  fetchGapsDescription,
  setTableFilter,
  setGapsDescription,
  setAuditFindingsMapping,
  setGapsTableData,
  fetchAdditionalInfo,
  saveGaps,
  deleteGap,
  saveGapsInfo,
  setInfoSaveData,
  setGapsSaveData,
  setGapsUpdated,
  setInfoUpdated
};
