import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { Layout, theme } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import qs from 'qs';
import { Content } from 'antd/es/layout/layout';
import CustomSelect from 'components/CustomSelect';
import TitleBar from 'components/TitleBar';
import GoBackButton from 'components/GoBackButton';
import { reportingOp } from 'store/ducks/Reporting';
import { RootState } from 'store/configureStore';
import { gapsOp } from 'store/ducks/Gaps';
import {
  IFilters,
  TitleListEN,
  TitleListDE,
  TitleListES,
  TitleListFR,
  TitleListIT,
  TitleListPT
} from 'store/ducks/globalTypes';
import filterNames from 'constants/filterNames';
import FilterNames from 'constants/filterNamesTranslations';
import * as Styled from 'components/Layouts/Layout.styled';

const { Sider } = Layout;
const { useToken } = theme;

const FiltersParam = withDefault(ArrayParam, []);

interface IReportingLayoutProps {
  activeTab?: string;
  activeKey?: string;
  children: JSX.Element;
}

const ReportingLayout: React.FC<IReportingLayoutProps> = ({
  activeTab,
  activeKey,
  children
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { token } = useToken();

  const page = location.pathname.split('/').pop();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  const filterPlaceholder = FilterNames();
  const [filtersvisible, setFiltersVisible] = useState(false);

  const [query, setQuery] = useQueryParams({
    industry: FiltersParam,
    country: FiltersParam,
    module: FiltersParam,
    entity: FiltersParam,
    category: FiltersParam,
    product: FiltersParam,
    regulator: FiltersParam
  });

  const {
    lang,
    // saveDisabled,
    // drawTable,
    filtersData,
    gapsUpdated,
    infoUpdated
  } = useSelector((state: RootState) => ({
    lang: state.global.lang,
    // drawTable: state.reporting.drawTable,
    // saveDisabled: state.reporting.saveDisabled,
    filtersData: state.reporting.filtersData,
    gapsUpdated: state.gaps.gapsUpdated,
    infoUpdated: state.gaps.infoUpdated
  }));

  const handleFilterChange = (value: string[], item: string) => {
    setQuery((latestQuery: any) => ({
      ...latestQuery,
      [item.toLowerCase()]: value
    }));
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersvisible);
  };

  const applyFilters = () => {
    switch (page) {
      case 'reporting':
        // reportingOp.applyFiltersApi(lang).then((data) => {
        //   dispatch(clientReqOp.setAppliedFilersResult(data));
        // })
        break;

      default:
        return;
    }

    dispatch(reportingOp.setDrawTable(true));
  };

  const getFilterObj = (item: string): IFilters[] => {
    return filtersData.length > 0
      ? filtersData.filter((obj) => obj.filter === item)
      : [];
  };

  // const handleSave = () => {
  //   dispatch(reportingOp.setSaveClicked(true));
  // };

  useEffect(() => {
    dispatch(reportingOp.setFilters(lang));
  }, []);

  useEffect(() => {
    dispatch(reportingOp.setDrawTable(false));
  }, [activeTab, activeKey]);

  useEffect(() => {
    if (gapsUpdated || infoUpdated) {
      applyFilters();
      dispatch(gapsOp.setGapsUpdated(false));
      dispatch(gapsOp.setInfoUpdated(false));
    }
  }, [gapsUpdated, infoUpdated]);
  return (
    <Styled.StyledLayout token={token} filtersvisible={false}>
      <TitleBar
        title={(() => {
          switch (lang) {
            case 'en':
              return TitleListEN.REPORTING;
            case 'de':
              return TitleListDE.REPORTING;
            case 'es':
              return TitleListES.REPORTING;
            case 'fr':
              return TitleListFR.REPORTING;
            case 'it':
              return TitleListIT.REPORTING;
            case 'pt':
              return TitleListPT.REPORTING;
            default:
              return TitleListEN.REPORTING;
          }
        })()}
      />
      <Styled.StyledHeader token={token}>
        <GoBackButton />
      </Styled.StyledHeader>
      <Styled.StyledLayout token={token} filtersvisible={false}>
        <Layout>
          <Content>{children}</Content>
        </Layout>
        <Sider>
          <Styled.OpenCloseButton type='primary' onClick={toggleFilters}>
            {filtersvisible ? <CloseOutlined /> : <CheckOutlined />}
          </Styled.OpenCloseButton>
          {filtersvisible && (
            <Styled.FilterContainer>
              {filterNames.map((item: any) => (
                <CustomSelect
                  key={item}
                  mode='multiple'
                  placeholder={item}
                  onChange={(value: string[]) =>
                    handleFilterChange(value, item)
                  }
                  options={
                    getFilterObj(item).length !== 0 &&
                    getFilterObj(item)[0]?.options
                  }
                  filters
                />
              ))}
              <Styled.StyledButton type='primary' onClick={applyFilters}>
                Display
              </Styled.StyledButton>
            </Styled.FilterContainer>
          )}
        </Sider>
      </Styled.StyledLayout>
    </Styled.StyledLayout>
  );
};

export default ReportingLayout;
