import { YesNo } from 'store/ducks/globalTypes';

const newSolObj = (regId: number) => ({
  bru_id: regId,
  approver: 'TBD@TBD.comq',
  evd_required: YesNo.YES,
  sol_desc: '',
  sol_type: 'Bus'
});

export default newSolObj;
