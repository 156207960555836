import React from 'react';
import { Navigate } from 'react-router-dom';
import Helpers from 'utils/Helpers';

interface IPrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children }) => {
  const token = Helpers.getToken();

  return token && !Helpers.isObjEmpty(token) ? (
    <>{children}</>
  ) : (
    <Navigate to='/' />
  );
};

export default PrivateRoute;
