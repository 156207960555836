import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, List, theme, Tooltip } from 'antd';
import { Comment } from '@ant-design/compatible';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { CommentItem, CommentTable } from 'store/ducks/ComplienceEst/types';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import Helpers from 'utils/Helpers';
import CustomIconButton from '../CustomIconButton';
import * as Styled from './CommentSection.styled';

const { useToken } = theme;

interface ICommentSection {
  data: CommentItem[];
  reqId: number;
  tableRef: CommentTable;
}

const CommentSection: React.FC<ICommentSection> = ({
  data,
  reqId,
  tableRef
}) => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const [form] = Form.useForm();

  let newComment;

  const onSubmit = async (values: { comment: string }) => {
    newComment = {
      res_table_ref: tableRef,
      res_table_pk_ref: data.length ? data[0].res_table_pk_ref : reqId,
      res_comment: values.comment
    };

    await complianceEstOp.saveComment([newComment]);

    form.resetFields();

    complianceEstOp.fetchComments(tableRef).then((responseData) => {
      dispatch(complianceEstOp.setCommentsData(responseData));
    });
  };

  const handleDelete = async (commentId?: number) => {
    if (!commentId) return;

    await complianceEstOp.deleteComment(commentId);

    complianceEstOp.fetchComments(tableRef).then((responseData) => {
      dispatch(complianceEstOp.setCommentsData(responseData));
    });
  };

  return (
    <Styled.StyledMainDiv token={token} key={reqId}>
      {data.length ? (
        <List
          className='comment-list'
          header={`${data.length} comments`}
          itemLayout='horizontal'
          dataSource={data}
          renderItem={(item: CommentItem) => (
            <li>
              <Styled.StyledDiv>
                <Comment
                  author={item.user}
                  content={<p>{item.res_comment}</p>}
                  datetime={
                    <span>{Helpers.formatDate(item.res_comment_time)}</span>
                  }
                />
                <Tooltip title='delete comment'>
                  <Button
                    type='text'
                    shape='circle'
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(item.res_id)}
                  />
                </Tooltip>
              </Styled.StyledDiv>
            </li>
          )}
        />
      ) : (
        <p>No Comments yet.</p>
      )}
      <div className='ui small form'>
        <Form
          form={form}
          name='comment'
          onFinish={onSubmit}
          layout='inline'
          autoComplete='off'
        >
          <Form.Item name='comment'>
            <Styled.StyledInput token={token} placeholder='Add comment...' />
          </Form.Item>
          <CustomIconButton
            htmlType='submit'
            type='primary'
            shape='circle'
            icon={<PlusOutlined />}
            size='middle'
          />
        </Form>
      </div>
    </Styled.StyledMainDiv>
  );
};

export default CommentSection;
