import { combineReducers } from 'redux';
import complianceEstSlice from './ducks/ComplienceEst';
import clientReqSlice from './ducks/ClientReq';
import gapsSlice from './ducks/Gaps';
import globalSlice from './ducks/Global';
import solutionsSlice from './ducks/Solutions';
import tasksSlice from './ducks/Tasks';
import evidencesSlice from './ducks/Evidences';
import managementSlice from './ducks/Management';
import reportingSlice from './ducks/Reporting';

export default combineReducers({
  complianceEst: complianceEstSlice.reducer,
  clientReq: clientReqSlice.reducer,
  gaps: gapsSlice.reducer,
  global: globalSlice.reducer,
  solutions: solutionsSlice.reducer,
  tasks: tasksSlice.reducer,
  evidences: evidencesSlice.reducer,
  management: managementSlice.reducer,
  reporting: reportingSlice.reducer
});
