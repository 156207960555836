import styled from 'styled-components';
import { Button } from 'antd';
import { IToken } from 'store/ducks/globalTypes';

export const StyledButton = styled(Button)<IToken>`
  background-color: ${(props) => props.token.colorFillContent};

  &:not(:disabled):hover {
    background-color: ${(props) => props.token.colorFillContentHover};
  }
`;
