import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DeleteOutlined, CommentOutlined } from '@ant-design/icons';
import { Popover, theme, message, Popconfirm } from 'antd';
import { CommentItem, CommentTable } from 'store/ducks/ComplienceEst/types';
import { RootState } from 'store/configureStore';
import { tasksOp } from 'store/ducks/Tasks';
import { ITask } from 'store/ducks/Tasks/types';
import CustomTextarea from '../CustomTextarea';
import CustomIconButton from '../CustomIconButton';
import CommentSection from '../CommentSection';
import * as Styled from './TextAreaBoxTasks.styled';

interface ITextAreaBoxProps {
  task: ITask;
  solId: number;
  taskId: string | number;
}

interface IPopoverState {
  [id: number | string]: boolean;
}

const { useToken } = theme;

const cancel = () => {
  message.error('Task was not deleted');
};

export const TextAreaBoxTasks: React.FC<ITextAreaBoxProps> = ({
  task,
  solId,
  taskId
}) => {
  const { token } = useToken();
  const dispatch = useDispatch();

  const { commentsData } = useSelector(
    (state: RootState) => state.complianceEst
  );

  const [popoverState, setPopoverState] = useState<IPopoverState>({});

  const handleDeleteClick = () => {
    dispatch(tasksOp.deleteTask(taskId, solId));
  };

  const hide = (id: number | string) => {
    if (!id) return;

    setPopoverState((prevState) => ({
      ...prevState,
      [id]: false
    }));
  };

  const drawComments = () => {
    const filteredComments = commentsData.length
      ? commentsData.filter(
          (commentsDataItem: CommentItem) =>
            commentsDataItem.res_table_pk_ref === taskId
        )
      : [];

    return (
      <>
        <CommentSection
          reqId={solId}
          data={filteredComments}
          tableRef={CommentTable.TASKS}
        />
        <a onClick={() => hide(taskId)}>Close</a>
      </>
    );
  };

  const handleOpenChange = (id: number | string) => {
    if (!id) return;

    setPopoverState((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  return (
    <Styled.TextAreaElementsContainer>
      <Styled.TextAreaElementsContainer>
        <CustomTextarea
          placeholder='Type Task'
          defaultValue={task.tsk_desc}
          onChange={(e) =>
            dispatch(
              tasksOp.editTaskValue(taskId, task, 'tsk_desc', e.target.value)
            )
          }
        />
        <Styled.StyledButtonSection token={token}>
          <Popover
            placement='leftBottom'
            trigger='click'
            content={drawComments}
            title='Comments'
            open={!!(taskId && popoverState[taskId])}
            onOpenChange={() => handleOpenChange(taskId)}
          >
            <CustomIconButton
              type='primary'
              shape='circle'
              icon={<CommentOutlined />}
              size='small'
              cursor='pointer'
            />
          </Popover>

          <Popconfirm
            title='Delete the task'
            description='Are you sure to delete this task?'
            onConfirm={handleDeleteClick}
            onCancel={cancel}
            okText='Yes'
            cancelText='No'
          >
            <Styled.StyledCustomIconButton
              token={token}
              type='primary'
              shape='circle'
              icon={<DeleteOutlined />}
              cursor='pointer'
              size='small'
            />
          </Popconfirm>
        </Styled.StyledButtonSection>
      </Styled.TextAreaElementsContainer>
    </Styled.TextAreaElementsContainer>
  );
};
