import { YesNo } from 'store/ducks/globalTypes';

const gapNoticedOptions = [
  {
    value: 'all',
    text: 'All'
  },
  {
    value: 'noticed',
    text: 'Noticed'
  },
  {
    value: 'notNoticed',
    text: 'Not Noticed'
  }
];

const applicabilityOptions = [
  {
    value: 'all',
    text: 'All'
  },
  {
    value: YesNo.YES,
    text: 'Yes'
  },
  {
    value: YesNo.NO,
    text: 'No'
  }
];

export { gapNoticedOptions, applicabilityOptions };
