import React from 'react';
import Login from 'pages/Login';
import SignUp from 'pages/Signup';
import WorkingAreasPage from 'pages/WorkingAreas';
import ComplianceEst from 'pages/ClientRequirements';
import GapsAndAuditFindings from 'pages/GapsAndAuditFindings';
import SolutionDefinition from 'pages/SolutionDefinition';
import EvidenceDefinition from 'pages/EvidenceDefinition';
import TasksDefinition from 'pages/TasksDefinition';
import AuditFindingsManagement from 'pages/AuditFindingsManagement';
import CategoryManagement from 'pages/CategoryManagement';
import ProductManagement from 'pages/ProductManagement';
import ForgotPass from 'pages/ForgotPass';
import ResetPass from 'pages/ResetPass';
import Reporting from 'pages/Reporting';
// import InternalControls from 'pages/InternalControls';

const routes = [
  {
    id: 1,
    path: '/',
    private: false,
    component: () => <Login />
  },
  {
    id: 2,
    path: '/signup',
    private: false,
    component: () => <SignUp />
  },
  {
    id: 3,
    path: '/WorkingAreas',
    private: true,
    component: () => <WorkingAreasPage />
  },
  {
    id: 4,
    path: '/complianceEst/clientReq',
    private: true,
    component: () => <ComplianceEst />
  },
  {
    id: 5,
    path: '/complianceEst/gapsAndAuditFindings',
    private: true,
    component: () => <GapsAndAuditFindings />
  },
  {
    id: 6,
    path: '/complianceEst/solutionDefinition',
    private: true,
    component: () => <SolutionDefinition />
  },
  {
    id: 7,
    path: '/complianceEst/tasksDefinition',
    private: true,
    component: () => <TasksDefinition />
  },
  {
    id: 8,
    path: '/complianceEst/evidenceDefinition',
    private: true,
    component: () => <EvidenceDefinition />
  },
  {
    id: 9,
    path: '/dataManagement/auditFindings',
    private: true,
    component: () => <AuditFindingsManagement />
  },
  {
    id: 10,
    path: '/dataManagement/category',
    private: true,
    component: () => <CategoryManagement />
  },
  {
    id: 11,
    path: '/dataManagement/product',
    private: true,
    component: () => <ProductManagement />
  },
  {
    id: 12,
    path: '/forgot_pass',
    private: false,
    component: () => <ForgotPass />
  },
  {
    id: 13,
    path: '/reset_pass',
    private: false,
    component: () => <ResetPass />
  },
  {
    id: 14,
    path: '/reporting',
    private: false,
    component: () => <Reporting />
  }
  // {
  //   id: 15,
  //   path: '/internal_controls',
  //   private: false,
  //   component: () => <InternalControls />
  // }
];

export default routes;
