import * as React from 'react';
import { UserAddOutlined } from '@ant-design/icons';
import { Form, Input, Row, Col, theme } from 'antd';
import signUpFormItems from 'constants/signupFormItems';
import Copyright from 'components/Copyright';
import CustomTitle from 'components/CustomTitle';
import * as Styled from './Signup.styled';

const { useToken } = theme;

export const SignUp: React.FC = () => {
  const { token } = useToken();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const requestData = {
      first_name: data.get('firstName'),
      middle_name: data.get('middleName'),
      last_name: data.get('lastName'),
      email: data.get('email'),
      password: data.get('password'),
      confirm_password: data.get('confirmPassword')
    };
    //
    // register(requestData).then(
    //   () => {
    //     navigate('/');
    //   },
    //   (error: any) => {
    //     const resMessage =
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       error.message ||
    //       error.toString();
    //   }
    // );
  };

  return (
    <Styled.StyledBackground>
      <div>
        <Styled.FormContainer token={token}>
          <Styled.IconContainer token={token}>
            <UserAddOutlined />
          </Styled.IconContainer>
          <CustomTitle title='Sign up' level={3} />
          <Form
            name='basic'
            layout='vertical'
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            autoComplete='off'
          >
            <Row gutter={16}>
              <Col span={12}>
                <Styled.StyledFormItem
                  token={token}
                  label='First Name'
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your first name!'
                    }
                  ]}
                >
                  <Input type='text' />
                </Styled.StyledFormItem>
              </Col>

              <Col span={12}>
                <Styled.StyledFormItem
                  token={token}
                  label='Middle Name'
                  name='middleName'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your middle name!'
                    }
                  ]}
                >
                  <Input type='text' />
                </Styled.StyledFormItem>
              </Col>
            </Row>

            {signUpFormItems.map((item) => (
              <Styled.StyledFormItem
                token={token}
                key={item.name}
                label={item.label}
                name={item.name}
                rules={item.rules}
              >
                <Input type={item.type} />
              </Styled.StyledFormItem>
            ))}

            <Styled.StyledButton token={token} type='primary' size='large'>
              Sign Up
            </Styled.StyledButton>
            <Row justify='end'>
              <Styled.StyledLinkButton token={token} type='link' href='/'>
                Already have an account? Sign in
              </Styled.StyledLinkButton>
            </Row>
          </Form>
        </Styled.FormContainer>
        <Copyright />
      </div>
    </Styled.StyledBackground>
  );
};

export default SignUp;
