import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import initialClientReqState from './initialState';
import {
  IClientReqState,
  ICheckedAurReqs,
  INewClientReqs,
  ITableFilters,
  IClientReqToBeAdded,
  IRegulatoryReq,
  IClientReq
} from './types';

const clientReqSlice = createSlice({
  name: 'clientReq',
  initialState: initialClientReqState(),
  reducers: {
    set_applied_filters_result(
      state: IClientReqState,
      action: PayloadAction<IRegulatoryReq[]>
    ) {
      state.appliedFiltersResult = Object.values(action.payload);
      state.error = false;
      state.loading = false;
    },
    set_new_client_reqs(
      state: IClientReqState,
      action: PayloadAction<IClientReqToBeAdded>
    ) {
      const aurReq = state.clientReqs[action.payload.aurReqId];
      const clientReqs = aurReq.client_reqs;
      const updatedClientReqs = aurReq.updated_client_reqs;

      state.clientReqs = {
        ...state.clientReqs,
        [action.payload.aurReqId]: {
          ...aurReq,
          client_reqs: { ...clientReqs, ...action.payload.updatedAurReqs },
          updated_client_reqs: {
            ...updatedClientReqs,
            ...action.payload.updatedAurReqs
          }
        }
      };
      state.error = false;
      state.loading = false;
    },
    set_client_reqs(
      state: IClientReqState,
      action: PayloadAction<INewClientReqs>
    ) {
      state.clientReqs = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_checked_elements_toBe_merged(
      state: IClientReqState,
      action: PayloadAction<number[]>
    ) {
      state.checkedElementsToBeMerged = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_checked_elements_toBe_copied(
      state: IClientReqState,
      action: PayloadAction<number[]>
    ) {
      state.checkedElementsToBeCopied = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_merged_elements(
      state: IClientReqState,
      action: PayloadAction<number[]>
    ) {
      state.mergedElements = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_copied_elements(
      state: IClientReqState,
      action: PayloadAction<number[]>
    ) {
      state.copiedElements = action.payload;
      state.error = false;
      state.loading = false;
    },
    update_client_req(
      state: IClientReqState,
      action: PayloadAction<{
        value: IClientReq;
        clnReqKey: string;
        aurReqId: number | string;
      }>
    ) {
      const { value, clnReqKey, aurReqId } = action.payload;
      const oldState = current(state);
      const aurReq = oldState.clientReqs[aurReqId];

      const updatedValues = {
        [aurReqId]: {
          ...aurReq,
          updated_client_reqs: {
            ...aurReq.updated_client_reqs,
            [clnReqKey]: { ...value }
          }
        }
      };

      state.clientReqs = {
        ...oldState.clientReqs,
        [aurReqId]: updatedValues[aurReqId]
      };
      state.error = false;
      state.loading = false;
    },
    reset_new_client_reqs(state: IClientReqState) {
      const oldState = current(state);

      Object.keys(oldState.clientReqs).forEach((key: string | number) => {
        state.clientReqs = {
          ...oldState.clientReqs,
          [key]: {
            ...oldState.clientReqs[key],
            updated_client_reqs: {}
          }
        };
      });

      state.error = false;
      state.loading = false;
    },
    set_is_app_statuses(
      state: IClientReqState,
      action: PayloadAction<ICheckedAurReqs>
    ) {
      state.applicabilityStatuses = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_filtered_data(
      state: IClientReqState,
      action: PayloadAction<IRegulatoryReq[]>
    ) {
      state.filteredData = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_checked_aur_reqs(
      state: IClientReqState,
      action: PayloadAction<number>
    ) {
      state.checkedAurReqs = state.checkedAurReqs.includes(action.payload)
        ? state.checkedAurReqs.filter((i: number) => i !== action.payload)
        : [...state.checkedAurReqs, action.payload];
      state.error = false;
      state.loading = false;
    },
    set_table_filters(
      state: IClientReqState,
      action: PayloadAction<ITableFilters>
    ) {
      state.tableFilters = { ...state.tableFilters, ...action.payload };
      state.error = false;
      state.loading = false;
    },
    set_updated(state: IClientReqState, action: PayloadAction<boolean>) {
      state.updated = action.payload;
    },
    set_loading(state: IClientReqState) {
      state.loading = true;
    },
    set_error(state: IClientReqState, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export default clientReqSlice;
