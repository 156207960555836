import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, theme } from 'antd';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import Droppable from '../Droppable';
import i18n from 'components/translation/i18n';
import DraggableBox from '../DraggableBox';
import DragTextBox from '../DragTextBox';
import { RootState } from 'store/configureStore';
import { IAssignedEls } from 'store/ducks/ComplienceEst/types';
import { gapsOp } from 'store/ducks/Gaps';
import { IAssignedEvidence, IUnassignedAudit } from 'store/ducks/Gaps/types';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import Helpers from 'utils/Helpers';
import * as Styled from './AuditDragAndDrop.styled';

const { useToken } = theme;

type TMockData = {
  [key: string]: { id: string; text: string }[];
};

interface ISepRegReqItem {
  key: number;
  id: number;
  text: string;
}

const AuditDragAndDrop = () => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const i18nInitialization = i18n();

  const { lang, assignedEls, assignedAuditFindingsUpdated, saveClicked } =
    useSelector((state: RootState) => ({
      lang: state.global.lang,
      assignedEls: state.gaps.assignedEls,
      assignedAuditFindingsUpdated: state.gaps.assignedAuditFindingsUpdated,
      saveClicked: state.complianceEst.saveClicked
    }));

  const [mockData, setMockData] = useState<TMockData>({
    sepRegReq: []
  });
  const [activeBox, setActiveBox] = useState<null | string>(null);
  const [newArray, setNewArray] = useState<{ id: string; text: string }[]>([]);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const getAudits = async () => {
    gapsOp
      .fetchAudits(lang)
      .then((data) => {
        const unassignedAudits = data?.map((audit: IUnassignedAudit) => {
          return [
            {
              key: audit.afg_id,
              id: audit.afg_id,
              text: audit.afg_desc_en
            }
          ];
        });

        if (unassignedAudits) {
          setMockData({
            sepRegReq: unassignedAudits.flat()?.map((item: ISepRegReqItem) => ({
              ...item,
              key: item.id.toString(),
              id: item.id.toString(),
              text: item.text
            }))
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleMoveToAlignedAuditFindings = async () => {
    let assignedFindings: IAssignedEls[] = assignedEls
      ?.map((item: IAssignedEvidence[]) => {
        let result: IAssignedEls[] = [];
        let addedTxts: string[] = [];
        let txtIdMap: Map<string, number> = new Map();

        mockData.sepRegReq.forEach((el) => {
          if (
            item[0].txt === el.text &&
            addedTxts.indexOf(item[0].txt) === -1
          ) {
            let id = txtIdMap.get(item[0].txt);

            if (id === undefined) {
              txtIdMap.set(item[0].txt, item[0].id);
              id = item[0].id;
            }

            result.push({
              afg_id: el.id,
              bru_id: id
            });

            addedTxts.push(item[0].txt);
          }
        });
        return result;
      })
      .flat();

    if (assignedFindings.length > 0) {
      const uniqueAfgIds: Record<string, number> = {};
      assignedFindings.forEach((item, index) => {
        if (!uniqueAfgIds[item.afg_id]) {
          uniqueAfgIds[item.afg_id] = index;
        } else {
          uniqueAfgIds[item.afg_id] = index;
        }
      });

      try {
        await gapsOp.fetchAuditsSave(assignedFindings);
        dispatch(gapsOp.setAssignedAuditFindingsUpdated(true));
        await getAudits();
      } catch (error) {
        console.error('Failed to send assigned findings:', error);
      }
    }

    dispatch(gapsOp.setClickedHandleMoveButton(true));
  };

  useEffect(() => {
    getAudits();
    dispatch(gapsOp.setAssignedAuditFindingsUpdated(false));
  }, []);

  useEffect(() => {
    if (assignedAuditFindingsUpdated) {
      gapsOp.fetchAuditFindingsMapping(lang).then(() => getAudits());
    }
  }, [assignedAuditFindingsUpdated]);

  useEffect(() => {
    if (assignedEls.length && saveClicked) {
      handleMoveToAlignedAuditFindings();
      dispatch(complianceEstOp.setSaveClicked(false));
      dispatch(gapsOp.setAssignedEls([]));
    }
  }, [saveClicked]);

  const moveBetweenContainers = (
    colData: TMockData,
    activeContainer: string,
    activeIndex: number,
    overContainer: string,
    overIndex: number,
    item: { id: string; text: string }
  ) => ({
    ...colData,
    [activeContainer]: Helpers.removeAtIndex(
      colData[activeContainer],
      activeIndex
    ),
    [overContainer]: Helpers.insertAtIndex(
      colData[overContainer],
      overIndex,
      item
    )
  });

  const handleDragStart = (event: DragStartEvent) => {
    if (event.active.data.current) {
      setActiveBox(
        mockData[event.active.data.current.keyName][
          event.active.data.current.index
        ].text
      );
      const selectedElements = [];
      selectedElements.push(
        mockData[event.active.data.current.keyName][
          event.active.data.current.index
        ]
      );
    } else {
      setActiveBox(null);
    }
  };

  const handleDragCancel = () => setActiveBox(null);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.data.current && over.data.current) {
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current.sortable.containerId;
      const overIndex =
        over.id in mockData
          ? mockData[overContainer].length + 1
          : over.data.current.sortable.index;
      const activeIndex = active.data.current.index;
      let newItems;
      if (activeContainer === overContainer) {
        newItems = {
          ...mockData,
          [overContainer]: arrayMove(
            mockData[overContainer],
            activeIndex,
            overIndex
          )
        };
      } else {
        const itemToMove = mockData[activeContainer][activeIndex];
        newItems = moveBetweenContainers(
          mockData,
          activeContainer,
          activeIndex,
          overContainer,
          overIndex,
          itemToMove
        );
        setNewArray([...newArray, itemToMove]);
        setMockData({
          ...newItems,
          [activeContainer]: Helpers.removeAtIndex(
            mockData[activeContainer],
            activeIndex
          )
        });
      }
      setMockData(newItems);
      setActiveBox(null);
    }
  };

  const renderCols = () => {
    const colNum = Object.keys(mockData).length;
    return Object.keys(mockData)?.map((key: string, index: number) => (
      <Droppable
        colNum={colNum}
        keyName={key}
        arrayData={mockData.sepRegReq}
        key={index}
      />
    ));
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
    >
      <Row className='justify-content-end m-3'>
        <Styled.StyledButton
          type='primary'
          token={token}
          onClick={handleMoveToAlignedAuditFindings}
        >
          {i18nInitialization.t('MOVE')}
        </Styled.StyledButton>
      </Row>
      <Styled.StyledRow className='drag-overlay'>
        {renderCols()}
      </Styled.StyledRow>
      <DragOverlay>
        {activeBox ? (
          <DraggableBox isOverlay={true}>
            <DragTextBox>{activeBox}</DragTextBox>
          </DraggableBox>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default AuditDragAndDrop;
