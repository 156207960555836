import { notification } from 'antd';
import { Dispatch } from '@reduxjs/toolkit';
import ApiService from 'utils/ApiCalls';
import evidencesDefSlice from './evidencesSlice';
import { IEvidenceResult, IEvidencesToShow, IEvidenceToShow } from './types';
import { LanguageList } from '../globalTypes';

const {
  set_loading,
  set_evidences,
  set_evidences_to_show,
  delete_evidence,
  add_new_evidence,
  edit_evidence,
  reset_evidences_to_save
} = evidencesDefSlice.actions;

const fetchEvidences = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getEvidences(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const setEvidences =
  (evidences: IEvidenceResult[]) => async (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_evidences(evidences));
    } catch (error) {
      console.error(error);
    }
  };

const setEvidencesToShow =
  (evidences: {
    [key: number]: { [key: number | string]: IEvidencesToShow };
  }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_evidences_to_show(evidences));
    } catch (error) {
      console.error(error);
    }
  };

const editEvidenceValue =
  (
    evidenceId: string | number,
    evidenceData: IEvidenceToShow,
    key: string,
    value: string
  ) =>
  (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(edit_evidence({ evidenceId, evidenceData, key, value }));
    } catch (error) {
      console.error(error);
    }
  };

const deleteEvidence = async (evdId: number | string, lang: LanguageList) => {
  try {
    const response = await ApiService.removeEvidenceDef(evdId);

    if (response.status === 200) {
      fetchEvidences(lang).then((data) => {
        setEvidences(data?.flat());
      });

      notification.success({
        message: response.status,
        description: 'Evidence successfully deleted'
      });
    }
  } catch (error: any) {
    notification.error({
      message: error.message,
      description: 'Failed to delete Evidence'
    });

    console.error(error);
  }
};

const handleDeleteClick =
  (clnReqId: number, evId: number | string) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(delete_evidence({ clnReqId, evId }));
    } catch (error) {
      console.error(error);
    }
  };

const approveEvidence = async (evdId: string | number) => {
  try {
    await ApiService.approveEvidence(evdId);
  } catch (error) {
    console.error(error);
  }
};

const rejectEvidence = async (evdId: string | number) => {
  try {
    await ApiService.rejectEvidence(evdId);
  } catch (error) {
    console.error(error);
  }
};

const fetchEvidenceDefApproversList = async () => {
  try {
    const response = await ApiService.getEvidenceDefApproverList();

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const addNewEvidence =
  (cln_req_id: number, sol_id: number) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(add_new_evidence({ cln_req_id, sol_id }));
    } catch (error) {
      console.error(error);
    }
  };

const saveEvidences = async (
  evidencesToSave: IEvidenceToShow[],
  lang: LanguageList
) => {
  try {
    const response = await ApiService.saveEvidencesDef(evidencesToSave, lang);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const resetEvidencesToSave = () => (dispatch: Dispatch) => {
  try {
    dispatch(reset_evidences_to_save());
  } catch (error) {
    console.error(error);
  }
};

export default {
  fetchEvidences,
  setEvidences,
  deleteEvidence,
  setEvidencesToShow,
  handleDeleteClick,
  fetchEvidenceDefApproversList,
  addNewEvidence,
  editEvidenceValue,
  approveEvidence,
  rejectEvidence,
  saveEvidences,
  resetEvidencesToSave
};
