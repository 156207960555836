import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, CommentOutlined } from '@ant-design/icons';
import { Popover, theme, message, Popconfirm } from 'antd';
import { CommentItem, CommentTable } from 'store/ducks/ComplienceEst/types';
import { RootState } from 'store/configureStore';
import { evidencesOp } from 'store/ducks/Evidences';
import { IEvidencesToShow } from 'store/ducks/Evidences/types';
import CustomTextarea from '../CustomTextarea';
import CustomIconButton from '../CustomIconButton';
import CommentSection from '../CommentSection';
import * as Styled from './TextAreaBoxEvidences.styled';

interface ITextAreaBoxProps {
  evidences: IEvidencesToShow;
  evidenceId?: number;
  onDelete?: (id: string | number) => void;
}

interface IPopoverState {
  [id: number | string]: boolean;
}

const { useToken } = theme;

const cancel = () => {
  message.error('Evidence was not deleted');
};

export const TextAreaBoxEvidences: React.FC<ITextAreaBoxProps> = ({
  evidences,
  evidenceId
}) => {
  const { token } = useToken();
  const dispatch = useDispatch();

  const { commentsData, lang } = useSelector((state: RootState) => ({
    commentsData: state.complianceEst.commentsData,
    lang: state.global.lang
  }));
  const { EVIDENCES } = evidences.SOLUTIONS;
  const { evd_id, evd_desc } = EVIDENCES;

  const [popoverState, setPopoverState] = useState<IPopoverState>({});

  const handleDeleteClick = () => {
    if (typeof evidences.SOLUTIONS.EVIDENCES.evd_id === 'number') {
      evidencesOp.deleteEvidence(evidences.SOLUTIONS.EVIDENCES.evd_id, lang);
    } else {
      dispatch(
        evidencesOp.handleDeleteClick(
          evidences.id,
          evidences.SOLUTIONS.EVIDENCES.evd_id
        )
      );
    }
  };

  const hide = (id: number | string) => {
    if (!id) return;

    setPopoverState((prevState) => ({
      ...prevState,
      [id]: false
    }));
  };

  const drawComments = () => {
    const filteredComments = commentsData.length
      ? commentsData.filter(
          (commentsDataItem: CommentItem) =>
            commentsDataItem.res_table_pk_ref === evidenceId
        )
      : [];

    return (
      <>
        {evidenceId && (
          <>
            <CommentSection
              reqId={evidenceId}
              data={filteredComments}
              tableRef={CommentTable.EVIDENCES}
            />
            <a onClick={() => hide(evidenceId)}>Close</a>
          </>
        )}
      </>
    );
  };

  const handleOpenChange = (id: number | string) => {
    if (!id) return;

    setPopoverState((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  return (
    <Styled.TextAreaElementsContainer>
      <Styled.TextAreaElementsContainer>
        <CustomTextarea
          key={evidenceId}
          placeholder='Evidence Definition'
          defaultValue={evd_desc}
          onChange={(e) =>
            dispatch(
              evidencesOp.editEvidenceValue(
                evd_id,
                EVIDENCES,
                'evd_desc',
                e.target.value
              )
            )
          }
        />
        <Styled.StyledButtonSection token={token}>
          {evidenceId && (
            <Popover
              placement='leftBottom'
              trigger='click'
              content={drawComments}
              title='Comments'
              open={!!(evidenceId && popoverState[evidenceId])}
              onOpenChange={() => handleOpenChange(evidenceId)}
            >
              <CustomIconButton
                type='primary'
                shape='circle'
                icon={<CommentOutlined />}
                size='small'
                cursor='pointer'
              />
            </Popover>
          )}

          <Popconfirm
            title='Delete the evidence'
            description='Are you sure to delete this evidence?'
            onConfirm={handleDeleteClick}
            onCancel={cancel}
            okText='Yes'
            cancelText='No'
          >
            <Styled.StyledCustomIconButton
              token={token}
              type='primary'
              shape='circle'
              icon={<DeleteOutlined />}
              cursor='pointer'
              size='small'
            />
          </Popconfirm>
        </Styled.StyledButtonSection>
      </Styled.TextAreaElementsContainer>
    </Styled.TextAreaElementsContainer>
  );
};
