import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  PlusOutlined,
  DeleteOutlined,
  CommentOutlined
} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { Popover, Row, theme } from 'antd';
import { IGaps, IGapsInfo } from 'store/ducks/Gaps/types';
import { gapsOp } from 'store/ducks/Gaps';
import { CommentItem, CommentTable } from 'store/ducks/ComplienceEst/types';
import { RootState } from 'store/configureStore';
import Helpers from 'utils/Helpers';
import CustomTextarea from '../CustomTextarea';
import CustomIconButton from '../CustomIconButton';
import CommentSection from '../CommentSection';
import CustomCheckbox from '../CustomCheckbox';
import * as Styled from './TextAreaBoxGap.styled';

interface ITextAreaBoxProps {
  placeholder: string;
  data: IGaps;
  withCheckbox?: boolean;
  refId: number;
  onChange: (updatedData: IGaps) => void;
  onDelete: (id: string | number) => void;
}

interface IPopoverState {
  [id: number | string]: boolean;
}

const { useToken } = theme;

export const TextAreaBoxGap: React.FC<ITextAreaBoxProps> = ({
  placeholder,
  data,
  withCheckbox = false,
  refId,
  onChange,
  onDelete
}) => {
  const { token } = useToken();

  const { commentsData } = useSelector(
    (state: RootState) => state.complianceEst
  );

  const [dataObj, setDataObj] = useState<IGaps>({});
  const [checkboxDisabled, setCheckboxDisabled] = useState(
    data && !Helpers.isObjEmpty(data)
  );
  const [popoverState, setPopoverState] = useState<IPopoverState>({});

  useEffect(() => {
    let commentsState: IPopoverState = {};

    if (!Helpers.isObjEmpty(dataObj)) {
      Object.values(dataObj).forEach((item: IGapsInfo) => {
        if (item.gap_id) {
          commentsState[item.gap_id] = false;
        }
      });
    }

    setPopoverState(commentsState);
  }, [dataObj]);

  useEffect(() => {
    if (data && !Helpers.isObjEmpty(data)) {
      setDataObj(data);
      setCheckboxDisabled(true);
    }
  }, [data]);

  const handleAddEmptyGap = (id: string) => {
    setDataObj((prevState: IGaps) => ({
      ...prevState,
      [id]: { gap_desc: '', gap_bru_ref: refId }
    }));
  };

  const handlePlusClick = () => {
    const newId = uuidv4();

    handleAddEmptyGap(newId);
  };

  const handleDeleteClick = (key: number) => {
    setDataObj((prevState: IGaps) => {
      const copy = { ...prevState };
      delete copy[key];

      return copy;
    });

    if (data[key]) {
      gapsOp.deleteGap(key);
    }

    onDelete(key);
  };

  const handleGapChange = (value: string, key: number) => {
    setDataObj((prevState: IGaps) => ({
      ...prevState,
      [key]: {
        gap_id: prevState[key].gap_id,
        gap_desc: value,
        gap_bru_ref: refId
      }
    }));

    onChange({
      [key]: { ...dataObj[key], gap_desc: value, gap_bru_ref: refId }
    });
  };

  const handleCheckboxChange = () => {
    if (Helpers.isObjEmpty(dataObj)) {
      handlePlusClick();
    }
  };

  const hide = (id: number | string) => {
    if (!id) return;

    setPopoverState((prevState) => ({
      ...prevState,
      [id]: false
    }));
  };

  const drawComments = (id: number) => {
    const filteredComments = commentsData.length
      ? commentsData.filter(
          (commentsDataItem: CommentItem) =>
            commentsDataItem.res_table_pk_ref === id
        )
      : [];

    return (
      <>
        <CommentSection
          reqId={id}
          data={filteredComments}
          tableRef={CommentTable.GAPS}
        />
        <a onClick={() => hide(id)}>Close</a>
      </>
    );
  };

  const handleOpenChange = (id: number | string) => {
    if (!id) return;

    setPopoverState((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  useEffect(() => {
    if (dataObj && !Helpers.isObjEmpty(dataObj)) {
      setCheckboxDisabled(true);
    } else {
      setCheckboxDisabled(false);
    }
  }, [dataObj]);

  return (
    <Styled.StyledContainer withCheckbox={withCheckbox}>
      <Row>
        <Styled.CheckboxContainer>
          {withCheckbox && (
            <CustomCheckbox
              disabled={checkboxDisabled}
              checked={checkboxDisabled}
              onChange={handleCheckboxChange}
            />
          )}
        </Styled.CheckboxContainer>
        <Styled.TextAreaContainer withCheckbox={withCheckbox}>
          {dataObj &&
            Object.keys(dataObj).map((key: any, index: number) => (
              <Styled.TextAreaElementsContainer
                key={dataObj[key].gap_id || index}
              >
                <CustomTextarea
                  key={dataObj[key].gap_id}
                  placeholder={placeholder}
                  defaultValue={dataObj[key].gap_desc}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleGapChange(e.target.value, key)
                  }
                />
                <Styled.StyledButtonSection token={token}>
                  <Popover
                    placement='leftBottom'
                    trigger='click'
                    content={drawComments(Number(key))}
                    title='Comments'
                    open={!!(key && popoverState[key])}
                    onOpenChange={() => handleOpenChange(key)}
                  >
                    <CustomIconButton
                      type='primary'
                      shape='circle'
                      icon={<CommentOutlined />}
                      size='small'
                      cursor='pointer'
                    />
                  </Popover>
                  <Styled.StyledCustomIconButton
                    token={token}
                    type='primary'
                    shape='circle'
                    icon={<DeleteOutlined />}
                    cursor='pointer'
                    size='small'
                    onClick={() => handleDeleteClick(key)}
                  />
                </Styled.StyledButtonSection>
              </Styled.TextAreaElementsContainer>
            ))}
          <Styled.ButtonContainer>
            <CustomIconButton
              type='primary'
              shape='circle'
              icon={<PlusOutlined />}
              size='middle'
              onClick={handlePlusClick}
            />
          </Styled.ButtonContainer>
        </Styled.TextAreaContainer>
      </Row>
      <Styled.FlexContainer />
    </Styled.StyledContainer>
  );
};
