import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IAdditionalInfoData,
  IAuditData,
  IEvidenceData,
  IGaps,
  IGapsState,
  IGapsTableData,
  ISaveInfoData,
  ITableFilters
} from './types';
import initialGapsState from './initialState';

const gapsSlice = createSlice({
  name: 'gaps',
  initialState: initialGapsState(),
  reducers: {
    set_gaps_description(
      state: IGapsState,
      action: PayloadAction<IGapsTableData[]>
    ) {
      state.gapsDescription = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_additional_info(
      state: IGapsState,
      action: PayloadAction<IAdditionalInfoData[]>
    ) {
      state.additionalInfo = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_gaps_table_data(
      state: IGapsState,
      action: PayloadAction<IGapsTableData[]>
    ) {
      state.gapsTableData = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_auditFindings_mapping(
      state: IGapsState,
      action: PayloadAction<IAuditData[]>
    ) {
      state.auditFindingMapping = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_evidence_mapping(
      state: IGapsState,
      action: PayloadAction<IEvidenceData[]>
    ) {
      state.evidenceMapping = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_assigned_els(state: IGapsState, action: PayloadAction<any>) {
      state.assignedEls =
        (Array.isArray(action.payload) && action.payload.length === 0) ||
        !Object.keys(action.payload).length
          ? []
          : [...state.assignedEls, action.payload];
      state.error = false;
      state.loading = false;
    },
    set_assigned_evidences(state: IGapsState, action: PayloadAction<any>) {
      state.assignedEvidences =
        Array.isArray(action.payload) && action.payload.length === 0
          ? []
          : [...state.assignedEvidences, action.payload];
      state.error = false;
      state.loading = false;
    },
    set_table_filters(state: IGapsState, action: PayloadAction<ITableFilters>) {
      state.tableFilters = { ...state.tableFilters, ...action.payload };
      state.error = false;
      state.loading = false;
    },
    set_assigned_audit_findings_updated(
      state: IGapsState,
      action: PayloadAction<boolean>
    ) {
      state.assignedAuditFindingsUpdated = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_clicked_handle_move_button(
      state: IGapsState,
      action: PayloadAction<boolean>
    ) {
      state.clickedHandleMoveButton = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_clicked_handle_evidence_move_button(
      state: IGapsState,
      action: PayloadAction<boolean>
    ) {
      state.clickedHandleEvidenceMoveButton = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_assigned_evidences_updated(
      state: IGapsState,
      action: PayloadAction<boolean>
    ) {
      state.assignedEvidencesUpdated = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_loading(state: IGapsState) {
      state.loading = true;
    },
    set_error(state: IGapsState, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    set_selected_evidence_module(
      state: IGapsState,
      action: PayloadAction<boolean>
    ) {
      state.selectedEvidenceModule = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_info_save(state: IGapsState, action: PayloadAction<ISaveInfoData>) {
      state.infoSave = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_gaps_save_data(state: IGapsState, action: PayloadAction<IGaps>) {
      state.gapsSaveData = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_gaps_updated(state: IGapsState, action: PayloadAction<boolean>) {
      state.gapsUpdated = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_info_updated(state: IGapsState, action: PayloadAction<boolean>) {
      state.infoUpdated = action.payload;
      state.error = false;
      state.loading = false;
    }
  }
});
export default gapsSlice;
