import Images from 'constants/images';
import i18n from 'components/translation/i18n';

const workingAreas = () => {
  const i18nInitialization = i18n();
  const areas = [
    {
      area: i18nInitialization.t('DATA_MANAGEMENT'),
      infoText: i18nInitialization.t('DATA_MANAGEMENT_TEXT'),
      imgSrc: Images.dataManagement,
      href: '/dataManagement/auditFindings'
    },
    {
      area: i18nInitialization.t('COMP_EST'),
      infoText: i18nInitialization.t('COMP_EST_TEXT'),
      imgSrc: Images.complianceEstabCardIcon,
      href: '/complianceEst/clientReq'
    },
    // {
    //   area: Areas.COCKPIT,
    //   infoText: 'Some text about Cockpit area..',
    //   imgSrc: Images.cockpit,
    //   href: ''
    // },
    // {
    //   area: Areas.RISK_MANAGEMENT,
    //   infoText: 'Some text about Risk and Control Management area...',
    //   imgSrc: Images.internalControls,
    //   href: ''
    // },
    {
      area: i18nInitialization.t('REPORTING'),
      infoText: i18nInitialization.t('REPORTING_TEXT'),
      imgSrc: Images.reporting,
      href: '/reporting'
    },
    {
      area: i18nInitialization.t('CONTROL_RISKS'),
      infoText: i18nInitialization.t('CONTROL_RISKS_TEXT'),
      imgSrc: Images.internalControls,
      href: '/internal_controls'
    }
  ];
  return areas;
};

export default workingAreas;
