import { Select } from 'antd';
import styled from 'styled-components';

export const StyledDiv = styled('div')`
  display: flex;
  margin-top: 1rem;
  padding: 10px 0;
`;

export const StyledSpan = styled('span')`
  width: 165px;
`;

export const StyledSelect = styled(Select)`
  width: 100px;
  margin-right: 10px;
`;
