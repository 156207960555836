import React from 'react';
import { useSelector } from 'react-redux';
import { theme } from 'antd';
import CustomTable from '../CustomTable';
import { RootState } from 'store/configureStore';
import * as Styled from './NestedTable.styled';

const { useToken } = theme;

interface INestedTableProps {
  [rest: string]: any;
}

const NestedTable: React.FC<INestedTableProps> = ({ ...rest }) => {
  const { token } = useToken();

  const { drawTable } = useSelector((state: RootState) => state.complianceEst);

  return (
    <Styled.TableWrapper token={token} className='nested-table-wrapper'>
      <CustomTable
        drawTable={drawTable}
        itemsCount={0}
        pagination={false}
        {...rest}
      />
    </Styled.TableWrapper>
  );
};

export default NestedTable;
