import { ReactNode, FC } from 'react';
import type { Transform } from '@dnd-kit/utilities';
import * as Styled from './DraggableBox.styled';

interface IDraggableBox {
  isDragging?: boolean;
  isOverlay?: boolean;
  transition?: string;
  transform?: Transform | null;
  children?: ReactNode;
}

const DraggableBox: FC<IDraggableBox> = ({
  isDragging,
  isOverlay,
  transition,
  transform,
  children
}) => (
  <Styled.StyledDraggableContainer
    isDragging={isDragging}
    isOverlay={isOverlay}
    transition={transition}
    transform={transform}
  >
    {children}
  </Styled.StyledDraggableContainer>
);

export default DraggableBox;
