import React, { useState, useEffect } from 'react';
import { notification, theme } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import ComplianceEstLayout from 'components/Layouts/ComplianceEstLayout';
import { RootState } from 'store/configureStore';
import TextBox from 'components/TextBox';
import CustomTable from 'components/Tables/CustomTable';
import CustomSelect from 'components/CustomSelect';
import NestedTable from 'components/Tables/NestedTable';
import CustomFileUpload from 'components/CustomFileUpload';
import CustomIconButton from 'components/CustomIconButton';
import TextAreaBoxEvidences from 'components/TextAreaBoxEvidences';
import Helpers from 'utils/Helpers';
import { evidencesOp } from 'store/ducks/Evidences';
import { ISelectionOption, IUserInfo, YesNo } from 'store/ducks/globalTypes';
import { IEvidencesToShow } from 'store/ducks/Evidences/types';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import { EvidenceDefinitionColumns } from 'constants/tableColumns';
import i18n from 'components/translation/i18n';
import * as Styled from './EvidenceDefinition.styled';

const { useToken } = theme;

const EvidenceDefinition = () => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const i18nInitialization = i18n();
  const userToken = Helpers.getToken();
  const userInfo: IUserInfo = Helpers.getUserInfo().user;

  const [approversList, setApproversList] = useState<ISelectionOption[]>([]);
  const [permitted, setPermitted] = useState<boolean>(false);
  const evidenceDefinitionColumns = EvidenceDefinitionColumns();

  const {
    lang,
    evidences,
    drawTable,
    loading,
    saveClicked,
    evidencesToShow,
    evidencesToSave
  } = useSelector((state: RootState) => ({
    lang: state.global.lang,
    drawTable: state.complianceEst.drawTable,
    saveClicked: state.complianceEst.saveClicked,
    loading: state.evidences.loading,
    evidences: state.evidences.evidences,
    evidencesToShow: state.evidences.evidencesToShow,
    evidencesToSave: state.evidences.evidencesToSave
  }));

  const handleStatusChange = (value: YesNo, evId: string | number) => {
    switch (value) {
      case YesNo.YES:
        evidencesOp.approveEvidence(evId);
        break;
      case YesNo.NO:
        evidencesOp.rejectEvidence(evId);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    evidencesOp.fetchEvidenceDefApproversList().then((data) => {
      setApproversList(Helpers.convertToSelectOptions(data?.approvers));
    });
  }, []);

  useEffect(() => {
    if (userInfo.entitlements.length > 0) {
      setPermitted(userInfo.entitlements.includes('Second Approval'));
    }
  }, [userToken]);

  useEffect(() => {
    dispatch(
      complianceEstOp.setSaveDisabled(Helpers.isObjEmpty(evidencesToSave))
    );
  }, [evidencesToSave]);

  useEffect(() => {
    if (saveClicked) {
      evidencesOp
        .saveEvidences(Object.values(evidencesToSave), lang)
        .then((response) => {
          dispatch(complianceEstOp.setSaveClicked(false));

          if (response) {
            dispatch(complianceEstOp.setSaveDisabled(true));
            dispatch(evidencesOp.resetEvidencesToSave());
            dispatch(evidencesOp.fetchEvidences(lang));
          }
        });
    }
  }, [saveClicked]);

  useEffect(() => {
    if (evidences?.length > 0) {
      const transformedObject: {
        [key: number]: { [key: number | string]: IEvidencesToShow };
      } = {};

      evidences.forEach((item) => {
        const solutionKeys = Object.keys(item.SOLUTIONS);
        const nestedObj: any = {};

        if (solutionKeys.length === 0) {
          const transformedItem = {
            ...item,
            SOLUTIONS: {}
          };

          nestedObj['emptySolutions'] = transformedItem;
        } else {
          solutionKeys.forEach((key) => {
            const solution = item.SOLUTIONS[key];

            if (!Helpers.isObjEmpty(solution.EVIDENCES)) {
              const evidenceKeys = Object.keys(solution.EVIDENCES);

              evidenceKeys.forEach((evidenceKey) => {
                const evidence = solution.EVIDENCES[evidenceKey];

                const transformedObj = {
                  ...item,
                  SOLUTIONS: {
                    ...solution,
                    EVIDENCES: { ...evidence }
                  }
                };

                nestedObj[evidence.evd_id] = transformedObj;
              });
            } else {
              const transformedObj = {
                ...item,
                SOLUTIONS: { ...solution }
              };

              nestedObj[`emptyEvidence${solution.sol_id}`] = transformedObj;
            }
          });
        }

        transformedObject[item.id] = nestedObj;
      });

      dispatch(evidencesOp.setEvidencesToShow(transformedObject));
    }
  }, [evidences]);

  const expandedRow = (record: { [key: number]: IEvidencesToShow }) => {
    const handlePlusClick = (sol_id?: number) => {
      if (sol_id) {
        dispatch(
          evidencesOp.addNewEvidence(Object.values(record)[0].id, sol_id)
        );
      } else {
        notification.error({
          message: 'Failed to add an evidence',
          description:
            'You can not add an evidence because it has no Solution related to it'
        });
      }
    };

    const isDuplicate = (index: number) =>
      Object.values(record)[index]?.SOLUTIONS?.sol_id ===
      Object.values(record)[index - 1]?.SOLUTIONS?.sol_id;

    const nestedTableColumns = [
      {
        title: 'CLN Req',
        dataIndex: [],
        key: 'bru_desc',
        width: 500,
        render: (
          dataObj: IEvidencesToShow,
          rec: IEvidencesToShow,
          index: number
        ) => {
          const rowspan = index === 0 ? Object.values(record).length : 0;

          return {
            children: (
              <TextBox width={500} level={dataObj.level}>
                {dataObj.bru_desc}
              </TextBox>
            ),
            props: {
              rowSpan: rowspan
            }
          };
        }
      },
      {
        title: 'Solution Definition',
        dataIndex: [],
        key: 'sol_desc',
        width: 500,
        render: (
          dataObj: IEvidencesToShow,
          rec: IEvidencesToShow,
          index: number
        ) => {
          const { SOLUTIONS } = dataObj;

          return (
            <>
              {!Helpers.isObjEmpty(SOLUTIONS) &&
              (index === 0 || !isDuplicate(index)) ? (
                <TextBox width={500}>{SOLUTIONS.sol_desc}</TextBox>
              ) : (
                <Styled.StyledTextBox width={500} />
              )}
            </>
          );
        }
      },
      {
        title: 'Solution Type',
        dataIndex: [],
        key: 'sol_type',
        width: 100,
        render: (
          dataObj: IEvidencesToShow,
          rec: IEvidencesToShow,
          index: number
        ) => {
          const { SOLUTIONS } = dataObj;

          return (
            <>
              {!Helpers.isObjEmpty(SOLUTIONS) &&
              (index === 0 || !isDuplicate(index)) ? (
                <CustomSelect
                  width={100}
                  defaultValue={SOLUTIONS.sol_type}
                  disabled
                />
              ) : (
                <Styled.StyledTextBox width={100} />
              )}
            </>
          );
        }
      },
      {
        title: 'Evidence ID',
        dataIndex: [],
        key: 'evidenceId',
        width: 100,
        render: (dataObj: IEvidencesToShow) => {
          const { SOLUTIONS } = dataObj;
          const { EVIDENCES } = SOLUTIONS;

          return (
            <>
              {!Helpers.isObjEmpty(SOLUTIONS) &&
              !Helpers.isObjEmpty(SOLUTIONS.EVIDENCES) &&
              typeof EVIDENCES.evd_id === 'number' ? (
                <TextBox width={100}>{EVIDENCES.evd_id}</TextBox>
              ) : (
                <Styled.StyledTextBox width={100} />
              )}
            </>
          );
        }
      },
      {
        title: 'Evidence definition',
        dataIndex: [],
        key: 'evidenceDef',
        width: 376,
        render: (
          dataObj: IEvidencesToShow,
          rec: IEvidencesToShow,
          index: number
        ) => {
          const dataArr: IEvidencesToShow[] = Object.values(record);
          const { SOLUTIONS } = dataObj;
          const { EVIDENCES, sol_id } = SOLUTIONS;

          const isFinalEvidence =
            dataArr[index]?.SOLUTIONS?.sol_id !==
            dataArr[index + 1]?.SOLUTIONS?.sol_id;

          return (
            <>
              {!Helpers.isObjEmpty(SOLUTIONS) &&
              !Helpers.isObjEmpty(SOLUTIONS.EVIDENCES) ? (
                <>
                  <TextAreaBoxEvidences
                    evidences={dataObj}
                    evidenceId={
                      typeof EVIDENCES.evd_id === 'number'
                        ? EVIDENCES.evd_id
                        : undefined
                    }
                  />
                  {isFinalEvidence && (
                    <Styled.ButtonContainer>
                      <CustomIconButton
                        type='primary'
                        shape='circle'
                        icon={<PlusOutlined />}
                        size='middle'
                        onClick={() => handlePlusClick(sol_id)}
                      />
                    </Styled.ButtonContainer>
                  )}
                </>
              ) : (
                <Styled.ButtonContainerEmpty>
                  <CustomIconButton
                    type='primary'
                    shape='circle'
                    icon={<PlusOutlined />}
                    size='middle'
                    onClick={() => handlePlusClick(sol_id)}
                  />
                </Styled.ButtonContainerEmpty>
              )}
            </>
          );
        }
      },
      {
        title: 'Evidence file',
        dataIndex: [],
        key: 'evidenceFile',
        width: 350,
        render: (dataObj: IEvidencesToShow) => {
          const { SOLUTIONS } = dataObj;
          const { EVIDENCES } = SOLUTIONS;

          return (
            <>
              {!Helpers.isObjEmpty(SOLUTIONS) &&
              !Helpers.isObjEmpty(EVIDENCES) ? (
                <CustomFileUpload
                  defaultFileList={
                    EVIDENCES.evd_file.length > 0 ? EVIDENCES.evd_file : []
                  }
                />
              ) : (
                <Styled.StyledTextBox width={350} />
              )}
            </>
          );
        }
      },
      // {
      //   title: 'Evidence file',
      //   dataIndex: 'EVIDENCES',
      //   key: 'EVIDENCES',
      //   width: 376,
      //   render: (EVIDENCES: any) => {
      //     return Object.keys(EVIDENCES).length > 0 ? (
      //       <>
      //         {Object.values(EVIDENCES).map((ev: any) => (
      //           <>
      //             {ev.evd_file?.map((url: string) => {
      //               const shortenedUrlName = url.substring(
      //                 url.lastIndexOf('/') + 1
      //               );
      //               return (
      //                 <div key={url} style={{ paddingTop: 0 }}>
      //                   <Link to={url} target='_blank'>
      //                     <TextBox
      //                       width={376}
      //                       style={{
      //                         backgroundColor:
      //                           EVIDENCES && Object.keys(EVIDENCES).length > 0
      //                             ? token['colorBgSpotlight']
      //                             : 'transparent'
      //                       }}
      //                     >
      //                       {shortenedUrlName}
      //                     </TextBox>
      //                   </Link>
      //                 </div>
      //               );
      //             })}
      //             {/* <CustomFileUpload /> */}
      //           </>
      //         ))}
      //       </>
      //     ) : (
      //       <TextBox width={376} style={{ backgroundColor: 'transparent' }} />
      //     );
      //   }
      // },
      // {
      //   title: 'Evidence file',
      //   dataIndex: 'EVIDENCES',
      //   key: 'EVIDENCES',
      //   width: 376,
      //   render: (EVIDENCES: any) => {
      //     if (Object.keys(EVIDENCES).length > 0) {
      //       return (
      //         <>
      //           {Object.values(EVIDENCES).map((ev: any) => (
      //             <>
      //               {/* {ev.evd_file?.map((url: string) => {
      //                 const shortenedUrlName = url.substring(
      //                   url.lastIndexOf('/') + 1
      //                 );
      //                 return (
      //                   <div key={url} style={{ paddingTop: 0 }}>
      //                     <Link to={url} target='_blank'>
      //                       <TextBox
      //                         width={376}
      //                         style={{
      //                           backgroundColor:
      //                             EVIDENCES && Object.keys(EVIDENCES).length > 0
      //                               ? token['colorBgSpotlight']
      //                               : 'transparent'
      //                         }}
      //                       >
      //                         {shortenedUrlName}
      //                       </TextBox>
      //                     </Link>
      //                   </div>
      //                 );
      //               })} */}
      //               {/* <div
      //                 style={{
      //                   backgroundColor:
      //                     EVIDENCES && Object.keys(EVIDENCES).length > 0
      //                       ? token['colorBgSpotlight']
      //                       : 'transparent'
      //                 }}
      //               >
      //                 <CustomFileUpload />
      //               </div> */}
      //             </>
      //           ))}
      //         </>
      //       );
      //     } else {
      //       return <Styled.StyledTextBox width={100} />;
      //     }
      //   }
      // },
      {
        title: 'Approver',
        dataIndex: [],
        key: 'approver',
        width: 150,
        render: (dataObj: IEvidencesToShow) => {
          const { SOLUTIONS } = dataObj;
          const { EVIDENCES } = SOLUTIONS;

          return (
            <>
              {!Helpers.isObjEmpty(SOLUTIONS) &&
              !Helpers.isObjEmpty(SOLUTIONS.EVIDENCES) ? (
                <CustomSelect
                  size='lg'
                  options={approversList}
                  defaultValue={EVIDENCES.evd_approver_ref}
                  onChange={(value: string) =>
                    dispatch(
                      evidencesOp.editEvidenceValue(
                        EVIDENCES.evd_id,
                        SOLUTIONS.EVIDENCES,
                        'evd_approver_ref',
                        value
                      )
                    )
                  }
                />
              ) : (
                <Styled.StyledTextBox width={150} />
              )}
            </>
          );
        }
      },
      {
        title: 'Approval',
        dataIndex: [],
        key: 'approval',
        width: 150,
        render: (dataObj: IEvidencesToShow) => {
          const { SOLUTIONS } = dataObj;
          const { EVIDENCES } = SOLUTIONS;
          const isApprover = EVIDENCES?.evd_approver_ref === userInfo.email;

          return (
            <>
              {!Helpers.isObjEmpty(SOLUTIONS) &&
              !Helpers.isObjEmpty(SOLUTIONS.EVIDENCES) ? (
                <CustomSelect
                  size='lg'
                  options={[
                    { label: 'Approved', value: YesNo.YES },
                    { label: 'Not approved', value: YesNo.NO }
                  ]}
                  defaultValue={
                    EVIDENCES.evd_approved === 'N' ? 'Not approved' : 'Approved'
                  }
                  disabled={!permitted || !isApprover}
                  onChange={(value: YesNo) =>
                    handleStatusChange(value, EVIDENCES.evd_id)
                  }
                />
              ) : (
                <Styled.StyledTextBox width={150} />
              )}
            </>
          );
        }
      }
    ];

    return (
      <NestedTable
        key={Object.keys(record)[0]}
        className='nested-table'
        columns={nestedTableColumns}
        dataSource={Object.values(record)}
        pagination={false}
      />
    );
  };

  return (
    <ComplianceEstLayout>
      <Styled.ContentWrapper token={token}>
        {drawTable && evidences?.length > 0 ? (
          <Styled.StyledCustomTable
            drawTable
            token={token}
            columns={evidenceDefinitionColumns}
            dataSource={
              !Helpers.isObjEmpty(evidencesToShow)
                ? Object.values(evidencesToShow)
                : []
            }
            itemsCount={Object.values(evidencesToShow).length}
            loading={loading}
            expandable={{
              expandedRowKeys: evidences.map(
                (item: any, index: number) => item[index]
              ),
              expandedRowRender: (record: {
                [key: number]: IEvidencesToShow;
              }) => expandedRow(record)
            }}
            scroll={{ x: 1000 }}
          />
        ) : (
          <CustomTable
            drawTable
            itemsCount={0}
            columns={evidenceDefinitionColumns}
            dataSource={[]}
          />
        )}
      </Styled.ContentWrapper>
    </ComplianceEstLayout>
  );
};

export default EvidenceDefinition;
