import React, { useEffect, useState } from 'react';
import { theme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { PlusOutlined } from '@ant-design/icons';
import ComplianceEstLayout from 'components/Layouts/ComplianceEstLayout';
import CustomTable from 'components/Tables/CustomTable';
import { SolutionColumns } from 'constants/tableColumns';
import { RootState } from 'store/configureStore';
import { IAudits, IGaps, IGapsInfo } from 'store/ducks/Gaps/types';
import TextBox from 'components/TextBox';
import CustomSelect from 'components/CustomSelect';
import NestedTable from 'components/Tables/NestedTable';
import Helpers from 'utils/Helpers';
import {
  ISolution,
  ISolutions,
  ISolutionsResult,
  ISolutionsToShow
} from 'store/ducks/Solutions/types';
import CustomIconButton from 'components/CustomIconButton';
import { IUserInfo, YesNo } from 'store/ducks/globalTypes';
import { solutionsOp } from 'store/ducks/Solutions';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import TextAreaBoxSolutions from 'components/TextAreaBoxSolutions';
import * as Styled from './SolutionDefinition.styled';

const { useToken } = theme;

const TestTable = () => {
  const { token } = useToken();
  const dispatch = useDispatch();
  const userToken = Helpers.getToken();
  const userInfo: IUserInfo = Helpers.getUserInfo().user;
  const solutionColumns = SolutionColumns();
  const {
    lang,
    solutions,
    solutionsToShow,
    solutionsToSave,
    drawTable,
    saveClicked,
    loading
  } = useSelector((state: RootState) => ({
    lang: state.global.lang,
    drawTable: state.complianceEst.drawTable,
    solutions: state.solutions.solutions,
    solutionsToShow: state.solutions.solutionsToShow,
    solutionsToSave: state.solutions.solutionsToSave,
    saveClicked: state.complianceEst.saveClicked,
    loading: state.solutions.loading
  }));

  const [approversList, setApproversList] = useState<string[]>([]);
  const [solutionTypes, setSolutionTypes] = useState<string[]>([]);
  const [permitted, setPermitted] = useState<boolean>(false);

  const handleStatusChange = (value: YesNo, solId: string | number) => {
    switch (value) {
      case YesNo.YES:
        solutionsOp.approveSolution(solId);
        break;
      case YesNo.NO:
        solutionsOp.rejectSolution(solId);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    solutionsOp.fetchApproversList().then((data) => {
      setApproversList(data?.approver);
    });

    solutionsOp.fetchSolutionTypes(lang).then((data) => {
      setSolutionTypes(data?.solution_type);
    });

    dispatch(complianceEstOp.setSaveDisabled(false));
  }, []);

  useEffect(() => {
    dispatch(
      complianceEstOp.setSaveDisabled(Helpers.isObjEmpty(solutionsToSave))
    );
  }, [solutionsToSave]);

  useEffect(() => {
    if (userInfo.entitlements?.length > 0) {
      setPermitted(userInfo.entitlements.includes('Second Approval'));
    }
  }, [userToken]);

  useEffect(() => {
    if (saveClicked) {
      solutionsOp
        .saveSolutions(Object.values(solutionsToSave), lang)
        .then((response) => {
          dispatch(complianceEstOp.setSaveClicked(false));

          if (response) {
            dispatch(complianceEstOp.setSaveDisabled(true));
            dispatch(solutionsOp.resetSolutionsToSave());
            dispatch(solutionsOp.fetchSolutions(lang));
          }
        });
    }
  }, [saveClicked]);

  useEffect(() => {
    if (solutions?.length > 0) {
      let newObj: {
        [key: number]: { [key: number | string]: ISolutionsToShow };
      } = {};

      solutions.forEach((item: ISolutions) => {
        const newSolutions: { [key: number]: ISolutionsToShow } = {};

        Object.values(item.SOLUTIONS).forEach((solItem: ISolution) => {
          newSolutions[solItem.sol_id] = {
            ...item,
            sol_id: solItem.sol_id,
            SOLUTIONS: { ...item.SOLUTIONS[solItem.sol_id], bru_id: item.id }
          };

          newObj[item.id] = newSolutions;
        });
      });

      dispatch(solutionsOp.setSolutionsToShow(newObj));
    }
  }, [solutions]);

  const expandedRow = (record: ISolutionsResult[]) => {
    const getRowSpan = (index: number, rec: ISolutionsResult) =>
      index === 0 ? Object.values(solutionsToShow[rec.id])?.length : 0;

    const handlePlusClick = () => {
      dispatch(solutionsOp.addNewSolution(record[0].id));
    };

    const nestedTableColumns = [
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        width: 100,
        render: (category: string, rec: ISolutionsResult, index: number) => {
          return {
            children: (
              <CustomSelect options={[]} defaultValue={category} disabled />
            ),
            props: {
              rowSpan: getRowSpan(index, rec)
            }
          };
        }
      },
      {
        title: 'Cln Req Id',
        dataIndex: [],
        key: 'SOLUTIONS',
        width: 100,
        render: (
          value: ISolutionsResult,
          rec: ISolutionsResult,
          index: number
        ) => {
          return {
            children: <TextBox width={100}>{rec.id}</TextBox>,
            props: {
              rowSpan: getRowSpan(index, rec)
            }
          };
        }
      },
      {
        title: 'CLN Req',
        dataIndex: 'value',
        key: 'value',
        width: 500,
        render: (value: string, rec: ISolutionsResult, index: number) => {
          return {
            children: (
              <TextBox width={500} level={rec.level}>
                {value}
              </TextBox>
            ),
            props: {
              rowSpan: getRowSpan(index, rec)
            }
          };
        }
      },
      {
        title: 'Gaps',
        dataIndex: 'GAPS',
        key: 'GAPS',
        width: 335,
        render: (GAPS: IGaps, rec: ISolutionsResult, index: number) => {
          return {
            children: !Helpers.isObjEmpty(GAPS) ? (
              <Styled.TextBoxContainer>
                {Object.values(GAPS).map((gap: IGapsInfo) => (
                  <TextBox key={gap.gap_id} width={335}>
                    {gap.gap_desc}
                  </TextBox>
                ))}
              </Styled.TextBoxContainer>
            ) : (
              <Styled.StyledTextBox width={335} />
            ),
            props: {
              rowSpan: getRowSpan(index, rec)
            }
          };
        }
      },
      {
        title: 'Audit Findings',
        dataIndex: 'AUDITS',
        key: 'AUDITS',
        width: 335,
        render: (
          AUDITS: { [key: number]: IAudits },
          rec: ISolutionsResult,
          index: number
        ) => {
          return {
            children: !Helpers.isObjEmpty(AUDITS) ? (
              <Styled.TextBoxContainer>
                {Object.values(AUDITS).map((audit: IAudits) => (
                  <TextBox key={uuidv4()} width={335}>
                    {audit.desc}
                  </TextBox>
                ))}
              </Styled.TextBoxContainer>
            ) : (
              <Styled.StyledTextBox width={335} />
            ),
            props: {
              rowSpan: getRowSpan(index, rec)
            }
          };
        }
      },
      {
        title: 'Id',
        dataIndex: 'SOLUTIONS',
        key: 'SOLUTIONS',
        width: 100,
        render: (SOLUTIONS: ISolution) => (
          <>
            {SOLUTIONS.sol_id ? (
              <TextBox width={100}>{SOLUTIONS.sol_id}</TextBox>
            ) : (
              <Styled.StyledTextBox width={100} />
            )}
          </>
        )
      },
      {
        title: 'Solutions',
        dataIndex: 'SOLUTIONS',
        key: 'SOLUTIONS',
        width: 376,
        render: (
          SOLUTIONS: ISolution,
          rec: ISolutionsResult,
          index: number
        ) => (
          <>
            {!Helpers.isObjEmpty(SOLUTIONS) && (
              <TextAreaBoxSolutions solutions={SOLUTIONS} solId={rec.sol_id} />
            )}
            {index === record?.length - 1 && (
              <Styled.ButtonContainer>
                <CustomIconButton
                  type='primary'
                  shape='circle'
                  icon={<PlusOutlined />}
                  size='middle'
                  onClick={handlePlusClick}
                />
              </Styled.ButtonContainer>
            )}
          </>
        )
      },
      {
        title: 'Solution Type',
        dataIndex: 'SOLUTIONS',
        key: 'SOLUTIONS',
        width: 100,
        render: (SOLUTIONS: ISolution, rec: ISolutionsResult) =>
          !Helpers.isObjEmpty(SOLUTIONS) ? (
            <CustomSelect
              options={
                solutionTypes
                  ? Helpers.convertToSelectOptions(solutionTypes)
                  : []
              }
              defaultValue={SOLUTIONS.sol_type}
              onChange={(value: string) =>
                dispatch(
                  solutionsOp.editSolutionValue(
                    rec.sol_id,
                    SOLUTIONS,
                    'sol_type',
                    value
                  )
                )
              }
            />
          ) : (
            <Styled.StyledTextBox width={100} />
          )
      },
      {
        title: 'Evidence Required',
        dataIndex: 'SOLUTIONS',
        key: 'SOLUTIONS',
        width: 150,
        render: (SOLUTIONS: ISolution, rec: ISolutionsResult) =>
          !Helpers.isObjEmpty(SOLUTIONS) ? (
            <CustomSelect
              size='lg'
              options={[
                { value: YesNo.YES, label: 'Required' },
                { value: YesNo.NO, label: 'Not required' }
              ]}
              defaultValue={SOLUTIONS.evd_required}
              onChange={(value: string) =>
                dispatch(
                  solutionsOp.editSolutionValue(
                    rec.sol_id,
                    SOLUTIONS,
                    'evd_required',
                    value
                  )
                )
              }
            />
          ) : (
            <Styled.StyledTextBox width={150} />
          )
      },
      {
        title: 'Approver',
        dataIndex: 'SOLUTIONS',
        key: 'SOLUTIONS',
        width: 150,
        render: (SOLUTIONS: ISolution, rec: ISolutionsResult) =>
          !Helpers.isObjEmpty(SOLUTIONS) ? (
            <CustomSelect
              size='lg'
              options={
                approversList
                  ? Helpers.convertToSelectOptions(approversList)
                  : []
              }
              defaultValue={SOLUTIONS.approver}
              onChange={(value: string) =>
                dispatch(
                  solutionsOp.editSolutionValue(
                    rec.sol_id,
                    SOLUTIONS,
                    'approver',
                    value
                  )
                )
              }
            />
          ) : (
            <Styled.StyledTextBox width={150} />
          )
      },
      {
        title: 'Solution Status',
        dataIndex: 'SOLUTIONS',
        key: 'SOLUTIONS',
        width: 150,
        render: (SOLUTIONS: ISolution, rec: ISolutionsResult) =>
          !Helpers.isObjEmpty(SOLUTIONS) ? (
            <CustomSelect
              size='lg'
              options={[
                { label: 'Approved', value: YesNo.YES },
                { label: 'Not approved', value: YesNo.NO }
              ]}
              defaultValue={SOLUTIONS.sol_approved || ''}
              disabled={!permitted || !SOLUTIONS.sol_approved}
              onChange={(value: YesNo) => handleStatusChange(value, rec.sol_id)}
            />
          ) : (
            <Styled.StyledTextBox width={150} />
          )
      }
    ];

    return (
      <NestedTable
        key={record[0].id}
        className='nested-table'
        columns={nestedTableColumns}
        dataSource={record}
        pagination={false}
      />
    );
  };

  return (
    <ComplianceEstLayout>
      <Styled.ContentWrapper token={token}>
        {drawTable && !Helpers.isObjEmpty(solutionsToShow) ? (
          <CustomTable
            drawTable
            token={token}
            columns={solutionColumns}
            dataSource={Object.values(solutionsToShow).map((item: any) =>
              Object.values(item)
            )}
            itemsCount={Object.values(solutionsToShow).length}
            loading={loading}
            expandable={{
              expandedRowKeys: Object.values(solutionsToShow).map(
                (item: any, index: number) => item[index]
              ),
              expandedRowRender: (record: ISolutionsResult[]) =>
                expandedRow(record)
            }}
            scroll={{ x: 1000 }}
          />
        ) : (
          <CustomTable
            drawTable
            itemsCount={0}
            columns={solutionColumns}
            dataSource={[]}
          />
        )}
      </Styled.ContentWrapper>
    </ComplianceEstLayout>
  );
};

export default TestTable;
