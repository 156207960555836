import { ThemeList } from 'store/ducks/globalTypes';
import i18n from 'components/translation/i18n';

function ColorPalettes() {
  const i18nInitialization = i18n();

  const colorPalettes = [
    { label: i18nInitialization.t('MAIN_THEME'), value: ThemeList.MAIN_THEME },
    {
      label: `${i18nInitialization.t('THEME')} 1`,
      value: ThemeList.FIRST_THEME
    },
    {
      label: `${i18nInitialization.t('THEME')} 2`,
      value: ThemeList.SECOND_THEME
    },
    {
      label: `${i18nInitialization.t('THEME')} 3`,
      value: ThemeList.THIRD_THEME
    },
    {
      label: `${i18nInitialization.t('THEME')} 4`,
      value: ThemeList.FOURTH_THEME
    },
    {
      label: `${i18nInitialization.t('THEME')} 5`,
      value: ThemeList.FIFTH_THEME
    },
    {
      label: `${i18nInitialization.t('THEME')} 6`,
      value: ThemeList.SIXTH_THEME
    },
    {
      label: `${i18nInitialization.t('THEME')} 7`,
      value: ThemeList.SEVENTH_THEME
    },
    {
      label: `${i18nInitialization.t('THEME')} 8`,
      value: ThemeList.EIGHTH_THEME
    }
  ];

  return colorPalettes;
}

export default ColorPalettes;
