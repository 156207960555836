import { ITasksState } from './types';

const initialTasksDefState = (): ITasksState => ({
  error: null,
  loading: false,
  tasks: [],
  tasksToShow: {},
  tasksToSave: {}
});

export default initialTasksDefState;
