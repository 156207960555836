import React from 'react';
import { useSelector } from 'react-redux';
import {
  auditFindingColumns,
  auditFindingsMockedData
} from 'constants/auditFindingsTableColumn';
import CustomTable from '../CustomTable';
import { RootState } from 'store/configureStore';

const GapsAuditFindingsTable: React.FC = () => {
  const { drawTable } = useSelector((state: RootState) => state.complianceEst);
  const auditFindingColumn = auditFindingColumns();

  return (
    <CustomTable
      drawTable={drawTable}
      columns={auditFindingColumn}
      dataSource={auditFindingsMockedData}
      itemsCount={auditFindingsMockedData.length}
      pagination={false}
    />
  );
};

export default GapsAuditFindingsTable;
