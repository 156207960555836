import React, { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { Row } from 'antd';
import DragTextBox from '../DragTextBox';
import DraggableBox from '../DraggableBox';

interface ISortableDrag {
  id: string;
  text: string;
  index: number;
  keyName: string;
  isOverlay?: boolean;
}

const SortableDrag: FC<ISortableDrag> = ({
  id,
  text,
  index,
  keyName,
  isOverlay
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    resizeObserverConfig: {},
    id: id,
    data: { index, keyName },
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  });

  return (
    <Row ref={setNodeRef} {...attributes} {...listeners}>
      <DraggableBox
        isDragging={isDragging}
        isOverlay={isOverlay}
        transition={transition}
        transform={transform}
      >
        <DragTextBox>{text}</DragTextBox>
      </DraggableBox>
    </Row>
  );
};

export default SortableDrag;
