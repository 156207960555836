import React from 'react';
import { RollbackOutlined } from '@ant-design/icons';
import * as Styled from './GoBackButton.styled';
import i18n from 'components/translation/i18n';

const GoBackButton: React.FC = () => {
  const i18nInitialization = i18n();

  return (
    <Styled.StyledLink to='/workingAreas'>
      <Styled.StyledGoBackButton type='primary'>
        {i18nInitialization.t('WORKING_AREAS')}
        <RollbackOutlined />
      </Styled.StyledGoBackButton>
    </Styled.StyledLink>
  );
};

export default GoBackButton;
