import React from 'react';
import { theme } from 'antd';
import * as Styled from './Card.styled';

const { useToken } = theme;

interface ICardComponentProps {
  area: string;
  infoText: string;
  imgSrc: string;
  href: string;
}

const CardComponent: React.FC<ICardComponentProps> = ({
  area,
  infoText,
  imgSrc,
  href
}) => {
  const { token } = useToken();

  return (
    <Styled.StyledLinkButton type='link' href={href}>
      <Styled.StyledCard token={token} cover={<img alt='' src={imgSrc} />}>
        <Styled.StyledMeta token={token} title={area} description={infoText} />
      </Styled.StyledCard>
    </Styled.StyledLinkButton>
  );
};

export default CardComponent;
