const resetPassInputs = [
  {
    label: 'Password',
    name: 'password'
  },
  {
    label: 'Confirm password',
    name: 'confirm_password'
  }
];

export default resetPassInputs;
