import { IEvidencesState } from './types';

const initialEvidencesDefState = (): IEvidencesState => ({
  error: null,
  loading: false,
  evidences: [],
  evidencesToShow: [],
  evidencesToSave: {}
});

export default initialEvidencesDefState;
