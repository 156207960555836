import { Button, Col } from 'antd';
import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';

export const StyledButton = styled(Button)<IToken>`
  border: unset;
  margin-bottom: ${(props) => `${props.token.marginSM}px`};
  width: 110px;
  padding: 0;
`;

export const StyledCol = styled(Col)<IToken>`
  margin-top: ${(props) => `${props.token.margin}px`};
`;
