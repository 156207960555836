import { AliasToken } from 'antd/es/theme/interface';

export interface IToken {
  token: AliasToken;
}

export interface IFilters {
  filter: string;
  options: ISelectionOption[];
}

export interface ISelectionOption {
  value: string;
  label: string;
}

export interface IResetPass {
  password: string;
  confirm_password: string;
}

export interface ICustomMenuItem {
  label: JSX.Element;
  key: string;
  path: string;
}

export interface IResponseFilters {
  ind?: number;
  cntry?: number;
  mod?: number;
  reg?: number;
  ent?: number;
  cat?: number;
  prod?: number;
}

export interface IUserInfo {
  email: string;
  entitlements: string[];
  role: string;
  user_id: number;
}

export interface IOwnership {
  per_email: string;
  per_first_name: string;
  per_last_name: string;
  per_person_id: number;
}

export enum ThemeList {
  MAIN_THEME = 'mainTheme',
  FIRST_THEME = 'firstTheme',
  SECOND_THEME = 'secondTheme',
  THIRD_THEME = 'thirdTheme',
  FOURTH_THEME = 'fourthTheme',
  FIFTH_THEME = 'fifthTheme',
  SIXTH_THEME = 'sixthTheme',
  SEVENTH_THEME = 'seventhTheme',
  EIGHTH_THEME = 'eighthTheme'
}

export enum LanguageList {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  IT = 'it',
  ES = 'es',
  PT = 'pt'
}

export enum TitleListEN {
  WORKING_AREAS = 'Working areas',
  COMP_EST = 'Compliance Creation',
  DATA_MANAGEMENT = 'Data Management',
  REPORTING = 'Reporting',
  INTERNAL_CONTROLS = 'Controls and Risk allocation'
}

export enum TitleListDE {
  WORKING_AREAS = 'Arbeitsbereiche',
  COMP_EST = 'Compliance Erstellung',
  DATA_MANAGEMENT = 'Datenmanagement',
  REPORTING = 'Berichterstattung',
  INTERNAL_CONTROLS = 'Steuerung und Risikozuweisung'
}

export enum TitleListES {
  WORKING_AREAS = 'Áreas de Trabajo',
  COMP_EST = 'Creación de Cumplimiento',
  DATA_MANAGEMENT = 'Gestión de Datos',
  REPORTING = 'Informe',
  INTERNAL_CONTROLS = 'Controles y Asignación de Riesgos'
}

export enum TitleListFR {
  WORKING_AREAS = 'Domaines de travail',
  COMP_EST = 'Création de conformité',
  DATA_MANAGEMENT = 'Gestion des données',
  REPORTING = 'Rapports',
  INTERNAL_CONTROLS = 'Contrôles et Allocation des Risques'
}

export enum TitleListIT {
  WORKING_AREAS = 'Aree di lavoro',
  COMP_EST = 'Creazione di conformità',
  DATA_MANAGEMENT = 'Gestione dei dati',
  REPORTING = 'Segnalazione',
  INTERNAL_CONTROLS = 'Controlli e Allocazione del Rischio'
}

export enum TitleListPT {
  WORKING_AREAS = 'Áreas de Trabalho',
  COMP_EST = 'Criação de Conformidade',
  DATA_MANAGEMENT = 'Gestão de Dados',
  REPORTING = 'Relatórios',
  INTERNAL_CONTROLS = 'Controles e Alocação de Riscos'
}

export enum AreasDe {
  DATA_MANAGEMENT = 'Datenmanagement',
  COMP_EST = 'Compliance-Einrichtung',
  COCKPIT = 'Cockpit',
  RISK_MANAGEMENT = 'Risiko- und Kontrollmanagement',
  REPORTING = 'Berichterstattung',
  INTERNAL_CONTROLS = 'Internal Controls'
}

export enum AreasEn {
  DATA_MANAGEMENT = 'Data Management',
  COMP_EST = 'Compliance Creation',
  COCKPIT = 'Cockpit',
  RISK_MANAGEMENT = 'Risk and Control Management',
  REPORTING = 'Reporting',
  INTERNAL_CONTROLS = 'Interne Kontrollen'
}

export type TitleList =
  | TitleListEN
  | TitleListDE
  | TitleListES
  | TitleListFR
  | TitleListIT
  | TitleListPT;

export enum CategoryList {
  OUTSOURCING = 'Outsourcing',
  IT_SECURITY = 'IT security',
  BUSINESS_MANAGEMENT = 'Business Continuity Management',
  GENERAL_CATEGORY = 'General Category',
  DEFAULT_VALUE = 'Default Value'
}

export enum YesNo {
  YES = 'Y',
  NO = 'N'
}

export enum Lang {
  en = 'en',
  ge = 'ge'
}
