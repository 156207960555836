import { Button } from 'antd';
import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';

export const StyledButton = styled(Button)`
  min-width: 100%;
`;

export const StyledDiv = styled('div')<IToken>`
  margin-top: ${(props) => `${props.token.marginSM}px`};
`;

export const StyledMainDiv = styled('div')<IToken>`
  padding: ${(props) => `${props.token.marginXS}px`};
`;
