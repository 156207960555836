import { IGlobalState } from './types';
import { LanguageList, ThemeList } from '../globalTypes';

const initialGlobalState = (): IGlobalState => ({
  error: null,
  loading: false,
  scheme: (localStorage.getItem('theme') as ThemeList) || ThemeList.MAIN_THEME,
  lang: (localStorage.getItem('lang') as LanguageList) || LanguageList.EN,
  searchParams: ''
});

export default initialGlobalState;
