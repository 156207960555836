import styled from 'styled-components';
import { Button, Form } from 'antd';
import { IToken } from 'store/ducks/globalTypes';
import Background from 'assets/svg/LoginPageBackground.svg';

export const StyledLinkButton = styled(Button)<IToken>`
  padding: unset;
  line-height: inherit;
  margin-right: ${(props) => `${props.token.marginXXS}px`};

  > span {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const StyledBackground = styled('div')`
  background: ${() => `no-repeat center url(${Background})`};
  min-height: 100vh;
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledButton = styled(Button)<IToken>`
  min-width: 100%;
  margin-top: ${(props) => `${props.token.marginXS}px`};
  border-radius: ${(props) => `${props.token.borderRadiusSM}px !important`};
`;

export const StyledFormItem = styled(Form.Item)<IToken>`
  margin-bottom: ${(props) => `${props.token.margin}px`};

  .ant-input,
  .ant-input-affix-wrapper {
    padding: 16px 14px;
    border-radius: ${(props) => `${props.token.borderRadiusSM}px`};
  }

  .ant-col {
    min-width: 100%;
  }
`;

export const FormContainer = styled('div')<IToken>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${(props) => `${props.token.paddingXL}px`};

  > .ant-typography {
    margin: 0;
  }

  > .ant-form {
    min-width: 550px;
    margin-top: ${(props) => `${props.token.marginLG}px`};
  }
`;

export const IconContainer = styled('div')<IToken>`
  background: ${(props) => props.token.colorPrimary};
  padding: ${(props) => `${props.token.paddingSM}px`};
  border-radius: 50%;

  > span {
    font-size: ${(props) => `${props.token.fontSizeXL}px`};
    color: ${(props) => props.token.colorPrimaryText};
  }
`;
