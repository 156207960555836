import React from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import CardComponent from 'components/CardComponent';
import workingAreas from 'constants/workingAreas';
import TitleBar from 'components/TitleBar';
import { RootState } from 'store/configureStore';

import i18n from 'components/translation/i18n';
import * as Styled from './WorkingAreas.styled';

const WorkingAreasPage = () => {
  const i18nInitialization = i18n();

  const areas = workingAreas();
  return (
    <Layout>
      <TitleBar title={i18nInitialization.t('WORKING_AREAS')} />
      <Styled.Container>
        <Styled.GridContainer>
          {areas.map((item: any) => (
            <CardComponent
              key={item.area}
              area={item.area}
              infoText={item.infoText}
              imgSrc={item.imgSrc}
              href={item.href}
            />
          ))}
        </Styled.GridContainer>
      </Styled.Container>
    </Layout>
  );
};

export default WorkingAreasPage;
