import React from 'react';
import TextBox from 'components/TextBox';
import TestDragAndDrop from 'components/DragAndDropEv/Evidence/EvDragAndDrop';

const evidenceColumns = [
  {
    title: 'Not sorted evidence ref',
    dataIndex: 'notSortedEvidence',
    key: 'notSortedEvidence',
    render: (notSortedEvidence: string, record: any, index: number) => {
      if (index < 1) {
        return (
          <TextBox>
            <TestDragAndDrop />
          </TextBox>
        );
      }
      return null;
    },
    onCell: () => {
      return { rowSpan: 3 };
    }
  }
];

const evidenceMockedData = [
  {
    key: 1
  }
];

export { evidenceColumns, evidenceMockedData };
