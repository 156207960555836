import { Menu } from 'antd';
import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';

export const StyledMenu = styled(Menu)<IToken>`
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;

  &:before {
    display: none;
  }

  .ant-menu-title-content {
    z-index: 9;
  }

  > .ant-menu-item-selected {
    border: 1px solid white;
    border-radius: 10px !important;
    color: ${(props) => `${props.token.colorPrimaryText} !important`};

    &:first-child:before {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child:before {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      box-shadow: ${(props) => props.token.boxShadowTertiary};
    }
  }

  > .ant-menu-item-selected,
  > .ant-menu-item-active {
    &:after {
      display: none;
    }
  }

  .ant-menu-item {
    width: 34%;
    display: flex;
    justify-content: center;
    top: unset !important;
    margin-top: unset !important;

    &:hover {
      opacity: 0.6;

      &:after {
        display: none;
      }
    }
  }
`;
