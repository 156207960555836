import { FC } from 'react';
import { theme } from 'antd';
import { useDroppable } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import SortableDrag from '../SortableDrag';
import * as Styled from './Droppable.styled';

interface IDroppable {
  arrayData: { id: string; text: string }[];
  keyName: string;
  colNum: number;
}

const { useToken } = theme;

const Droppable: FC<IDroppable> = ({ arrayData, keyName }) => {
  const { token } = useToken();

  const { setNodeRef } = useDroppable({
    id: keyName,
    data: { keyName }
  });

  const renderBox = () =>
    arrayData.map(({ text, id }, index: number) => (
      <SortableDrag
        id={id}
        keyName={keyName}
        text={text}
        key={index}
        index={index}
      />
    ));

  return (
    <SortableContext
      id={keyName}
      items={arrayData}
      strategy={rectSortingStrategy}
    >
      <Styled.StyledRow token={token} ref={setNodeRef}>
        {renderBox()}
      </Styled.StyledRow>
    </SortableContext>
  );
};

export default Droppable;
