import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './en.json';
import deTranslations from './de.json';
import frTranslations from './fr.json';
import itTranslations from './it.json';
import ptTranslations from './pt.json';
import esTranslations from './es.json';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';

const initializeI18n = () => {
  const resources = {
    en: {
      translation: enTranslations
    },
    de: {
      translation: deTranslations
    },
    fr: {
      translation: frTranslations
    },
    it: {
      translation: itTranslations
    },
    pt: {
      translation: ptTranslations
    },
    es: {
      translation: esTranslations
    }
  };

  const { lang } = useSelector((state: RootState) => ({
    lang: state.global.lang
  }));

  i18n.use(initReactI18next).init({
    lng: lang,
    fallbackLng: 'en',
    resources
  });

  return i18n;
};

export default initializeI18n;
