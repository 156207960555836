export const themesList = {
  mainTheme: {
    colorPrimary: '#5f9ea0',
    mainColor: '#fff',
    colorBgLayout: '#e1e4f0',
    colorPrimaryBgHover: 'rgba(52, 137, 156, 0.7)',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#778fa2',
    inputBorderColor: '#00fff0',
    inputColorPrimary: '#5f9ea0',
    colorFillContent: 'rgba(0, 54, 84, 0.5)',
    colorFillContentHover: 'rgba(0, 54, 84, 0.3)',
    colorFillQuaternary: 'rgba(0, 54, 84, 0.7)',
    shadow:
      '-90px 0 60px -30px #003654 inset, 90px 0px 60px -30px #003654 inset'
  },
  firstTheme: {
    colorPrimary: '#635985',
    mainColor: '#fff',
    colorBgLayout: '#efecf0',
    colorPrimaryBgHover: '#635985',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#847f8e',
    inputBorderColor: 'rgba(24, 18, 43, 0.9)',
    inputColorPrimary: '#847f8e',
    colorFillContent: '#847f8e',
    colorFillContentHover: 'rgba(24, 18, 43, 0.3)',
    colorFillQuaternary: 'rgba(24, 18, 43, 0.7)',
    shadow:
      '-90px 0 60px -30px rgba(24, 18, 43, 0.9) inset, 90px 0px 60px -30px rgba(24, 18, 43, 0.9) inset'
  },
  secondTheme: {
    colorPrimary: '#495579',
    mainColor: '#fff',
    colorBgLayout: 'rgb(255, 251, 235)',
    colorPrimaryBgHover: 'rgba(73, 85, 121, 0.7)',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#495579',
    inputBorderColor: '#263159',
    inputColorPrimary: '#495579',
    colorFillContent: 'rgba(38, 49, 89, 0.6)',
    colorFillContentHover: '#263159',
    colorFillQuaternary: '#263159',
    shadow:
      '-90px 0 60px -30px #251749 inset, 90px 0px 60px -30px #251749 inset'
  },
  thirdTheme: {
    colorPrimary: '#678983',
    mainColor: '#fff',
    colorBgLayout: '#181D31',
    colorPrimaryBgHover: 'rgba(103, 137, 131, 0.7)',
    colorBorderSecondary: '#fff',
    colorBgSpotlight: '#E6DDC4',
    inputBorderColor: '#E6DDC4',
    inputColorPrimary: '#678983',
    colorFillContent: '#E6DDC4',
    colorFillContentHover: 'rgba(230, 221, 196, 0.8)',
    colorFillQuaternary: 'rgba(230, 221, 196, 0.8)',
    shadow:
      '-90px 0 60px -30px #678983 inset, 90px 0px 60px -30px #678983 inset'
  },
  fourthTheme: {
    colorPrimary: '#EB6440',
    mainColor: '#fff',
    colorBgLayout: '#EFF5F5',
    colorPrimaryBgHover: '#497174',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: 'rgba(73, 113, 116, 0.6)',
    inputBorderColor: 'rgba(73, 113, 116, 0.8)',
    inputColorPrimary: 'rgba(73, 113, 116, 0.8)',
    colorFillContent: 'rgba(73, 113, 116, 0.6)',
    colorFillContentHover: '#497174',
    colorFillQuaternary: '#497174',
    shadow:
      '-90px 0 60px -30px #497174 inset, 90px 0px 60px -30px #497174 inset'
  },
  fifthTheme: {
    colorPrimary: '#D14D72',
    mainColor: '#fff',
    colorBgLayout: '#cceefe',
    colorPrimaryBgHover: 'rgba(55, 146, 55, 0.9)',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: 'rgba(55, 146, 55, 0.6)',
    inputBorderColor: 'rgba(55, 146, 55, 0.9)',
    inputColorPrimary: 'rgba(55, 146, 55, 0.6)',
    colorFillContent: 'rgba(55, 146, 55, 0.6)',
    colorFillContentHover: 'rgba(55, 146, 55, 0.8)',
    colorFillQuaternary: 'rgba(55, 146, 55, 0.9)',
    shadow:
      '-90px 0 60px -30px #379237 inset, 90px 0px 60px -30px #379237 inset'
  },
  sixthTheme: {
    colorPrimary: 'rgb(243,174,65)',
    mainColor: '#fff',
    colorBgLayout: '#1c5454',
    colorPrimaryBgHover: '#254276',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#254276',
    inputBorderColor: '#254276',
    inputColorPrimary: '#254276',
    colorFillContent: '#254276',
    colorFillContentHover: '#254276',
    colorFillQuaternary: '#254276',
    shadow:
      '-90px 0 60px -30px #254276 inset, 90px 0px 60px -30px #254276 inset'
  },
  seventhTheme: {
    colorPrimary: '#73a2c1',
    mainColor: '#fff',
    colorBgLayout: '#fff',
    colorPrimaryBgHover: '#9DB2BF',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#9DB2BF',
    inputBorderColor: '#9DB2BF',
    inputColorPrimary: '#9DB2BF',
    colorFillContent: '#9DB2BF',
    colorFillContentHover: '#9DB2BF',
    colorFillQuaternary: '#9DB2BF',
    shadow:
      '-90px 0 60px -30px #9DB2BF inset, 90px 0px 60px -30px #9DB2BF inset'
  },
  eighthTheme: {
    colorPrimary: '#238636',
    mainColor: '#fff',
    colorBgLayout: '#010405',
    colorPrimaryBgHover: '#28313e',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#28313e',
    inputBorderColor: '#28313e',
    inputColorPrimary: '#28313e',
    colorFillContent: '#28313e',
    colorFillContentHover: '#28313e',
    colorFillQuaternary: '#28313e',
    shadow:
      '-90px 0 60px -30px #28313e inset, 90px 0px 60px -30px #28313e inset'
  }
};
