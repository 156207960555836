import React from 'react';
import { Table, theme } from 'antd';
import * as Styled from './CustomTable.styled';
import i18n from 'components/translation/i18n';

const { useToken } = theme;

interface ICustomTableProps {
  drawTable: boolean;
  itemsCount: number;
  [rest: string]: any;
}

const CustomTable: React.FC<ICustomTableProps> = ({
  drawTable,
  itemsCount,
  ...rest
}) => {
  const { token } = useToken();
  const i18nInitialization = i18n();

  const customPageSizeOptions = [1, 2, 5, 10, 20, 50, 100];

  return (
    <Styled.TableWrapper token={token} drawTable={drawTable}>
      <Table
        {...rest}
        pagination={
          drawTable
            ? {
                defaultPageSize: 2,
                hideOnSinglePage: false,
                showSizeChanger: true,
                pageSizeOptions: customPageSizeOptions.map((size) => `${size}`),
                position: ['topLeft'],
                showQuickJumper: true,
                total: itemsCount,
                locale: {
                  items_per_page: `/ ${i18nInitialization.t('PAGE')}`,
                  jump_to: i18nInitialization.t('GO_TO_PAGE'),
                  page: i18nInitialization.t('PAGE')
                },
                showTotal: (total: number, range: number[]) => {
                  return `${range[0]}-${range[1]} ${i18nInitialization.t(
                    'OF'
                  )} ${total} ${i18nInitialization.t('ITEMS')}`;
                }
              }
            : false
        }
      />
    </Styled.TableWrapper>
  );
};

export default CustomTable;
