import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import {
  IEvidenceResult,
  IEvidencesState,
  IEvidencesToShow,
  IEvidenceToShow
} from './types';
import initialEvidencesState from './initialState';
import Helpers from 'utils/Helpers';
import newEvidenceObj from 'constants/newEvidence';

const evidencesSlice = createSlice({
  name: 'evidences',
  initialState: initialEvidencesState(),
  reducers: {
    set_evidences(
      state: IEvidencesState,
      action: PayloadAction<IEvidenceResult[]>
    ) {
      state.evidences = action.payload;
      state.loading = false;
    },
    set_evidences_to_show(
      state: IEvidencesState,
      action: PayloadAction<{
        [key: number]: { [key: number | string]: IEvidencesToShow };
      }>
    ) {
      state.evidencesToShow = action.payload;
      state.loading = false;
    },
    add_new_evidence: (
      state,
      action: PayloadAction<{ cln_req_id: number; sol_id: number }>
    ) => {
      const oldState = current(state);
      const { cln_req_id, sol_id } = action.payload;

      const newId = uuidv4();

      const solObj = Object.values(oldState.evidencesToShow[cln_req_id]).find(
        (obj: IEvidencesToShow) => obj.SOLUTIONS.sol_id === sol_id
      );

      const lastIndex = Object.values(
        oldState.evidencesToShow[cln_req_id]
      ).reduceRight((acc, obj, index) => {
        if (obj.SOLUTIONS.sol_id === sol_id && acc === -1) {
          return index;
        }
        return acc;
      }, -1);

      const keys = Object.keys(oldState.evidencesToShow[cln_req_id]);
      let newObject: any = {};

      for (let i = 0; i < keys.length; i++) {
        if (i === lastIndex + 1 || i === keys.length - 1) {
          newObject[newId] = {
            ...solObj,
            SOLUTIONS: {
              ...solObj?.SOLUTIONS,
              EVIDENCES: newEvidenceObj(cln_req_id, sol_id, newId)
            }
          };
        }

        if (keys[i] !== `emptyEvidence${sol_id}`) {
          newObject[keys[i]] = oldState.evidencesToShow[cln_req_id][keys[i]];
        }
      }

      state.evidencesToShow = {
        ...oldState.evidencesToShow,
        [cln_req_id]: newObject
      };

      state.error = false;
      state.loading = false;
    },
    edit_evidence(
      state: IEvidencesState,
      action: PayloadAction<{
        evidenceId: string | number;
        evidenceData: IEvidenceToShow;
        key: string;
        value: string;
      }>
    ) {
      const oldState = current(state);
      const { evidenceId, evidenceData, key, value } = action.payload;
      const { evd_reviewer_ref, evd_id, evd_reviewed, evd_approved, ...rest } =
        evidenceData;

      const evidenceToSave =
        oldState.evidencesToSave[evidenceId] &&
        !Helpers.isObjEmpty(oldState.evidencesToSave[evidenceId])
          ? { ...oldState.evidencesToSave[evidenceId] }
          : { ...rest };

      const editedEvidence =
        typeof evd_id === 'number'
          ? {
              ...evidenceToSave,
              evd_id,
              [key]: value
            }
          : {
              ...evidenceToSave,
              [key]: value
            };

      // @ts-ignore
      state.evidencesToSave = {
        ...oldState.evidencesToSave,
        [evidenceId]: editedEvidence
      };
      state.loading = false;
    },
    reset_evidences_to_save(state: IEvidencesState) {
      state.evidencesToSave = {};
    },
    delete_evidence: (
      state: IEvidencesState,
      action: PayloadAction<{ clnReqId: number; evId: any }>
    ) => {
      const oldState = current(state);
      const { clnReqId, evId } = action.payload;
      const clnReqObj = oldState.evidencesToShow[clnReqId];
      const newKey = `emptyEvidence${clnReqObj[evId].SOLUTIONS.sol_id}`;
      const order = [...Object.keys(clnReqObj)];
      const evidenceKey = Object.keys(clnReqObj).indexOf(evId);

      order.splice(evidenceKey, 0, newKey);

      let filteredEvidencesToShow;
      let filteredEvidencesToSave;

      let count = 0;
      let keyToUpdate: string | number | null = null;

      for (const key in clnReqObj) {
        const { SOLUTIONS } = clnReqObj[key];

        if (
          SOLUTIONS &&
          SOLUTIONS.sol_id === clnReqObj[evId].SOLUTIONS.sol_id
        ) {
          count++;
          keyToUpdate = key;
        }
      }

      //filter evidencesToShow
      if (count === 1 && keyToUpdate) {
        for (const key in clnReqObj) {
          const { SOLUTIONS } = clnReqObj[key];

          if (
            SOLUTIONS &&
            SOLUTIONS.sol_id === clnReqObj[evId].SOLUTIONS.sol_id
          ) {
            keyToUpdate = key;
            break;
          }
        }

        let filteredEvidences: { [key: number | string]: IEvidencesToShow } =
          {};

        if (clnReqObj.hasOwnProperty(evId)) {
          order.forEach((key) => {
            if (key === newKey && keyToUpdate) {
              Object.assign(filteredEvidences, {
                [key]: {
                  ...clnReqObj[keyToUpdate],
                  SOLUTIONS: {
                    ...clnReqObj[keyToUpdate].SOLUTIONS,
                    EVIDENCES: {}
                  }
                }
              });
            } else if (key !== evId) {
              Object.assign(filteredEvidences, {
                [key]: clnReqObj[key]
              });
            }
          });
        }

        filteredEvidencesToShow = filteredEvidences;
      } else if (clnReqObj && clnReqObj[evId] && count !== 1) {
        const { [evId]: _, ...rest } = clnReqObj;

        filteredEvidencesToShow = rest;
      } else {
        filteredEvidencesToShow = clnReqObj;
      }

      //filter evidencesToSave
      if (oldState.evidencesToSave[evId]) {
        const { [evId]: _, ...rest } = oldState.evidencesToSave;

        filteredEvidencesToSave = rest;
      } else {
        filteredEvidencesToSave = oldState.evidencesToSave;
      }

      state.evidencesToShow = {
        ...oldState.evidencesToShow,
        [clnReqId]: filteredEvidencesToShow
      };
      state.evidencesToSave = filteredEvidencesToSave;
      state.error = false;
      state.loading = false;
    },
    set_loading(state: IEvidencesState) {
      state.loading = true;
    },
    set_error(state: IEvidencesState, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export default evidencesSlice;
