import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';

export const Container = styled('div')`
  display: flex;
  gap: 15px;
  flex-direction: column;
  padding: unset !important;
`;

export const InfoContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const IdContainer = styled('div')<IToken>`
  width: 70px;
  margin-right: ${(props) => `${props.token.marginXXS}px`};
`;

export const StyledSpan = styled('span')<IToken>`
  margin-right: ${(props) => `${props.token.marginXXS}px`};
`;
