const signUpFormItems = [
  {
    type: 'text',
    label: 'Last Name',
    name: 'lastName',
    rules: [
      {
        required: true,
        message: 'Please input your last name!'
      }
    ]
  },
  {
    type: 'email',
    label: 'Email Address',
    name: 'email',
    rules: [
      {
        required: true,
        message: 'Please input your email!'
      }
    ]
  },
  {
    type: 'password',
    label: 'Password',
    name: 'password',
    rules: [
      {
        required: true,
        message: 'Please input your password!'
      }
    ]
  },
  {
    type: 'password',
    label: 'Confirm Password',
    name: 'confirmPassword',
    rules: [
      {
        required: true,
        message: 'Please confirm your password!'
      }
    ]
  }
];

export default signUpFormItems;
