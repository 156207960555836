import styled from 'styled-components';
import { variablesList } from 'constants/variablesList';
import { ThemeList } from 'store/ducks/globalTypes';
import { IThemeSelectorProps } from './ThemeSelector';

export const ThemeContainer = styled('div')<IThemeSelectorProps>`
  .ant-tabs .ant-tabs-nav .ant-tabs-tab {
    background-color: ${(props) =>
      variablesList[props.theme as ThemeList].defaultTabBg};
  }

  .ant-tabs-tab-active {
    background: ${(props) =>
      variablesList[props.theme as ThemeList].tabSelected} !important;
  }

  .ant-btn.ant-btn-primary.ant-btn-icon-only:not(:disabled) {
    background-color: ${(props) =>
      variablesList[props.theme as ThemeList].iconButtonBg} !important;

    &:hover {
      background-color: ${(props) =>
        variablesList[props.theme as ThemeList].iconButtonBgHover} !important;
    }
  }

  .ant-menu.ant-menu-root.ant-menu-horizontal {
    background-color: ${(props) =>
      variablesList[props.theme as ThemeList].menuBackground};
    border: ${(props) => variablesList[props.theme as ThemeList].menuBorder};
    color: ${(props) => variablesList[props.theme as ThemeList].menuColor};

    li {
      span {
        span {
          color: ${(props) =>
            variablesList[props.theme as ThemeList].menuColor};
        }
      }
    }
  }

  .ant-menu-item:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ),
  > .ant-menu
    .ant-menu-item:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ) {
    color: ${(props) => variablesList[props.theme as ThemeList].menuColorHover};
  }

  .ant-menu > .ant-menu-item-selected:before {
    background-color: ${(props) =>
      variablesList[props.theme as ThemeList].menuItemBg};
  }

  .ant-btn-primary:not(:disabled):hover {
    background-color: ${(props) =>
      variablesList[props.theme as ThemeList].buttonPrimaryBgHover};
  }

  .drag-text-box {
    border-bottom: ${(props) =>
      variablesList[props.theme as ThemeList].colorBorder};
  }

  .ant-checkbox:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    border: ${(props) =>
      variablesList[props.theme as ThemeList].checkboxBorder};
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    border: 2px solid #aeaeae;
  }

  .ant-typography {
    color: ${(props) =>
      variablesList[props.theme as ThemeList].colorTypography};
  }

  .ant-table-tbody .ant-table-cell {
    div,
    textarea,
    button {
      color: ${(props) =>
        variablesList[props.theme as ThemeList].tableCellColor};
    }
  }

  .ant-picker-dropdown .ant-picker-today-btn {
    color: ${(props) =>
      variablesList[props.theme as ThemeList].datePickerDayColor};
  }

  .color-typography {
    color: ${(props) =>
      variablesList[props.theme as ThemeList].colorTypography};
  }

  .ant-table-cell-row-hover {
    background: ${(props) =>
      variablesList[props.theme as ThemeList].bgColor} !important;
  }

  .nested-table-wrapper .ant-table-wrapper .ant-table {
    border-bottom: ${(props) =>
      variablesList[props.theme as ThemeList].nestedTableBorder} !important;
  }

  .cln-req-wrapper .ant-table-wrapper .ant-table {
    .ant-table-tbody > tr:not(:first-child) > td {
      border-bottom: ${(props) =>
        variablesList[props.theme as ThemeList].nestedTableBorder} !important;
    }
  }
`;
