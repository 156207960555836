import qs from 'qs';
import { notification } from 'antd';
import { Dispatch } from '@reduxjs/toolkit';
import ApiService from 'utils/ApiCalls';
import globalSlice from './globalSlice';
import {
  IOwnership,
  IResetPass,
  LanguageList,
  ThemeList
} from '../globalTypes';

const { set_theme, set_lang, set_search_params, set_loading } =
  globalSlice.actions;

const setTheme = (theme: ThemeList) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_theme(theme));
  } catch (error) {
    console.error(error);
  }
};

const setLang = (lang: LanguageList) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_lang(lang));
  } catch (error) {
    console.error(error);
  }
};

const setSearchParams = (params: string) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_search_params(params));
  } catch (error) {
    console.error(error);
  }
};

const login = async (email: string, password: string) => {
  try {
    const response = await ApiService.login({
      email,
      password
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const forgotPass = async (email: string) => {
  try {
    const response = await ApiService.forgotPass(email);
    const { status, data } = response;

    notification.success({
      message: status,
      description: data.message
    });
  } catch (error) {
    console.error(error);
  }
};

const resetPass = async (
  formData: IResetPass,
  linkToken: string | qs.ParsedQs | string[] | qs.ParsedQs[]
) => {
  try {
    const response = await ApiService.resetPass(formData, linkToken);

    notification.success({
      message: response.status,
      description: 'Your password has been updated.'
    });
  } catch (error) {
    console.error(error);
  }
};

const fetchOwnersList = async () => {
  try {
    const response = await ApiService.getOwnersList();

    return response.data?.ownership?.map((item: IOwnership) => item.per_email);
  } catch (error) {
    console.error(error);
  }
};

const fetchStatusList = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getStatuses(lang);

    return response.data.task_type;
  } catch (error) {
    console.error(error);
  }
};

const fetchTypesList = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getTypesList(lang);

    return response.data.task_type;
  } catch (error) {
    console.error(error);
  }
};

export default {
  setTheme,
  setLang,
  setSearchParams,
  login,
  forgotPass,
  fetchOwnersList,
  fetchStatusList,
  fetchTypesList,
  resetPass
};
