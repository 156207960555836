import styled from 'styled-components';
import CustomIconButton from '../CustomIconButton';
import { IToken } from 'store/ducks/globalTypes';
import TextBox from '../TextBox';

interface IContainer extends IToken {
  clnReqsExist: boolean;
}

export const StyledContainer = styled('div')<IContainer>`
  display: flex;
  flex-direction: ${(props) =>
    props.clnReqsExist ? 'column' : 'column-reverse'};
  align-items: center;
  gap: 20px;
  // min-width: 369px;
  min-width: 501px;
  // margin-left: 20px;
`;

export const StyledButtonSection = styled('div')<IToken>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => `${props.token.marginSM}px`};
`;

export const StyledDiv = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const StyledCustomIconButton = styled(CustomIconButton)<IToken>`
  margin-top: ${(props) => `${props.token.marginXXS}px`};
`;

export const ButtonContainer = styled('div')<{
  withCheckbox?: boolean;
}>`
  min-width: 367px;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  margin-right: 18px;
`;

export const StyledTextBox = styled(TextBox)`
  visibility: hidden;
`;

export const Container = styled('div')`
  padding-right: 16px;
`;

export const TextAreaContainer = styled('div')`
  padding-left: 16px;
`;
