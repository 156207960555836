export const theme = (themeVars: any) => ({
  token: {
    wireframe: false,
    fontSizeHeading1: 52,
    colorPrimary: themeVars.colorPrimary,
    colorBgBase: themeVars.mainColor,
    colorPrimaryBg: 'rgb(225, 228, 240, 0.4)',
    colorPrimaryBgHover: themeVars.colorPrimaryBgHover,
    colorBgContainer: themeVars.mainColor,
    colorBgElevated: themeVars.mainColor,
    colorBgLayout: themeVars.colorBgLayout,
    colorBgSpotlight: themeVars.colorBgSpotlight,
    colorBgContainerDisabled: '#d9d9d9',
    controlItemBgActiveDisabled: '#d9d9d9',
    colorDisabled: '#d9d9d9',
    colorBgMask: '#f0f0f0',
    colorBorderSecondary: themeVars.colorBorderSecondary,
    colorPrimaryText: themeVars.mainColor,
    colorLink: themeVars.mainColor,
    colorLinkHover: themeVars.mainColor,
    colorLinkActive: themeVars.mainColor,
    lineHeightHeading2: 46,
    fontSizeLG: 18,
    borderRadius: 8,
    borderRadiusSM: 4,
    borderRadiusLG: 8,
    paddingXXS: 5,
    paddingSM: 10,
    marginXXS: 5,
    marginLG: 24,
    paddingXL: 100,
    marginXXL: 110,
    colorFillContent: themeVars.colorFillContent,
    colorFillContentHover: themeVars.colorFillContentHover,
    colorFillQuaternary: themeVars.colorFillQuaternary,
    fontWeightStrong: 700,
    linkDecoration: 'none',
    boxShadowTertiary: themeVars.shadow
  },
  components: {
    Card: {
      colorTextDescription: themeVars.mainColor,
      colorTextHeading: themeVars.mainColor,
      colorBorderSecondary: themeVars.inputBorderColor,
      lineWidth: 2,
      colorBgContainer: themeVars.inputColorPrimary,
      fontSize: 12
    },
    Checkbox: {
      colorPrimary: themeVars.inputColorPrimary,
      colorBorder: themeVars.mainColor,
      lineWidth: 2
    },
    Button: {
      colorBgContainer: themeVars.inputColorPrimary,
      colorPrimaryText: themeVars.mainColor,
      borderRadius: 4
    },
    Menu: {
      fontSize: 18
    },
    Table: {
      colorTextHeading: themeVars.mainColor
    },
    Tabs: {
      colorText: themeVars.mainColor
    }
  }
});
