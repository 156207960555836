import { IGapsState } from './types';

const initialGapsState = (): IGapsState => ({
  error: null,
  loading: false,
  auditFindingMapping: [],
  evidenceMapping: [],
  gapsDescription: [],
  gapsTableData: [],
  additionalInfo: [],
  assignedEls: [],
  assignedEvidences: [],
  assignedAuditFindingsUpdated: false,
  assignedEvidencesUpdated: false,
  tableFilters: {
    gapNoticed: ['all']
  },
  selectedEvidenceModule: false,
  clickedHandleMoveButton: false,
  clickedHandleEvidenceMoveButton: false,
  infoSave: {},
  gapsSaveData: {},
  gapsUpdated: false,
  infoUpdated: false
});

export default initialGapsState;
