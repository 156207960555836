import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';

export const ContentWrapper = styled('div')<IToken>`
  width: 100%;
  padding: ${(props) => `${props.token.paddingLG}px`};

  .ant-tabs-nav {
    margin: unset;

    &::before {
      border-bottom: unset;
    }
  }
  .ant-table-content {
    border-radius: 10px;
  }
  .ant-table-thead tr .ant-table-cell {
    white-space: nowrap;
  }

  .ant-tabs {
    & > .ant-tabs-nav .ant-tabs-tab {
      border: unset;

      &:hover,
      &:focus,
      &:active {
        background: ${(props) => props.token.colorPrimaryBgHover};
        color: ${(props) => props.token.colorPrimaryText};
      }
    }

    .ant-tabs-nav-list {
      gap: 10px;
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab {
      font-weight: bold;
      border-radius: 8px 8px 0 0;
      padding: 8px 16px;
      margin: unset;

      &.ant-tabs-tab-active {
        background: ${(props) => props.token.colorPrimaryBgHover};

        .ant-tabs-tab-btn {
          color: ${(props) => props.token.colorPrimaryText};
        }
      }
    }
  }
`;
