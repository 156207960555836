import styled from 'styled-components';
import { Button } from 'antd';
import TextBox from 'components/TextBox';
import { IToken } from 'store/ducks/globalTypes';

export const ButtonElement = styled(Button)`
  width: 350px;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
  margin-bottom: 16px;
`;

export const ReportingRow = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const ButtonDiv = styled('div')`
  margin: 16px;
`;

export const TextBoxContainer = styled(TextBox)<IToken>`
  margin-left: ${(props) => `${props.token.marginLG}px`};
`;

export const TextBoxP = styled('p')<IToken>`
  font-size: ${(props) => `${props.token.fontSizeSM}px`};
`;

export const DNDElement = styled('div')`
  background-color: #f5f5f5;
  min-width: 200px;
  padding: 25px;
  margin: 0 15px;
`;

export const DNDRow = styled('div')`
  display: flex;
  justify-content: space-between;
  max-width: 300px;
`;
