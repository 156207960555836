import React from 'react';
import { theme, Typography } from 'antd';
import * as Styled from './Copyright.styled';

const { Text } = Typography;
const { useToken } = theme;

const Copyright: React.FC = () => {
  const { token } = useToken();

  return (
    <Styled.CopyrightContainer token={token}>
      <Text type='secondary'>
        {'Copyright © '}
        <Styled.StyledLinkButton
          token={token}
          type='link'
          href='https://zamirion.de/'
        >
          Zamirion
        </Styled.StyledLinkButton>
        {new Date().getFullYear()}
        {'.'}
      </Text>
    </Styled.CopyrightContainer>
  );
};

export default Copyright;
