import React from 'react';
import { theme } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import Helpers from 'utils/Helpers';
import { IAuditData, IEvidence } from 'store/ducks/Gaps/types';
import TextBox from '../TextBox';
import * as Styled from './TextBoxesContainer.styled';

const { useToken } = theme;

interface IContainerProps {
  data: IEvidence | IAuditData;
  descName: string;
}

export const TextBoxesContainer: React.FC<IContainerProps> = ({
  data,
  descName
}) => {
  const { token } = useToken();

  return (
    <Styled.Container token={token}>
      {!Helpers.isObjEmpty(data) ? (
        <>
          {Object.values(data).map((item: any) => (
            <TextBox key={uuidv4()} width={500}>
              {item[descName]}
            </TextBox>
          ))}
        </>
      ) : (
        <Styled.StyledTextBox token={token} width={500} />
      )}
    </Styled.Container>
  );
};
