import React from 'react';
import { StyledLogo } from 'components/Layouts/Layout.styled';
import * as Styled from 'components/Layouts/AuthLayout/AuthLayout.styled';

interface IAuthLayoutProps {
  children: JSX.Element;
}

const AuthLayout: React.FC<IAuthLayoutProps> = ({ children }) => (
  <Styled.StyledContainer>
    <StyledLogo />
    <Styled.FormContainer>{children}</Styled.FormContainer>
  </Styled.StyledContainer>
);

export default AuthLayout;
