import ApiService from 'utils/ApiCalls';
import { LanguageList, IFilters } from '../globalTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { notification } from 'antd';
import managementSlice from './managementSlice';
import Helpers from 'utils/Helpers';
import { ICategoryResult, IProductResult, IAuditFindingsResult } from './types';

interface IEntityProps {
  [key: string]: number;
}

const {
  set_draw_table,
  set_loading,
  set_filters_data,
  set_save_disabled,
  set_save_clicked,
  set_category,
  set_mgmt_audits,
  set_product
} = managementSlice.actions;

const fetchMgmtAudits = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getMgmtAudits(lang);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchMngmPrioritization = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getMngmPrioritization(lang);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchAuditSource = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getAuditSource(lang);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const setMgmtAudits =
  (mgmtAudits: IAuditFindingsResult[]) => async (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_mgmt_audits(mgmtAudits));
    } catch (error) {
      console.error(error);
    }
  };

const setCategory =
  (category: ICategoryResult[]) => async (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_category(category));
    } catch (error) {
      console.error(error);
    }
  };

const fetchCategory = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getCategory(lang);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
const setProduct =
  (product: IProductResult[]) => async (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_product(product));
    } catch (error) {
      console.error(error);
    }
  };

const fetchProduct = async (lang: LanguageList) => {
  try {
    const response = await ApiService.getProduct(lang);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const setDrawTable = (draw: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_draw_table(draw));
  } catch (error) {
    console.error(error);
  }
};

const setFilters = (lang: LanguageList) => async (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    const response = await ApiService.getFilters(lang);

    let fetchedFilters: IFilters[] = [];

    if (response.data && !Helpers.isObjEmpty(response.data)) {
      fetchedFilters = Object.keys(response.data).map((key) => {
        let filterName, filterOptions;

        filterName = key.charAt(0).toUpperCase() + key.slice(1);
        filterOptions = response.data[key];

        const options = filterOptions.map((option: string | IEntityProps) => {
          const value =
            typeof option === 'string' ? option : Object.keys(option)[0];

          return {
            value: value,
            label: value
          };
        });

        return {
          filter: filterName,
          options: options
        };
      });
    }

    dispatch(set_filters_data(fetchedFilters));
  } catch (error) {
    console.error(error);
  }
};

const applyFiltersApi = async (lang: LanguageList) => {
  try {
    const response = await ApiService.applyFilters(lang);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const setSaveDisabled = (disabled: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_save_disabled(disabled));
  } catch (error) {
    console.error(error);
  }
};

const setSaveClicked = (saveClicked: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_save_clicked(saveClicked));
  } catch (error) {
    console.error(error);
  }
};

const deleteAuditFindings = async (params: { afg_id: number }) => {
  try {
    const response = await ApiService.removeAuditFindings(params);
    const { status, data } = response;
    notification.success({
      message: status,
      description: data.message
    });
  } catch (error) {
    console.error(error);
  }
};

export default {
  fetchMgmtAudits,
  fetchMngmPrioritization,
  fetchAuditSource,
  fetchCategory,
  fetchProduct,
  setDrawTable,
  setFilters,
  applyFiltersApi,
  setSaveClicked,
  deleteAuditFindings,
  setCategory,
  setProduct,
  setMgmtAudits
};
