import React, { useEffect, useCallback } from 'react';
import { Col, Row, theme } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import TextBox from 'components/TextBox';
import CustomSelect from 'components/CustomSelect';
import CustomTable from '../CustomTable';
import NestedTable from '../NestedTable';
import GapsAuditFindingsTable from '../GapsAuditFindingsTable';
import { gapsOp } from 'store/ducks/Gaps';
import { IAuditData, IAudits } from 'store/ducks/Gaps/types';
import { AuditFindingColumns } from 'constants/tableColumns';
import * as Styled from './AuditFindingsTable.styled';

const { useToken } = theme;

const AuditFindingsTable: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const auditFindingColumns = AuditFindingColumns();

  const {
    lang,
    drawTable,
    auditFindingMapping,
    assignedAuditFindingsUpdated,
    loading
  } = useSelector((state: RootState) => ({
    lang: state.global.lang,
    drawTable: state.complianceEst.drawTable,
    auditFindingMapping: state.gaps.auditFindingMapping,
    assignedAuditFindingsUpdated: state.gaps.assignedAuditFindingsUpdated,
    loading: state.gaps.loading
  }));

  const fetchDeleteAudit = async (audit: IAudits) => {
    dispatch(gapsOp.setAssignedAuditFindingsUpdated(false));
    try {
      const params = { afg_id: audit.afg_id };
      await gapsOp.deleteAudits(params);
      dispatch(gapsOp.setAssignedAuditFindingsUpdated(true));
    } catch (error) {
      console.error('Failed to fetch audits:', error);
    }
  };

  const dataSource = auditFindingMapping.length
    ? Object.values(auditFindingMapping[0])[0]
    : [];

  const expandedRow = useCallback(
    (key: string) => {
      const record = dataSource.find((item: IAuditData) => item.id === key);

      if (!record) return null;

      const nestedTableColumns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
          render: (id: number) => <TextBox width={100}>{id}</TextBox>
        },
        {
          title: 'CLN Req',
          dataIndex: 'value',
          key: 'value',
          width: 500,
          render: (value: string) => (
            <TextBox width={500} level={record.level}>
              {value}
            </TextBox>
          )
        },
        {
          title: 'Category',
          dataIndex: 'category',
          key: 'category',
          width: 100,
          render: (category: string) => (
            <CustomSelect options={[]} defaultValue={category} disabled />
          )
        },
        {
          title: 'Aligned Audit findings',
          dataIndex: 'AUDITS',
          key: 'AUDITS',
          width: 470,
          render: (audits: IAudits[]) => (
            <Styled.AuditsContainer token={token}>
              {audits.length ? (
                audits.map((audit: IAudits) => (
                  <Styled.StyledAssignedFindings key={audit.afg_id}>
                    <TextBox width={470}>{audit.afg_desc}</TextBox>
                    <Styled.StyledDeleteDiv>
                      <Styled.StyledButton
                        type='primary'
                        token={token}
                        icon={<DeleteOutlined />}
                        onClick={() => fetchDeleteAudit(audit)}
                      />
                    </Styled.StyledDeleteDiv>
                  </Styled.StyledAssignedFindings>
                ))
              ) : (
                <Styled.StyledTextBox width={500} token={token} />
              )}
            </Styled.AuditsContainer>
          )
        }
      ];

      const filteredDataSource = dataSource.filter(
        (item: IAuditData) => item.id === key
      );

      return (
        <NestedTable
          className='nested-table'
          columns={nestedTableColumns}
          dataSource={filteredDataSource.map((item: { id: any }) => ({
            ...item,
            key: item.id
          }))}
          pagination={false}
        />
      );
    },
    [dataSource, token, fetchDeleteAudit]
  );

  useEffect(() => {
    if (assignedAuditFindingsUpdated) {
      gapsOp
        .fetchAuditFindingsMapping(lang)
        .then((response) => {
          dispatch(gapsOp.setAuditFindingsMapping(response));
        })
        .catch((error) => {
          console.error('Failed to fetch audit findings mapping:', error);
        });
    }
    dispatch(gapsOp.setAssignedAuditFindingsUpdated(false));
  }, [assignedAuditFindingsUpdated, dispatch, lang]);

  return (
    <Row>
      {dataSource.length ? (
        <>
          <Col lg={18}>
            <Styled.StyledCustomTable
              drawTable={drawTable}
              token={token}
              columns={auditFindingColumns}
              expandable={{
                expandedRowKeys: dataSource.map((item: IAuditData) => item.id),
                expandedRowRender: (record: IAuditData) =>
                  expandedRow(record.id)
              }}
              dataSource={dataSource.map((item: IAuditData) => ({
                ...item,
                key: item.id
              }))}
              itemsCount={dataSource.length}
              scroll={{ x: 1000 }}
              loading={loading}
            />
          </Col>
          <Col lg={6}>
            <GapsAuditFindingsTable />
          </Col>
        </>
      ) : (
        <>
          <Col lg={18}>
            <CustomTable
              drawTable={drawTable}
              columns={auditFindingColumns}
              itemsCount={0}
              dataSource={[]}
              pagination={false}
            />
          </Col>
          <Col lg={6}>
            <GapsAuditFindingsTable />
          </Col>
        </>
      )}
    </Row>
  );
};

export default AuditFindingsTable;
