import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, CommentOutlined } from '@ant-design/icons';
import { Popover, theme, message, Popconfirm } from 'antd';
import { CommentItem, CommentTable } from 'store/ducks/ComplienceEst/types';
import { RootState } from 'store/configureStore';
import { solutionsOp } from 'store/ducks/Solutions';
import { ISolution } from 'store/ducks/Solutions/types';
import CustomTextarea from '../CustomTextarea';
import CustomIconButton from '../CustomIconButton';
import CommentSection from '../CommentSection';
import * as Styled from './TextAreaBoxSolutions.styled';

interface ITextAreaBoxProps {
  solutions: ISolution;
  solId: number | string;
  onDelete?: (id: string | number) => void;
}

interface IPopoverState {
  [id: number | string]: boolean;
}

const cancel = () => {
  message.error('Solution was not deleted');
};

const { useToken } = theme;

export const TextAreaBoxSolutions: React.FC<ITextAreaBoxProps> = ({
  solutions,
  solId
}) => {
  const { token } = useToken();
  const dispatch = useDispatch();

  const { commentsData, lang } = useSelector((state: RootState) => ({
    commentsData: state.complianceEst.commentsData,
    lang: state.global.lang
  }));

  const [popoverState, setPopoverState] = useState<IPopoverState>({});

  const handleDeleteClick = () => {
    solutionsOp
      .deleteSolution(Object.keys(solutions)[0])
      .then(() => solutionsOp.fetchSolutions(lang));
  };

  const handleSolutionChange = (value: string) => {
    dispatch(
      solutionsOp.editSolutionValue(solId, solutions, 'sol_desc', value)
    );
  };

  const hide = (id: number | string) => {
    if (!id) return;

    setPopoverState((prevState) => ({
      ...prevState,
      [id]: false
    }));
  };

  const drawComments = () => {
    const filteredComments = commentsData.length
      ? commentsData.filter(
          (commentsDataItem: CommentItem) =>
            commentsDataItem.res_table_pk_ref === solutions.sol_id
        )
      : [];

    return (
      <>
        <CommentSection
          reqId={solutions.sol_id}
          data={filteredComments}
          tableRef={CommentTable.SOLUTIONS}
        />
        <a onClick={() => hide(Number(solutions.sol_id))}>Close</a>
      </>
    );
  };

  const handleOpenChange = (id: number | string) => {
    if (!id) return;

    setPopoverState((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  return (
    <Styled.TextAreaElementsContainer>
      <Styled.TextAreaElementsContainer>
        <CustomTextarea
          key={solId}
          placeholder='Type Solution'
          defaultValue={solutions.sol_desc}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleSolutionChange(e.target.value)
          }
        />
        <Styled.StyledButtonSection token={token}>
          <Popover
            placement='leftBottom'
            trigger='click'
            content={drawComments}
            title='Comments'
            open={!!(solId && popoverState[solId])}
            onOpenChange={() => handleOpenChange(solId)}
          >
            <CustomIconButton
              type='primary'
              shape='circle'
              icon={<CommentOutlined />}
              size='small'
              cursor='pointer'
            />
          </Popover>
          <Popconfirm
            title='Delete the solution'
            description='Are you sure to delete this solution?'
            onConfirm={handleDeleteClick}
            onCancel={cancel}
            okText='Yes'
            cancelText='No'
          >
            <Styled.StyledCustomIconButton
              token={token}
              type='primary'
              shape='circle'
              icon={<DeleteOutlined />}
              cursor='pointer'
              size='small'
            />
          </Popconfirm>
        </Styled.StyledButtonSection>
      </Styled.TextAreaElementsContainer>
    </Styled.TextAreaElementsContainer>
  );
};
