import { gapsOp } from 'store/ducks/Gaps';
import { GapTabs, IAuditFindings, IEvidenceData } from 'store/ducks/Gaps/types';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import { CommentTable } from 'store/ducks/ComplienceEst/types';
import { LanguageList } from 'store/ducks/globalTypes';

const gapsRequests = (dispatch: any, lang: LanguageList) => ({
  [GapTabs.AUDIT_FINDINGS]: () => {
    return gapsOp.fetchAuditFindingsMapping(lang).then((response) => {
      dispatch(
        gapsOp.setAuditFindingsMapping(
          response.map((item: IAuditFindings[]) => ({ 0: item }))
        )
      );
    });
  },
  [GapTabs.EVIDENCES]: () => {
    return gapsOp
      .fetchEvidenceMapping(lang)
      .then((response) => {
        dispatch(
          gapsOp.setEvidenceData(
            response.map((item: IEvidenceData[]) => ({ 0: item }))
          )
        );
      })
      .catch((error) => {
        console.error('Error fetching evidence mapping:', error);
      });
  },
  [GapTabs.GAPS_DESC]: () => {
    return gapsOp.fetchGapsDescription(lang).then((response) => {
      const result = response?.map((item: any[]) => ({
        0: item
      }));

      complianceEstOp.fetchComments(CommentTable.GAPS).then((data) => {
        dispatch(complianceEstOp.setCommentsData(data));
      });
      dispatch(gapsOp.setGapsDescription(result));
      dispatch(gapsOp.setGapsTableData(result));
    });
  },
  [GapTabs.ADDITIONAL_INFO]: () => {
    return gapsOp.fetchAdditionalInfo(lang).then((response) => {
      const result = response?.map((item: any[]) => ({
        0: item
      }));

      dispatch(gapsOp.setAdditionalInfo(result));
    });
  }
});

export default gapsRequests;
