import React from 'react';
import { theme } from 'antd';
import * as Styled from './CustomIconButton.styled';

const { useToken } = theme;

interface ICustomCheckboxProps {
  [rest: string]: any;
  onClick?: (index: any) => void;
}

export const CustomIconButton: React.FC<ICustomCheckboxProps> = ({
  onClick,
  ...rest
}) => {
  const { token } = useToken();

  return <Styled.StyledButton token={token} onClick={onClick} {...rest} />;
};
