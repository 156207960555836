import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, theme } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { RootState } from 'store/configureStore';
import TextBox from 'components/TextBox';
import CustomSelect from 'components/CustomSelect';
import CustomTable from '../CustomTable';
import NestedTable from '../NestedTable';
import {
  evidenceColumns,
  evidenceMockedData
} from 'constants/evidencesTableColumn';
import i18n from 'components/translation/i18n';
import { gapsOp } from 'store/ducks/Gaps';
import {
  IEvidence,
  IEvidenceMapping,
  IEvidenceData
} from 'store/ducks/Gaps/types';
import * as Styled from './EvidenceMappingTable.styled';
const { useToken } = theme;

const EvidenceMappingTable: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const i18nInitialization = i18n();

  const { lang, drawTable, assignedEvidencesUpdated, evidenceMapping } =
    useSelector((state: RootState) => ({
      lang: state.global.lang,
      drawTable: state.complianceEst.drawTable,
      assignedEvidencesUpdated: state.gaps.assignedEvidencesUpdated,
      evidenceMapping: state.gaps.evidenceMapping
    }));

  const fetchDeleteEvidence = async (evidence: {
    evd_desc: string;
    id: number;
  }) => {
    dispatch(gapsOp.setAssignedEvidencesUpdated(false));

    try {
      const params = { evd_id: evidence.id };

      await gapsOp.deleteEvidences(params);
      dispatch(gapsOp.setAssignedEvidencesUpdated(true));
    } catch (error) {
      console.error('Failed to fetch evidences:', error);
    }
  };

  const tableColumns = [
    {
      title: i18nInitialization.t('ID'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('CLN_REQ'),
      width: 500,
      render: () => <TextBox width={500} />
    },
    {
      title: i18nInitialization.t('CATEGORY'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('EVIDENCE_REF'),
      width: 500,
      render: () => <TextBox width={500} />
    }
  ];
  const dataSource = evidenceMapping.length
    ? Object.values(evidenceMapping[0])[0]
    : [];

  const expandedRow = (record: IEvidenceData) => {
    const nestedTableColumns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        render: (id: number) => <TextBox width={100}>{id}</TextBox>
      },
      {
        title: 'CLN Req',
        dataIndex: [],
        key: 'value',
        width: 500,
        render: (dataObj: IEvidenceMapping) => (
          <TextBox width={500} level={dataObj.level}>
            {dataObj.value}
          </TextBox>
        )
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        width: 100,
        render: (category: string) => (
          <CustomSelect options={[]} defaultValue={category} disabled />
        )
      },
      {
        title: 'Evidence ref',
        dataIndex: 'EVIDENCES',
        key: 'EVIDENCES',
        width: 470,
        render: (evidences: IEvidence) => {
          return (
            <Styled.EvidenceContainer token={token}>
              {Object.keys(evidences).length !== 0 ? (
                <>
                  {Object.values(evidences).map(
                    (evidence: { evd_desc: string; id: number }) => (
                      <Styled.StyledAssignedEvidences key={evidence.id}>
                        <TextBox width={470}>{evidence.evd_desc}</TextBox>
                        <Styled.StyledDeleteDiv>
                          <Styled.StyledButton
                            type='primary'
                            token={token}
                            icon={<DeleteOutlined />}
                            onClick={() => fetchDeleteEvidence(evidence)}
                          />
                        </Styled.StyledDeleteDiv>
                      </Styled.StyledAssignedEvidences>
                    )
                  )}
                </>
              ) : (
                <Styled.StyledTextBox token={token} width={500} />
              )}
            </Styled.EvidenceContainer>
          );
        }
      }
    ];

    return (
      <NestedTable
        key={record[0][0].id}
        className='nested-table'
        columns={nestedTableColumns}
        dataSource={Object.values(record)[0] as IEvidenceMapping[]}
        pagination={false}
      />
    );
  };

  useEffect(() => {
    if (assignedEvidencesUpdated) {
      gapsOp.fetchEvidenceMapping(lang).then((response) => {
        dispatch(
          gapsOp.setEvidenceData(
            response.map((item: IEvidenceMapping[]) => ({ 0: item }))
          )
        );
      });
    }
    dispatch(gapsOp.setAssignedEvidencesUpdated(false));
  }, [assignedEvidencesUpdated]);

  // useEffect(() => {
  // gapsOp.fetchEvidenceMapping(lang).then((response) => {
  //   dispatch(
  //     gapsOp.setEvidenceData(response.map((item: IEvidenceData[]) => ({ 0: item })))
  //   )
  // })
  // }, []);

  useEffect(() => {
    // console.log(evidenceMapping, 'evidenceMapping');
  }, [evidenceMapping]);

  return (
    <Row>
      {dataSource.length > 0 ? (
        <>
          <Col lg={18}>
            <Styled.StyledCustomTable
              token={token}
              columns={tableColumns}
              dataSource={dataSource}
              itemsCount={dataSource.length}
              expandable={{
                expandedRowKeys: dataSource.map((item: IEvidenceMapping) => {
                  // console.log('Item:', item);
                  // console.log('Item ID:', item.id);
                  return item.id;
                }),
                expandedRowRender: (record: IEvidenceData) => {
                  // console.log('Record in expandedRowRender:', record);
                  return expandedRow(record);
                }
              }}
              scroll={{ x: 1000 }}
            />
          </Col>
          <Col lg={6}>
            <CustomTable
              drawTable={drawTable}
              columns={evidenceColumns}
              dataSource={drawTable ? evidenceMockedData : []}
              itemsCount={evidenceMockedData.length}
              pagination={false}
            />
          </Col>
        </>
      ) : (
        <>
          <Col lg={18}>
            <CustomTable
              drawTable={drawTable}
              columns={tableColumns}
              itemsCount={0}
              dataSource={[]}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default EvidenceMappingTable;
