import { IReportingState } from './types';

const initialReportingState = (): IReportingState => ({
  error: null,
  loading: false,
  drawTable: false,
  filtersData: [],
  saveDisabled: true,
  saveClicked: false
});

export default initialReportingState;
