import { Dispatch } from '@reduxjs/toolkit';
import { notification } from 'antd';
import clientReqSlice from './clientReqSlice';
import {
  ITableFilters,
  ICheckedAurReqs,
  IClientReqToBeAdded,
  INewClientReqs,
  IRegulatoryReq,
  IClientReq,
  IApplicability,
  ICategory
} from './types';
import ApiService from 'utils/ApiCalls';
import { LanguageList } from '../globalTypes';

const {
  set_applied_filters_result,
  set_merged_elements,
  set_copied_elements,
  set_client_reqs,
  set_new_client_reqs,
  set_checked_elements_toBe_merged,
  set_checked_elements_toBe_copied,
  set_checked_aur_reqs,
  set_table_filters,
  set_filtered_data,
  set_is_app_statuses,
  update_client_req,
  reset_new_client_reqs,
  set_updated,
  set_loading
} = clientReqSlice.actions;

const setAppliedFilersResult =
  (data: IRegulatoryReq[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_applied_filters_result(data));
    } catch (error) {
      console.error(error);
    }
  };

const setCheckedElementsToBeMerged =
  (elements: number[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_checked_elements_toBe_merged(elements));
    } catch (error) {
      console.error(error);
    }
  };

const setCheckedElementsToBeCopied =
  (elements: number[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_checked_elements_toBe_copied(elements));
    } catch (error) {
      console.error(error);
    }
  };

const setMergedElements = (elements: number[]) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_merged_elements(elements));
  } catch (error) {
    console.error(error);
  }
};

const setCopiedElements = (elements: number[]) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_copied_elements(elements));
  } catch (error) {
    console.error(error);
  }
};

const setFilteredData =
  (filteredData: IRegulatoryReq[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_filtered_data(filteredData));
    } catch (error) {
      console.error(error);
    }
  };

const setCheckedAurReqs = (checkedAurReqs: number) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_checked_aur_reqs(checkedAurReqs));
  } catch (error) {
    console.error(error);
  }
};

const setClientReqs =
  (clientReqData: INewClientReqs) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_client_reqs(clientReqData));
    } catch (error) {
      console.error(error);
    }
  };

const setNewClientReqs =
  (updatedAurReqs: IClientReqToBeAdded[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());

      updatedAurReqs.map((newClientReq: IClientReqToBeAdded) =>
        dispatch(set_new_client_reqs(newClientReq))
      );
    } catch (error) {
      console.error(error);
    }
  };

const setTableFilter = (filter: ITableFilters) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_table_filters(filter));
  } catch (error) {
    console.error(error);
  }
};

const setApplicabilityStatuses =
  (data: ICheckedAurReqs) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_is_app_statuses(data));
    } catch (error) {
      console.error(error);
    }
  };

const updateClientReq =
  (value: IClientReq, clnReqKey: string, aurReqId: number | string) =>
  (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(update_client_req({ value, clnReqKey, aurReqId }));
    } catch (error) {
      console.error(error);
    }
  };

const deleteClientReq =
  (data: { bru_id: number | string }, lang: LanguageList) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());

      const response = await ApiService.deleteClnReq(data, lang);

      notification.success({
        message: response.status,
        description: 'Client Requirement successfully deleted'
      });
    } catch (error) {
      console.error(error);
    }
  };

const saveClientReqs =
  (data: IClientReq[], lang: LanguageList) => async (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());

      const response = await ApiService.addClientReq(data, lang);

      dispatch(set_updated(true));
      dispatch(reset_new_client_reqs());

      notification.success({
        message: response.status,
        description: 'Client Requirements successfully saved'
      });
    } catch (error) {
      console.error(error);
    }
  };

const updateApplicability =
  (data: IApplicability[], lang: LanguageList) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());

      const response = await ApiService.changeApplicability(data, lang);

      dispatch(set_updated(true));

      notification.success({
        message: response.status,
        description: response.data.message
      });
    } catch (error) {
      console.error(error);
    }
  };

const updateCategory =
  (data: ICategory[], lang: LanguageList) => async (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());

      const response = await ApiService.changeCategory(data, lang);

      dispatch(set_updated(true));

      notification.success({
        message: response.status,
        description: response.data.message
      });
    } catch (error) {
      console.error(error);
    }
  };

const resetUpdated = () => (dispatch: Dispatch) => {
  dispatch(set_updated(false));
};

export default {
  setAppliedFilersResult,
  setCheckedElementsToBeMerged,
  setCheckedElementsToBeCopied,
  setTableFilter,
  setFilteredData,
  setApplicabilityStatuses,
  deleteClientReq,
  setClientReqs,
  setNewClientReqs,
  setMergedElements,
  setCopiedElements,
  setCheckedAurReqs,
  updateClientReq,
  saveClientReqs,
  updateApplicability,
  updateCategory,
  resetUpdated
};
