import React from 'react';
import TextBox from 'components/TextBox';
import i18n from 'components/translation/i18n';

const SolutionColumns = () => {
  const i18nInitialization = i18n();

  const solutionColumns = [
    {
      title: i18nInitialization.t('CATEGORY'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('CLN_REQ_ID'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('CLN_REQ'),
      width: 500,
      render: () => <TextBox width={500} />
    },
    {
      title: i18nInitialization.t('GAPS'),
      width: 335,
      render: () => <TextBox width={335} />
    },
    {
      title: i18nInitialization.t('AUDIT_FINDINGS'),
      width: 335,
      render: () => <TextBox width={335} />
    },
    {
      title: i18nInitialization.t('SOLUTION_ID'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('SOLUTIONS'),
      width: 376,
      render: () => <TextBox width={376} />
    },
    {
      title: i18nInitialization.t('SOLUTION_TYPE'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('EVIDENCE_REQUIRED'),
      width: 150,
      render: () => <TextBox width={150} />
    },
    {
      title: i18nInitialization.t('APPROVER'),
      width: 150,
      render: () => <TextBox width={150} />
    },
    {
      title: i18nInitialization.t('SOLUTION_STATUS'),
      width: 150,
      render: () => <TextBox width={150} />
    }
  ];
  return solutionColumns;
};

const TaskColumns = () => {
  const i18nInitialization = i18n();

  const taskColumns = [
    {
      title: i18nInitialization.t('SOLUTIONS'),
      width: 500,
      render: () => <TextBox width={500} />
    },
    {
      title: i18nInitialization.t('TASK_ID'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('TASK_SHORT_NAME'),
      width: 330,
      render: () => <TextBox width={330} />
    },
    {
      title: i18nInitialization.t('TASK_DEFINITION'),
      width: 366,
      render: () => <TextBox width={366} />
    },
    {
      title: i18nInitialization.t('TASK_TYPE'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('TASK_STATUS'),
      width: 120,
      render: () => <TextBox width={120} />
    },
    {
      title: i18nInitialization.t('OWNER'),
      width: 150,
      render: () => <TextBox width={150} />
    },
    {
      title: i18nInitialization.t('DUE_DATE'),
      width: 128,
      render: () => <TextBox width={128} />
    }
  ];
  return taskColumns;
};

const AuditFindingColumns = () => {
  const i18nInitialization = i18n();

  const auditFindingColumns = [
    {
      title: i18nInitialization.t('ID'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('CLN_REQ'),
      width: 500,
      render: () => <TextBox width={500} />
    },
    {
      title: i18nInitialization.t('CATEGORY'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('ALIGNED_AUDIT_FINDINGS'),
      width: 500,
      render: () => <TextBox width={500} />
    }
  ];
  return auditFindingColumns;
};

const EvidenceDefinitionColumns = () => {
  const i18nInitialization = i18n();

  const evidenceDefinitionColumns = [
    {
      title: i18nInitialization.t('CLN_REQ'),
      width: 500,
      render: () => <TextBox width={500} />
    },
    {
      title: i18nInitialization.t('SOLUTION_DEFINITION'),
      width: 500,
      render: () => <TextBox width={500} />
    },
    {
      title: i18nInitialization.t('SOLUTION_TYPE'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('EVIDENCE_ID'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('EVIDENCE_DEFINITION'),
      width: 376,
      render: () => <TextBox width={376} />
    },
    {
      title: i18nInitialization.t('EVIDENCE_FILE'),
      width: 350,
      render: () => <TextBox width={350} />
    },
    {
      title: i18nInitialization.t('APPROVER'),
      width: 150,
      render: () => <TextBox width={150} />
    },
    {
      title: i18nInitialization.t('APPROVAL'),
      width: 150,
      render: () => <TextBox width={150} />
    }
  ];
  return evidenceDefinitionColumns;
};

const GapAdditionalInfoColumns = () => {
  const i18nInitialization = i18n();

  const gapAdditionalInfoColumns = [
    {
      title: i18nInitialization.t('ID'),
      width: 80,
      render: () => <TextBox width={80} />
    },
    {
      title: i18nInitialization.t('GAPS'),
      width: 335,
      render: () => <TextBox width={335} />
    },
    {
      title: i18nInitialization.t('GAP_DUE_DATE'),
      width: 120,
      render: () => <TextBox width={120} />
    },
    {
      title: i18nInitialization.t('DUE_DATE_AUDIT'),
      width: 195,
      render: () => <TextBox width={195} />
    },
    {
      title: i18nInitialization.t('GAP_PRIORITIZATION'),
      width: 120,
      render: () => <TextBox width={120} />
    },
    {
      title: i18nInitialization.t('AUDIT_PRIORITIZATION'),
      width: 195,
      render: () => <TextBox width={195} />
    },
    {
      title: i18nInitialization.t('GAP_SOURCE'),
      width: 120,
      render: () => <TextBox width={120} />
    },
    {
      title: i18nInitialization.t('SOURCE_AUDIT'),
      width: 195,
      render: () => <TextBox width={195} />
    },
    {
      title: i18nInitialization.t('OWNER_NOTIFIED'),
      width: 100,
      render: () => <TextBox width={100} />
    },
    {
      title: i18nInitialization.t('GAP_OWNER'),
      width: 150,
      render: () => <TextBox width={150} />
    },
    {
      title: i18nInitialization.t('AUDIT_OWNER'),
      width: 195,
      render: () => <TextBox width={195} />
    }
  ];
  return gapAdditionalInfoColumns;
};

export {
  SolutionColumns,
  TaskColumns,
  AuditFindingColumns,
  GapAdditionalInfoColumns,
  EvidenceDefinitionColumns
};
