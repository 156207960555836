import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { Layout, theme } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
import { Content } from 'antd/es/layout/layout';
import HorizontalNavBar from 'components/NavigationBar/HorizontalNavBar';
import CustomSelect from 'components/CustomSelect';
import TitleBar from 'components/TitleBar';
import GoBackButton from 'components/GoBackButton';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import { RootState } from 'store/configureStore';
import { GapTabs } from 'store/ducks/Gaps/types';
import { clientReqOp } from 'store/ducks/ClientReq';
import { gapsOp } from 'store/ducks/Gaps';
import { CommentTable } from 'store/ducks/ComplienceEst/types';
import { solutionsOp } from 'store/ducks/Solutions';
import {
  IFilters,
  TitleListEN,
  TitleListDE,
  TitleListES,
  TitleListFR,
  TitleListIT,
  TitleListPT
} from 'store/ducks/globalTypes';
import { tasksOp } from 'store/ducks/Tasks';
import { evidencesOp } from 'store/ducks/Evidences';
import gapsRequests from 'constants/gapsRequests';
import filterNames from 'constants/filterNames';
import FilterNames from 'constants/filterNamesTranslations';
import {
  IAurReq,
  IClientReq,
  IClientReqToBeAdded,
  IRegulatoryReq
} from 'store/ducks/ClientReq/types';
import * as Styled from 'components/Layouts/Layout.styled';

const { Sider } = Layout;
const { useToken } = theme;

const FiltersParam = withDefault(ArrayParam, []);

interface IComplianceEstLayoutProps {
  activeTab?: string;
  activeKey?: string;
  children: JSX.Element;
}

const ComplianceEstLayout: React.FC<IComplianceEstLayoutProps> = ({
  activeTab,
  activeKey,
  children
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { token } = useToken();

  const [filtersvisible, setFiltersVisible] = useState(false);

  const toggleFilters = () => {
    setFiltersVisible(!filtersvisible);
  };

  const page = location.pathname.split('/').pop();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  const filterPlaceholder = FilterNames();

  const [query, setQuery] = useQueryParams({
    industry: FiltersParam,
    country: FiltersParam,
    module: FiltersParam,
    entity: FiltersParam,
    category: FiltersParam,
    product: FiltersParam,
    regulator: FiltersParam
  });

  const {
    lang,
    saveDisabled,
    drawTable,
    filtersData,
    appliedFiltersResult,
    checkedElementsToBeMerged,
    checkedElementsToBeCopied,
    mergedElements,
    copiedElements,
    gapsUpdated,
    infoUpdated
  } = useSelector((state: RootState) => ({
    lang: state.global.lang,
    drawTable: state.complianceEst.drawTable,
    saveDisabled: state.complianceEst.saveDisabled,
    filtersData: state.complianceEst.filtersData,
    appliedFiltersResult: state.clientReq.appliedFiltersResult,
    checkedElementsToBeMerged: state.clientReq.checkedElementsToBeMerged,
    checkedElementsToBeCopied: state.clientReq.checkedElementsToBeCopied,
    mergedElements: state.clientReq.mergedElements,
    copiedElements: state.clientReq.copiedElements,
    gapsUpdated: state.gaps.gapsUpdated,
    infoUpdated: state.gaps.infoUpdated
  }));

  const { scheme } = useSelector((state: RootState) => state.global);

  const findAurReqById = (aurReqId: number): IAurReq | undefined => {
    for (const key in appliedFiltersResult) {
      const aurReq = appliedFiltersResult[key].AUR_REQ;

      for (const aurChildKey in aurReq) {
        const aurReqObj = aurReq[aurChildKey as keyof typeof aurReq];

        if (aurReqObj.id === aurReqId) {
          return aurReqObj;
        }
      }
    }
    return undefined;
  };

  const mergeSelected = () => {
    const mergedAurReqs: IClientReqToBeAdded[] = [];
    const uniqueAurParentKeys: string[] = [];
    let elementsToBeMerged: number[] = checkedElementsToBeMerged;
    const newId = uuidv4();

    checkedElementsToBeMerged.forEach((aurReqId: number) => {
      const aurReq: IAurReq | undefined = findAurReqById(aurReqId);

      if (aurReq && !uniqueAurParentKeys.includes(aurReq.aur_parent_key)) {
        uniqueAurParentKeys.push(aurReq.aur_parent_key);
      }
    });

    for (const aurParentKey of uniqueAurParentKeys) {
      const aurReqs: IAurReq[] = checkedElementsToBeMerged
        .map((aurReqId) => findAurReqById(aurReqId))
        .filter(
          (aurReq): aurReq is IAurReq =>
            aurReq !== undefined && aurReq.aur_parent_key === aurParentKey
        );

      if (aurReqs.length > 1) {
        const mergedValues: string = aurReqs
          .map((aurReq) => aurReq.value)
          .join(' ');

        const { aur_child_key, aur_parent_key, id } = aurReqs[0];

        const newClientReq: IClientReq = {
          aur_child_key,
          aur_parent_key,
          value: mergedValues,
          merge_list: aurReqs.map((aurReq: IAurReq) => aurReq.id)
        };

        mergedAurReqs.push({
          aurReqId: id,
          updatedAurReqs: { [newId]: newClientReq }
        });
      } else {
        //TODO remove aurREq id from mergedAurReqs if i has nothing to be merged with
        elementsToBeMerged = checkedElementsToBeMerged.filter(
          (el: number) => el !== aurReqs[0].id
        );
      }
    }

    dispatch(clientReqOp.setNewClientReqs(mergedAurReqs));
    dispatch(clientReqOp.setMergedElements(elementsToBeMerged));
    dispatch(clientReqOp.setCheckedElementsToBeMerged([]));
  };

  const copySelected = () => {
    const newId = uuidv4();

    const selectedElementsToCopy = checkedElementsToBeCopied.map(
      (aurReqId: number) => {
        const dataObj = appliedFiltersResult.find((item: IRegulatoryReq) =>
          Object.values(item.AUR_REQ).find(
            (aurReqObj: IAurReq) => aurReqObj.id === aurReqId
          )
        );

        const { aur_parent_key, aur_child_key, value } =
          dataObj && Object.values(dataObj.AUR_REQ)[0];

        return {
          aurReqId,
          updatedAurReqs: { [newId]: { aur_parent_key, aur_child_key, value } }
        };
      }
    );

    dispatch(clientReqOp.setNewClientReqs(selectedElementsToCopy));
    dispatch(clientReqOp.setCopiedElements(checkedElementsToBeCopied));
    dispatch(clientReqOp.setCheckedElementsToBeCopied([]));
  };

  const copyAll = () => {
    let selectedElementsToCopy: IClientReqToBeAdded[] = [];
    let idsList: number[] = [];
    const newId = uuidv4();

    Object.values(appliedFiltersResult).map((regReq: IRegulatoryReq) =>
      Object.values(regReq.AUR_REQ).map((aurReq: IAurReq) => {
        const { id, aur_parent_key, aur_child_key, value } = aurReq;

        idsList.push(id);

        return selectedElementsToCopy.push({
          aurReqId: id,
          updatedAurReqs: { [newId]: { aur_parent_key, aur_child_key, value } }
        });
      })
    );

    dispatch(clientReqOp.setCopiedElements(idsList));
    dispatch(clientReqOp.setCheckedElementsToBeCopied([]));
    dispatch(clientReqOp.setNewClientReqs(selectedElementsToCopy));
  };

  const handleFilterChange = (value: string[], item: string) => {
    setQuery((latestQuery: any) => ({
      ...latestQuery,
      [item.toLowerCase()]: value
    }));
  };

  const applyFilters = () => {
    switch (page) {
      case 'clientReq':
        complianceEstOp.applyFiltersApi(lang).then((data) => {
          dispatch(clientReqOp.setAppliedFilersResult(data));
        });
        complianceEstOp
          .fetchComments(CommentTable.BUSINESS_RULES)
          .then((data) => {
            dispatch(complianceEstOp.setCommentsData(data));
          });

        break;
      case 'gapsAndAuditFindings':
        if (activeTab) {
          const gapsReqs = gapsRequests(dispatch, lang);
          switch (activeTab) {
            case GapTabs.AUDIT_FINDINGS:
              dispatch(gapsOp.setAssignedAuditFindingsUpdated(false));
              break;
            case GapTabs.EVIDENCES:
              dispatch(gapsOp.setAssignedEvidencesUpdated(false));
              break;
            case GapTabs.GAPS_DESC:
              dispatch(gapsOp.setGapsUpdated(false));
              break;
            case GapTabs.ADDITIONAL_INFO:
              dispatch(gapsOp.setInfoUpdated(false));
              break;
            default:
              return;
          }

          gapsReqs[activeTab as GapTabs]();
        }

        break;
      case 'solutionDefinition':
        solutionsOp.fetchSolutions(lang).then((data) => {
          dispatch(solutionsOp.setSolutions(data?.flat()));
        });

        complianceEstOp.fetchComments(CommentTable.SOLUTIONS).then((data) => {
          dispatch(complianceEstOp.setCommentsData(data));
        });

        break;
      case 'tasksDefinition':
        tasksOp.fetchTasks(lang).then((data) => {
          dispatch(tasksOp.setTasks(data?.flat()));
        });

        complianceEstOp.fetchComments(CommentTable.TASKS).then((data) => {
          dispatch(complianceEstOp.setCommentsData(data));
        });

        break;

      case 'evidenceDefinition':
        evidencesOp.fetchEvidences(lang).then((data) => {
          dispatch(evidencesOp.setEvidences(data?.flat()));
        });

        complianceEstOp.fetchComments(CommentTable.EVIDENCES).then((data) => {
          dispatch(complianceEstOp.setCommentsData(data));
        });

        break;

      default:
        return;
    }

    dispatch(complianceEstOp.setDrawTable(true));
  };

  const getFilterObj = (item: string): IFilters[] => {
    return filtersData.length > 0
      ? filtersData.filter((obj) => obj.filter === item)
      : [];
  };

  const handleSave = () => {
    dispatch(complianceEstOp.setSaveClicked(true));
  };

  const handleClientReqSave = () => {
    dispatch(complianceEstOp.setSaveClicked(true));
  };

  useEffect(() => {
    dispatch(complianceEstOp.setFilters(lang));
  }, []);

  useEffect(() => {
    dispatch(complianceEstOp.setDrawTable(false));
  }, [activeTab, activeKey]);

  useEffect(() => {
    if (gapsUpdated || infoUpdated) {
      applyFilters();
      dispatch(gapsOp.setGapsUpdated(false));
      dispatch(gapsOp.setInfoUpdated(false));
    }
  }, [gapsUpdated, infoUpdated]);

  return (
    <Styled.StyledLayout token={token} filtersvisible={filtersvisible}>
      <TitleBar
        title={(() => {
          switch (lang) {
            case 'en':
              return TitleListEN.COMP_EST;
            case 'de':
              return TitleListDE.COMP_EST;
            case 'es':
              return TitleListES.COMP_EST;
            case 'fr':
              return TitleListFR.COMP_EST;
            case 'it':
              return TitleListIT.COMP_EST;
            case 'pt':
              return TitleListPT.COMP_EST;
            default:
              return TitleListEN.COMP_EST;
          }
        })()}
      />
      <Styled.StyledHeader token={token}>
        <GoBackButton />
      </Styled.StyledHeader>
      <Styled.HorizontalLayout theme={scheme}>
        <HorizontalNavBar />
      </Styled.HorizontalLayout>

      <Styled.StyledLayout token={token} filtersvisible={filtersvisible}>
        <Layout>
          <Content>{children}</Content>
        </Layout>
        <Sider>
          <Styled.OpenCloseButton type='primary' onClick={toggleFilters}>
            {filtersvisible ? <CloseOutlined /> : <CheckOutlined />}
          </Styled.OpenCloseButton>
          {filtersvisible && (
            <Styled.FilterContainer>
              {filterNames.map((item: string, index: number) => (
                <CustomSelect
                  key={item}
                  mode='multiple'
                  placeholder={filterPlaceholder[index]}
                  onChange={(value: string[]) =>
                    handleFilterChange(value, item)
                  }
                  options={
                    getFilterObj(item).length !== 0 &&
                    getFilterObj(item)[0]?.options
                  }
                  defaultValue={queryParams[item.toLowerCase()]}
                  filters
                />
              ))}
              <Styled.StyledButton type='primary' onClick={applyFilters}>
                Display
              </Styled.StyledButton>
              {drawTable &&
                (page === 'clientReq' ? (
                  <>
                    <Styled.StyledButton
                      type='primary'
                      onClick={mergeSelected}
                      disabled={checkedElementsToBeMerged.length <= 1}
                    >
                      <span>Merge</span>
                      <span>selected</span>
                    </Styled.StyledButton>
                    <Styled.StyledButton
                      type='primary'
                      onClick={copySelected}
                      disabled={!checkedElementsToBeCopied.length}
                    >
                      <span>Copy</span>
                      <span>selected</span>
                    </Styled.StyledButton>
                    <Styled.StyledButton
                      type='primary'
                      disabled={
                        !!(copiedElements.length || mergedElements.length)
                      }
                      onClick={copyAll}
                    >
                      Copy All
                    </Styled.StyledButton>
                    <Styled.StyledButton
                      type='primary'
                      disabled={saveDisabled}
                      onClick={handleClientReqSave}
                    >
                      Save
                    </Styled.StyledButton>
                  </>
                ) : (
                  <Styled.StyledButton
                    type='primary'
                    disabled={saveDisabled}
                    onClick={handleSave}
                  >
                    Save
                  </Styled.StyledButton>
                ))}
            </Styled.FilterContainer>
          )}
        </Sider>
      </Styled.StyledLayout>
    </Styled.StyledLayout>
  );
};

export default ComplianceEstLayout;
