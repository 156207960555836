import React from 'react';
import TextBox from 'components/TextBox';
import TestDragAndDrop from 'components/DragAndDrop/Audits';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import i18n from 'components/translation/i18n';

const auditFindingColumns = () => {
  const i18nInitialization = i18n();
  const { drawTable } = useSelector((state: RootState) => ({
    drawTable: state.complianceEst.drawTable
  }));

  const auditFindingColumn = [
    {
      title: i18nInitialization.t('NOT_ALIGNED_AUDIT_FINDINGS'),
      dataIndex: 'notAlignedAudit',
      key: 'notAlignedAudit',
      render: (notAlignedAudit: string, record: any, index: number) => {
        if (index < 1) {
          return (
            drawTable && (
              <TextBox>
                <TestDragAndDrop />
              </TextBox>
            )
          );
        }
        return null;
      },
      onCell: () => {
        return { rowSpan: 3 };
      }
    }
  ];
  return auditFindingColumn;
};

const auditFindingsMockedData = [
  {
    key: 1
  }
];

export { auditFindingColumns, auditFindingsMockedData };
