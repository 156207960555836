import { Key } from 'react';
import { IResponseFilters, YesNo } from '../globalTypes';

export interface IGapsState {
  error: boolean | null;
  loading: boolean;
  auditFindingMapping: IAuditData[];
  evidenceMapping: IEvidenceData[];
  gapsDescription: IGapsTableData[];
  gapsTableData: IGapsTableData[];
  additionalInfo: IAdditionalInfoData[];
  assignedEls: IAssignedEvidence[][];
  assignedEvidences: IAssignedEvidence[][];
  assignedAuditFindingsUpdated: boolean;
  assignedEvidencesUpdated: boolean;
  tableFilters: ITableFilters;
  selectedEvidenceModule: boolean;
  clickedHandleMoveButton: boolean;
  clickedHandleEvidenceMoveButton: boolean;
  infoSave: ISaveInfoData;
  gapsSaveData: IGaps;
  gapsUpdated: boolean;
  infoUpdated: boolean;
}

export interface ITableFilters {
  [key: string]: Key[];
}

export interface IUnassignedAudit {
  key?: number;
  afg_cat_ref: number;
  afg_cty_ref: number;
  afg_desc_en: string;
  afg_due_date: string | null;
  afg_id: number;
  afg_pri_ref: number;
  afg_src_ref: number;
}

export interface IUnassignedEvidence {
  evd_assigned_from: number;
  evd_desc_en: string;
  evd_file_name: string[];
  evd_id: number;
  evd_mod_ref: number;
}

export interface IAudits {
  desc?: string;
  ads_short_name?: string;
  afg_desc?: string;
  due_date?: string;
  afg_due_date?: string;
  afg_id: number;
  afg_owner_notified?: YesNo;
  afg_owner_ref: string;
  afg_pri_ref: number;
  afg_prio_short_name?: string;
  afg_src_ref: number;
}

export interface IAuditFindings {
  AUDITS: IAudits[];
  category: Categories;
  id: number;
  is_app: YesNo;
  level: number;
  tech_child: string;
  value: string;
}

export interface IAuditData {
  [x: string]: any;
  [key: number]: IAuditFindings[];
}

export interface IEvidence {
  [key: number]: { evd_desc: string; id: number };
}

export interface IEvidenceMapping {
  EVIDENCES: IEvidence;
  category: Categories;
  id: number;
  is_app: YesNo;
  level: number;
  tech_child: string;
  value: string | null;
}

export interface IEvidenceData {
  [x: string]: any;
  [key: number]: IEvidenceMapping[];
}

export interface IGapsInfo {
  gap_desc: string;
  gap_id?: number;
  gap_noticed?: YesNo;
  gap_owner_notified?: YesNo;
  gap_bru_ref?: number;
}

export interface IGaps {
  [key: number | string]: IGapsInfo;
}

export interface IGapsData extends IResponseFilters {
  AUDITS: IAuditData;
  EVIDENCES: IEvidence;
  GAPS: IGaps;
  category: Categories;
  id: number;
  is_app: YesNo;
  level: number;
  tech_child: string;
  value: string;
}

export interface IGapsTableData {
  [key: number]: IGapsData[];
}

export interface ISaveInfo {
  gap_id?: number;
  gap_src_ref?: number;
  gap_due_date?: string;
  gap_pri_ref?: number;
  gap_owner_ref?: string;
}

export interface ISaveInfoData {
  [key: number]: ISaveInfo;
}

export interface IAdditionalInfo extends ISaveInfo {
  AUDITS: IAudits[];
  bru_child_key: string;
  gap_desc: string;
  gap_noticed: YesNo;
  gap_owner_notified: YesNo;
  gap_prio_short_name: string;
  gps_short_name: string;
}

export interface IAdditionalInfoData {
  [x: string]: any;
  [key: number]: IAdditionalInfo[];
}

export interface IAssignedEl {
  id: number | string;
  text: string;
}

export interface IAssignedEvidence {
  id: number;
  txt: string;
}

export enum GapTabs {
  AUDIT_FINDINGS = 'Audit Findings Mapping',
  EVIDENCES = 'Evidence Mapping',
  GAPS_DESC = 'Gaps Definition',
  ADDITIONAL_INFO = 'Additional Information'
}

export enum Categories {
  OUTSOURCING = 'Outsourcing',
  IT_SECURITY = 'IT security',
  GENERAL_CATEGORY = 'General Category',
  DEFAULT_VALUE = 'Default Value'
}
