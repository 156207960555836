import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Popconfirm, theme, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { RootState } from 'store/configureStore';
import CustomTable from 'components/Tables/CustomTable';
import DataManagementLayout from 'components/Layouts/DataManagementLayout/DataManagementLayout';
import i18n from 'components/translation/i18n';
import * as Styled from './CategoryManagement.styled';

export interface ICategory {
  key?: string;
  short_name: string;
  description: string;
}

const { useToken } = theme;

const CategoryManagement: React.FC = () => {
  const { token } = useToken();
  const i18nInitialization = i18n();
  const { drawTable, lang, category } = useSelector((state: RootState) => ({
    drawTable: state.management.drawTable,
    lang: state.global.lang,
    category: state.management.category
  }));

  const [rows, setRows] = useState<ICategory[]>([]);

  useEffect(() => {
    if (category && Array.isArray(category)) {
      setRows(
        category.map((cat: any, index: number) => ({
          key: String(index + 1),
          short_name: cat.short_name,
          description: cat.description
        }))
      );
    }
  }, [category, lang]);

  const handleAddRow = () => {
    const newRow: ICategory = {
      key: String(rows.length + 1),
      short_name: '',
      description: ''
    };
    setRows((prevRows) => [...prevRows, newRow]);
  };

  const handleDeleteClick = (id?: number | string) => {
    if (!id) return;

    // TODO - add delete functionality
  };

  const cancel = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    message.error(i18nInitialization.t('CATEGORY_NOT_DELETED'));
  };

  const columns: ColumnsType<ICategory> = [
    {
      title: i18nInitialization.t('ACTION'),
      key: 'action',
      width: 100,
      render: (_) => (
        <Styled.StyledButtonSection token={token}>
          <Popconfirm
            title={i18nInitialization.t('DELETE_CATEGORY')}
            description={i18nInitialization.t('CONFIRM_DELETE_CATEGORY')}
            onConfirm={() => handleDeleteClick()}
            onCancel={cancel}
            okText={i18nInitialization.t('YES')}
            cancelText={i18nInitialization.t('NO')}
          >
            <Styled.StyledButton
              type='primary'
              size='small'
              shape='circle'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Styled.StyledButtonSection>
      )
    },
    {
      title: i18nInitialization.t('SHORT_NAME'),
      dataIndex: 'short_name',
      key: 'short_name',
      width: 425,
      render: (val, data) => (
        <Styled.ColumnTextBox width={500} placeholder={data.short_name} />
      )
    },
    {
      title: i18nInitialization.t('DESCRIPTION'),
      dataIndex: 'description',
      key: 'description',
      width: 425,
      render: (val, data) => (
        <Styled.ColumnTextBox width={425} placeholder={data.description} />
      )
    }
  ];

  return (
    <DataManagementLayout>
      <Styled.ContentWrapper>
        {drawTable && (
          <>
            <CustomTable
              drawTable={drawTable}
              columns={columns}
              dataSource={rows}
              itemsCount={rows.length}
              pagination={false}
              scroll={{ x: 1000 }}
            />
            <Button
              type='primary'
              onClick={handleAddRow}
              style={{ marginTop: '15px', marginLeft: '45px' }}
              icon={<PlusOutlined />}
              shape='circle'
            />
          </>
        )}
      </Styled.ContentWrapper>
    </DataManagementLayout>
  );
};

export default CategoryManagement;
