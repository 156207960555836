import { Button } from 'antd';
import styled from 'styled-components';
import { CSS } from '@dnd-kit/utilities';
import { IToken } from 'store/ducks/globalTypes';

interface IDraggableElProps {
  isDragging: boolean;
  transition: any;
  transform: any;
}

interface IWrapperProps extends IToken {
  fileListExist: boolean;
}

export const DraggableEl = styled('div')<IDraggableElProps>`
  cursor: move;
  transform: ${(props) => CSS.Transform.toString(props.transform)};
  transition: ${(props) => props.transition};
}
  .ant-upload-list-item .ant-upload-list-item-name {
    pointer-events: ${(props) => (props.isDragging ? 'none' : 'auto')};
  }
`;

export const StyledButton = styled(Button)<IToken>`
  margin-bottom: ${(props) => props.token.marginSM};
`;

export const UploadFileWrapper = styled('div')<IWrapperProps>`
  padding: 0 !important;

  .ant-upload-list {
    padding: 2px 10px 10px;
    margin-top: 10px;
    background: ${(props) =>
      props.fileListExist ? props.token.colorBgSpotlight : 'unset'};
    border-radius: ${(props) => `${props.token.borderRadiusLG}px`};
    max-height: 162px;
    max-width: 350px;
    overflow-y: auto;
  }
`;
