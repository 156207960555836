import { LanguageList } from 'store/ducks/globalTypes';
import i18n from 'components/translation/i18n';

const languagesPage = () => {
  const i18nInitialization = i18n();

  const languages = [
    {
      label: i18nInitialization.t('ENGLISH'),
      value: LanguageList.EN
    },
    {
      label: i18nInitialization.t('GERMAN'),
      value: LanguageList.DE
    },
    {
      label: i18nInitialization.t('FRENCH'),
      value: LanguageList.FR
    },
    {
      label: i18nInitialization.t('ITALIAN'),
      value: LanguageList.IT
    },
    {
      label: i18nInitialization.t('SPANISH'),
      value: LanguageList.ES
    },
    {
      label: i18nInitialization.t('PORTUGUESE'),
      value: LanguageList.PT
    }
  ];
  return languages;
};

export default languagesPage;
