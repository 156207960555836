import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import * as Styled from './CustomDatePicker.styled';

interface ICustomDatePickerProps {
  defaultValue?: any;
  [rest: string]: any;
}

const dateFormat = 'YYYY-MM-DD';

export const CustomDatePicker: React.FC<ICustomDatePickerProps> = ({
  defaultValue,
  ...rest
}) => {
  const dateIsValid = dayjs(defaultValue, dateFormat).isValid();

  return (
    <Styled.DatePickerWrapper>
      {dateIsValid ? (
        <DatePicker defaultValue={dayjs(defaultValue, dateFormat)} {...rest} />
      ) : (
        <DatePicker placeholder='None' {...rest} />
      )}
    </Styled.DatePickerWrapper>
  );
};
