import jwt_decode from 'jwt-decode';
import { arrayMove } from '@dnd-kit/sortable';
import { IAurReq, IRegulatoryReq } from 'store/ducks/ClientReq/types';
import { ISelectedOptions } from 'store/ducks/ComplienceEst/types';
import filterNames from 'constants/filterNames';

const Helpers = {
  isObjEmpty: (obj: any) => Object.keys(obj).length === 0,
  formatDate: (date: any) => {
    const dt = new Date(date);
    return dt.toLocaleString('de', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  },

  getToken: () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user?.token;
  },
  getUserInfo: () => {
    const token = Helpers.getToken();

    return token && jwt_decode(token);
  },

  getFilterParams: () => {
    const searchParams = new URLSearchParams(document.location.search);
    let filtersParams: ISelectedOptions = {};

    filterNames.forEach((filter: string) => {
      if (searchParams.has(filter.toLowerCase())) {
        filtersParams = {
          ...filtersParams,
          [filter.toLowerCase()]: searchParams.getAll(filter.toLowerCase())
        };
      }
    });

    return filtersParams;
  },

  convertToSelectOptions: (data: Array<any>) =>
    data && data.length > 0
      ? data.map((item: string) => ({ value: item, label: item }))
      : [],
  onDragEnd: ({ active, over }: any, setState: any, filteredKey: string) => {
    if (active.id !== over?.id) {
      setState((prev: any) => {
        const activeIndex = prev.findIndex(
          (i: any) => i[filteredKey] === active.id
        );

        const overIndex = prev.findIndex(
          (i: any) => i[filteredKey] === over?.id
        );

        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  },
  filterRegReqs: (
    regReqs: IRegulatoryReq[],
    checkedAurReqs: number[],
    checked: boolean
  ) => {
    const filteredRegReqs: IRegulatoryReq[] = regReqs.reduce(
      (result: IRegulatoryReq[], obj: IRegulatoryReq) => {
        const filteredAurReqs: { [key: string]: IAurReq } = {};

        for (const key in obj.AUR_REQ) {
          const aurReq = obj.AUR_REQ[key as keyof typeof obj.AUR_REQ];

          if (
            (checked && checkedAurReqs.includes(aurReq.id)) ||
            (!checked && !checkedAurReqs.includes(aurReq.id))
          ) {
            filteredAurReqs[key] = aurReq;
          }
        }

        if (Object.keys(filteredAurReqs).length > 0) {
          result.push({
            ...obj,
            // @ts-ignore
            AUR_REQ: filteredAurReqs
          });
        }

        return result;
      },
      []
    );

    return filteredRegReqs;
  },
  removeAtIndex: (array: any[], index: number) => [
    ...array.slice(0, index),
    ...array.slice(index + 1)
  ],
  insertAtIndex: (array: any[], index: number, item: unknown) => [
    ...array.slice(0, index),
    item,
    ...array.slice(index)
  ]
};

export default Helpers;
