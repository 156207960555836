import React, { useState, useEffect } from 'react';
import { theme } from 'antd';
import { Dayjs } from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import ComplianceEstLayout from 'components/Layouts/ComplianceEstLayout';
import TextBox from 'components/TextBox';
import CustomTable from 'components/Tables/CustomTable';
import CustomSelect from 'components/CustomSelect';
import NestedTable from 'components/Tables/NestedTable';
import {
  ITask,
  ITasksResult,
  ITasksToShow,
  ITaskToShow
} from 'store/ducks/Tasks/types';
import CustomIconButton from 'components/CustomIconButton';
import CustomDatePicker from 'components/CustomDatePicker';
import TextAreaBoxTasks from 'components/TextAreaBoxTasks';
import Helpers from 'utils/Helpers';
import CustomTextarea from 'components/CustomTextarea';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import { RootState } from 'store/configureStore';
import { tasksOp } from 'store/ducks/Tasks';
import { globalOp } from 'store/ducks/Global';
import i18n from 'components/translation/i18n';
import { TaskColumns } from 'constants/tableColumns';
import * as Styled from './TasksDefinition.styled';

const { useToken } = theme;

const TasksDefinition = () => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const i18nInitialization = i18n();
  const taskColumns = TaskColumns();

  const {
    lang,
    drawTable,
    loading,
    tasks,
    tasksToShow,
    tasksToSave,
    saveClicked
  } = useSelector((state: RootState) => ({
    lang: state.global.lang,
    drawTable: state.complianceEst.drawTable,
    saveClicked: state.complianceEst.saveClicked,
    loading: state.tasks.loading,
    tasks: state.tasks.tasks,
    tasksToShow: state.tasks.tasksToShow,
    tasksToSave: state.tasks.tasksToSave
  }));

  const [ownersList, setOwnersList] = useState<string[]>([]);
  const [taskTypes, setTaskTypes] = useState<string[]>([]);
  const [statusList, setStatusList] = useState<string[]>([]);

  useEffect(() => {
    globalOp
      .fetchOwnersList()
      .then((response: string[]) => setOwnersList(response));
    globalOp
      .fetchStatusList(lang)
      .then((response: string[]) => setStatusList(response));
    globalOp
      .fetchTypesList(lang)
      .then((response: string[]) => setTaskTypes(response));
  }, []);

  useEffect(() => {
    if (!Helpers.isObjEmpty(tasksToSave) && saveClicked) {
      dispatch(tasksOp.saveTasks(Object.values(tasksToSave), lang));
    }
  }, [saveClicked]);

  useEffect(() => {
    if (Helpers.isObjEmpty(tasksToSave)) {
      dispatch(complianceEstOp.setSaveDisabled(true));
    } else {
      dispatch(complianceEstOp.setSaveDisabled(false));
    }
  }, [tasksToSave]);

  useEffect(() => {
    if (tasks?.length > 0) {
      let newObj: ITasksToShow = {};

      tasks.forEach((item: ITasksResult) => {
        const newTasks: { [key: keyof typeof item.TASKS]: ITaskToShow } = {};

        for (const property in item.TASKS) {
          newTasks[property] = {
            ...item,
            task_id: item.TASKS[property].tsk_id,
            TASKS: { ...item.TASKS[property], tsk_sol_ref: item.sol_id }
          };
        }

        newObj[item.sol_id] = newTasks;
      });

      dispatch(tasksOp.setTasksToShow(newObj));
    }
  }, [tasks]);

  const expandedRow = (record: { [key: number | string]: ITaskToShow }) => {
    const handlePlusClick = () => {
      dispatch(tasksOp.addNewTask(Object.values(record)[0].sol_id));
    };

    const nestedTableColumns = [
      {
        title: 'Solution',
        dataIndex: 'desc',
        key: 'desc',
        width: 500,
        render: (desc: string, rec: ITasksToShow, index: number) => {
          const rowspan = index === 0 ? Object.values(record).length : 0;

          return {
            children: <TextBox width={500}>{desc}</TextBox>,
            props: {
              rowSpan: rowspan
            }
          };
        }
      },
      {
        title: 'Task ID',
        dataIndex: 'TASKS',
        key: 'TASKS',
        width: 100,
        render: (TASKS: ITask) => (
          <>
            {TASKS.tsk_id ? (
              <TextBox width={100}>{TASKS.tsk_id}</TextBox>
            ) : (
              <Styled.StyledTextBox width={100} />
            )}
          </>
        )
      },
      {
        title: 'Task Short Name',
        dataIndex: 'TASKS',
        key: 'TASKS',
        width: 330,
        render: (TASKS: ITask, rec: ITaskToShow) => (
          <>
            {!Helpers.isObjEmpty(TASKS) ? (
              <Styled.TextAreaElementsContainer>
                <CustomTextarea
                  placeholder='Type Short Name'
                  defaultValue={TASKS.tsk_short_name}
                  onChange={(e) =>
                    dispatch(
                      tasksOp.editTaskValue(
                        rec.task_id,
                        TASKS,
                        'tsk_short_name',
                        e.target.value
                      )
                    )
                  }
                />
              </Styled.TextAreaElementsContainer>
            ) : (
              <Styled.StyledTextBox width={330} />
            )}
          </>
        )
      },
      {
        title: 'Task definition',
        dataIndex: 'TASKS',
        key: 'TASKS',
        width: 366,
        render: (TASKS: ITask, rec: ITaskToShow, index: number) => (
          <>
            {!Helpers.isObjEmpty(TASKS) && (
              <TextAreaBoxTasks
                task={TASKS}
                solId={rec.sol_id}
                taskId={rec.task_id}
              />
            )}
            {index === Object.values(record).length - 1 && (
              <Styled.ButtonContainer>
                <CustomIconButton
                  type='primary'
                  shape='circle'
                  icon={<PlusOutlined />}
                  size='middle'
                  onClick={handlePlusClick}
                />
              </Styled.ButtonContainer>
            )}
          </>
        )
      },
      {
        title: 'Task Type',
        dataIndex: 'TASKS',
        key: 'TASKS',
        width: 100,
        render: (TASKS: ITask, rec: ITaskToShow) => (
          <>
            {TASKS.tsk_tkt_ref ? (
              <CustomSelect
                options={Helpers.convertToSelectOptions(taskTypes)}
                defaultValue={TASKS.tsk_tkt_ref}
                onChange={(value: string) =>
                  dispatch(
                    tasksOp.editTaskValue(
                      rec.task_id,
                      TASKS,
                      'tsk_tkt_ref',
                      value
                    )
                  )
                }
              />
            ) : (
              <Styled.StyledTextBox width={100} />
            )}
          </>
        )
      },
      {
        title: 'Task status',
        dataIndex: 'TASKS',
        key: 'TASKS',
        width: 120,
        render: (TASKS: ITask, rec: ITaskToShow) => (
          <>
            {TASKS.tsk_tst_ref ? (
              <CustomSelect
                size='md'
                options={Helpers.convertToSelectOptions(statusList)}
                defaultValue={TASKS.tsk_tst_ref}
                onChange={(value: string) =>
                  dispatch(
                    tasksOp.editTaskValue(
                      rec.task_id,
                      TASKS,
                      'tsk_tst_ref',
                      value
                    )
                  )
                }
              />
            ) : (
              <Styled.StyledTextBox width={120} />
            )}
          </>
        )
      },
      {
        title: 'Owner',
        dataIndex: 'TASKS',
        key: 'TASKS',
        width: 150,
        render: (TASKS: ITask, rec: ITaskToShow) => (
          <>
            {TASKS.tsk_owner ? (
              <CustomSelect
                size='lg'
                options={Helpers.convertToSelectOptions(ownersList)}
                defaultValue={TASKS.tsk_owner}
                onChange={(value: string) =>
                  dispatch(
                    tasksOp.editTaskValue(
                      rec.task_id,
                      TASKS,
                      'tsk_owner',
                      value
                    )
                  )
                }
              />
            ) : (
              <Styled.StyledTextBox width={150} />
            )}
          </>
        )
      },
      {
        title: 'Due Date',
        dataIndex: 'TASKS',
        key: 'TASKS',
        width: 128,
        render: (TASKS: ITask, rec: ITaskToShow) => (
          <>
            {TASKS.tsk_due_date ? (
              <CustomDatePicker
                defaultValue={TASKS.tsk_due_date}
                onChange={(date: Dayjs, dateString: string) =>
                  dispatch(
                    tasksOp.editTaskValue(
                      rec.task_id,
                      TASKS,
                      'tsk_due_date',
                      dateString
                    )
                  )
                }
              />
            ) : (
              <Styled.StyledTextBox width={128} />
            )}
          </>
        )
      }
    ];

    return (
      <NestedTable
        key={Object.values(record)[0].sol_id}
        className='nested-table'
        columns={nestedTableColumns}
        dataSource={Object.values(record)}
        pagination={false}
      />
    );
  };

  return (
    <ComplianceEstLayout>
      <Styled.ContentWrapper token={token}>
        {drawTable && !Helpers.isObjEmpty(tasksToShow) ? (
          <Styled.StyledCustomTable
            drawTable
            token={token}
            columns={taskColumns}
            dataSource={Object.values(tasksToShow)}
            itemsCount={Object.values(tasksToShow).length}
            loading={loading}
            expandable={{
              expandedRowKeys: Object.values(tasksToShow).map(
                (item: any, index: number) => item[index]
              ),
              expandedRowRender: (record: { [key: number]: ITaskToShow }) =>
                expandedRow(record)
            }}
            scroll={{ x: 1000 }}
          />
        ) : (
          <CustomTable
            drawTable
            itemsCount={0}
            columns={taskColumns}
            dataSource={[]}
          />
        )}
      </Styled.ContentWrapper>
    </ComplianceEstLayout>
  );
};

export default TasksDefinition;
