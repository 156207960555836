import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';
import CustomIconButton from '../CustomIconButton';

export const StyledButtonSection = styled('div')<IToken>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => `${props.token.marginSM}px`};
`;

export const TextAreaElementsContainer = styled('div')`
  display: flex;
  padding: 0 !important;
`;

export const StyledCustomIconButton = styled(CustomIconButton)<IToken>`
  margin-top: ${(props) => `${props.token.marginXXS}px`};
`;
