import { CSS, Transform } from '@dnd-kit/utilities';
import styled from 'styled-components';

export const StyledDraggableContainer = styled.div.attrs({
  className: 'StyledDraggableContainer'
})<{
  isDragging?: boolean;
  isOverlay?: boolean;
  transition?: string;
  transform?: Transform | null;
}>`
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  cursor: ${(props) => (props.isOverlay ? 'grabbing' : 'grab')};
  transition: ${(props) => props.transition};
  transform: ${(props) => CSS.Transform.toString(props.transform || null)};
  margin-bottom: 2rem;
`;
