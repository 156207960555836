import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';
import { CustomIconButton } from 'components/CustomIconButton/CustomIconButton';
import TextBox from 'components/TextBox';
import CustomTable from 'components/Tables/CustomTable';

interface IItemWidth extends IToken {
  width?: number;
}

export const ContentWrapper = styled('div')<IToken>`
  width: 100%;
  padding: ${(props) => `${props.token.paddingLG}px`};

  .ant-table-row-level-0 {
    > .ant-table-cell {
      padding: 0 16px;

      > div {
        padding: 0 16px;
      }
    }
  }

  .ant-tabs-nav {
    margin: unset;

    &::before {
      border-bottom: unset;
    }
  }

  .ant-tabs {
    .ant-tabs-nav-list {
      gap: 10px;
    }

    .ant-tabs-tab {
      color: ${(props) => props.token.colorPrimaryText};
      font-weight: bold;

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${(props) => props.token.colorPrimaryText};
      }
    }
  }
  .ant-table-content {
    border-radius: 10px;
  }
  .ant-table-thead tr .ant-table-cell {
    white-space: nowrap;
  }

  .ant-tabs-card {
    &.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
      background: ${(props) => props.token.colorPrimaryBgHover};
    }

    & > .ant-tabs-nav .ant-tabs-tab {
      background-color: #34899c;
      border: unset;

      &:hover,
      &:focus,
      &:active {
        background: ${(props) => props.token.colorPrimaryBgHover};
      }
    }
  }
`;

export const StyledTextBox = styled(TextBox)`
  visibility: hidden;
`;

export const CheckboxContainer = styled('div')`
  min-width: 116px;
  margin-right: 16px;
  display: flex;
  justify-content: flex-start;
`;

export const StyledButtonSection = styled('div')<IToken>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => `${props.token.marginSM}px`};
`;

export const TextAreaElementsContainer = styled('div')`
  display: flex;
  padding: 0 !important;
`;

export const StyledCustomIconButton = styled(CustomIconButton)<IToken>`
  margin-top: ${(props) => `${props.token.marginXXS}px`};
`;

export const ButtonContainer = styled('div')<{
  withCheckbox?: boolean;
}>`
  min-width: 367px;
  display: flex;
  justify-content: center;
  padding: 16px 0 0 !important;
`;

export const ButtonContainerEmpty = styled('div')<{
  withCheckbox?: boolean;
}>`
  min-width: 367px;
  display: flex;
  justify-content: center;
  padding: 0 !important;
`;

export const StyledCustomTable = styled(CustomTable)`
  .ant-table-row-expand-icon-cell {
    display: none;
  }

  .ant-table-row-level-0 {
    > .ant-table-cell {
      padding: 0 16px;

      > div {
        padding: 0 16px;
      }
    }
  }

  .ant-table-filter-column {
    flex-direction: row-reverse;

    .ant-dropdown-trigger {
      margin-inline: 0 5px;
    }
  }

  .ant-table-wrapper {
    .ant-table {
      margin: unset !important;
    }
  }

  .ant-spin-nested-loading {
    overflow-y: initial !important;
  }
`;

export const MergedColumnLg = styled('div')<IItemWidth>`
  width: ${(props) => (props.width ? `${props.width}px` : '515px')};
  position: relative;
  padding: ${(props) => `0 ${props.token.padding}px`};

  &:before {
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    width: 1px;
    height: 1.6em;
    background-color: ${(props) => props.token.colorBgMask};
    transform: translateY(-50%);
    transition: background-color 0.2s;
    content: '';
    display: block;
  }
`;
