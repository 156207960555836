const regExps = {
  containsSymbols: /[-_.!@#$%^&*()]+/,
  containsUppercase: /[A-Z]+/,
  containNumbers: /\d+/
};

const validationRules = [
  {
    test: (val: string) => val?.length >= 8,
    message: 'Password length should be at least 8 characters.'
  },
  {
    test: (val: string) => regExps.containsSymbols.test(val),
    message: 'Password must contain symbols.'
  },
  {
    test: (val: string) => regExps.containsUppercase.test(val),
    message: 'Password must contain an uppercase letter.'
  },
  {
    test: (val: string) => regExps.containNumbers.test(val),
    message: 'Password must contain a digit.'
  }
];

export default validationRules;
