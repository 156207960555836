import styled from 'styled-components';
import { Button } from 'antd';
import { IToken } from 'store/ducks/globalTypes';

export const CopyrightContainer = styled('div')<IToken>`
  min-width: 100%;
  text-align: center;
  margin-top: ${(props) => `${props.token.marginLG}px`};
`;

export const StyledLinkButton = styled(Button)<IToken>`
  padding: unset;
  margin-right: ${(props) => `${props.token.marginXXS}px`};

  > span {
    color: ${(props) => props.token.colorPrimaryText};
    text-decoration: underline;
  }

  &:hover {
    > span {
      text-decoration: none;
    }
  }
`;
