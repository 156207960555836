import { IManagementState } from './types';

const initialManagementState = (): IManagementState => ({
  error: null,
  loading: false,
  drawTable: false,
  filtersData: [],
  saveDisabled: true,
  saveClicked: false,
  mgmtAudits: {},
  category: {},
  product: {}
});

export default initialManagementState;
