import styled from 'styled-components';
import { AliasToken } from 'antd/es/theme/interface';

interface ITableProps {
  drawTable: boolean;
  token: AliasToken;
}

export const TableWrapper = styled('div')<ITableProps>`
  .ant-table-row-expand-icon-cell {
    display: none;
  }

  .ant-pagination {
    margin: 0px 16px 16px 16px !important;
  }

  .ant-table-row-level-0 {
    > .ant-table-cell {
      padding: 16px 5px 16px 5px;

      > div {
        padding: 16px;
      }
    }
  }

  .ant-table-filter-column {
    flex-direction: row-reverse;

    .ant-dropdown-trigger {
      margin-inline: 0 5px;
      color: ${(props) => props.token.colorPrimaryText};
    }
  }

  .ant-table-wrapper {
    .ant-table {
      margin: unset !important;
    }
  }

  .ant-spin-nested-loading {
    overflow-y: initial !important;
  }

  .ant-table-row {
    .ant-table-cell:nth-of-type(5) {
      .TextColumn-root {
        background-color: transparent;
      }
    }
  }

  .ant-table-content {
    overflow: ${(props) => (props.drawTable ? 'auto hidden' : 'hidden')};
  }

  .ant-table-placeholder {
    display: none;
  }

  .ant-table-wrapper .ant-table {
    max-width: 100%;
    background: none !important;

    &:hover {
      background: none !important;
    }

    .ant-spin-nested-loading {
      overflow-y: scroll;
    }

    .ant-table-tbody > tr > td {
      border-bottom: unset !important;
    }

    tbody {
      vertical-align: top;

      > tr {
        background: ${(props) => props.token.colorBgLayout};

        &:hover > td {
          background: ${(props) => props.token.colorBgLayout};
        }
      }
    }

    .ant-table-expanded-row.ant-table-expanded-row-level-1 {
      .ant-table-cell {
        padding: unset;
      }
    }

    .ant-table-thead {
      tr {
        &:first-of-type > *:first-of-type,
        &:last-child > *:last-child {
          border-start-start-radius: unset !important;
          border-start-end-radius: unset !important;
        }

        th {
          background: ${(props) => props.token.colorFillQuaternary};
          font-size: ${(props) => `${props.token.fontSizeSM}px`};
          font-weight: ${(props) => props.token.fontWeightStrong};
        }
      }
    }
  }
`;
