import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';

export const TableWrapper = styled('div')<IToken>`
  .ant-table-wrapper .ant-table {
    .ant-table-thead {
      display: none;
    }

    .ant-table-row.ant-table-row-level-0 {
      > .ant-table-cell {
        padding: 10px 16px 30px !important;

        > div {
          margin: unset;
          padding: ${(props) => `${props.token.padding}px`};
        }

        .ant-select {
          display: block;
        }
      }
    }

    .ant-table-tbody {
      > tr {
        &:hover > td {
          background: ${(props) => props.token.colorBgLayout};
        }
      }
    }
  }
`;
