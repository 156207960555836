import styled from 'styled-components';
import { Button } from 'antd';
import { IToken } from 'store/ducks/globalTypes';
import CustomTextarea from 'components/CustomTextarea';

export const ColumnTextBox = styled(CustomTextarea)`
  margin-top: -15px;
`;

export const ContentWrapper = styled('div')`
  width: 100%;
  padding: 24px;

  .ant-table-wrapper .ant-table {
    .ant-table-filter-column {
      flex-direction: row-reverse;
  
      .ant-dropdown-trigger {
        margin-inline: 0 5px;
        color: white;
      }
    }
    .ant-table-content {
      border-radius: 10px
    }
    .ant-table-thead tr .ant-table-cell{
      white-space: nowrap;
    }
`;

export const StyledButton = styled(Button)`
  border: unset;
  margin-bottom: 5px;
  min-width: 24px;
  border-radius: 50%;
  background-color: rgba(0, 54, 84, 0.5);
  padding: 0;
  width: 24px !important;
  height: 24px;
  align-self: flex-end;
  margin-left: 6px;
`;

export const StyledButtonSection = styled('div')<IToken>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => `${props.token.marginSM}px`};
`;
