import styled from 'styled-components';
import TextBox from 'components/TextBox';
import { IToken } from 'store/ducks/globalTypes';

export const MergedColumnsContainer = styled('div')`
  display: flex;
`;

export const MergedColumnSm = styled('div')<IToken>`
  width: 116px;
  position: relative;

  &:before {
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    width: 1px;
    height: 1.6em;
    background-color: ${(props) => props.token.colorBgMask};
    transform: translateY(-50%);
    transition: background-color 0.2s;
    content: '';
    display: block;
  }
`;

export const MergedColumnMd = styled('div')<IToken>`
  width: 373px;
  padding-left: ${(props) => `${props.token.padding}px`};
`;

export const StyledTextBox = styled(TextBox)`
  padding: 0 !important;
`;
