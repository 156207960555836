import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import * as Styled from './ThemeSelector.styled';

export interface IThemeSelectorProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const ThemeSelector: React.FC<IThemeSelectorProps> = ({ children }) => {
  const { scheme } = useSelector((state: RootState) => state.global);

  return (
    <Styled.ThemeContainer theme={scheme}>{children}</Styled.ThemeContainer>
  );
};

export default ThemeSelector;
