import i18n from 'components/translation/i18n';
import { ICustomMenuItem } from 'store/ducks/globalTypes';

const managementHorizontalNavItemsPage = () => {
  const i18nInitialization = i18n();
  const managementHorizontalNavItems: ICustomMenuItem[] = [
    {
      label: <span>{i18nInitialization.t('AUDIT_FINDINGS_MANAGEMENT')}</span>,
      key: 'auditFindings',
      path: '/dataManagement/auditFindings'
    },
    {
      label: <span>{i18nInitialization.t('CATEGORY_MANAGEMENT')}</span>,
      key: 'category',
      path: '/dataManagement/category'
    },
    {
      label: <span>{i18nInitialization.t('PRODUCT_MANAGEMENT')}</span>,
      key: 'product',
      path: '/dataManagement/product'
    }
  ];
  return managementHorizontalNavItems;
};

export default managementHorizontalNavItemsPage;
