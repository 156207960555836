import styled from 'styled-components';
import { Checkbox } from 'antd';
import { IToken } from 'store/ducks/globalTypes';

export const StyledCheckbox = styled(Checkbox)<IToken>`
  width: 10px;
  justify-content: center;

  .ant-checkbox-checked {
    &:after {
      border: unset;
    }

    .ant-checkbox-inner {
      background-color: ${(props) => props.token.colorPrimary};
      border: unset;
    }
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${(props) => props.token.colorBorderSecondary};
  }

  .ant-checkbox-inner {
    width: 21px;
    height: 21px;
    background: transparent;

    &:after {
      width: 7px;
      height: 12px;
    }
  }

  &:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border: unset;
  }
`;
