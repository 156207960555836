import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';
import { ReactComponent as ReactBrightLogo } from 'assets/svg/brightlogo1.svg';

export const StyledNav = styled('div')<IToken>`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => `0px ${props.token.paddingLG}px`};
`;

export const StyledLogo = styled(ReactBrightLogo)`
  //TODO ask designer to send logo with normal sizes
  margin-left: -60px;
  width: 227px;
`;

export const DropdownContainer = styled('div')`
  margin-top: 20px;
  display: flex;
  gap: 15px;
`;
