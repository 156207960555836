import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import initialManagementState from './initialState';
import {
  IAuditFindingsResult,
  ICategoryResult,
  IManagementState,
  IProductResult
} from './types';
import { IFilters } from '../globalTypes';

const managementSlice = createSlice({
  name: 'management',
  initialState: initialManagementState(),
  reducers: {
    set_mgmt_audits(state: any, action: PayloadAction<IAuditFindingsResult[]>) {
      state.mgmtAudits = action.payload;
      state.loading = false;
    },
    set_category(state: any, action: PayloadAction<ICategoryResult[]>) {
      state.category = action.payload;
      state.loading = false;
    },
    set_product(state: any, action: PayloadAction<IProductResult[]>) {
      state.product = action.payload;
      state.loading = false;
    },
    set_draw_table(state: IManagementState, action: PayloadAction<boolean>) {
      state.drawTable = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_loading(state: IManagementState) {
      state.loading = true;
    },
    set_save_disabled(state: IManagementState, action: PayloadAction<boolean>) {
      state.saveDisabled = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_save_clicked(state: IManagementState, action: PayloadAction<boolean>) {
      state.saveClicked = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_filters_data(
      state: IManagementState,
      action: PayloadAction<IFilters[]>
    ) {
      state.filtersData = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_error(state: IManagementState, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export default managementSlice;
