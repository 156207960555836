import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, theme } from 'antd';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import Droppable from '../Droppable';
import DraggableBox from '../DraggableBox';
import EvidenceTextBox from '../DragTextBox';
import { RootState } from 'store/configureStore';
import { gapsOp } from 'store/ducks/Gaps';
import { IAssignedEvidences } from 'store/ducks/ComplienceEst/types';
import {
  IAssignedEl,
  IAssignedEvidence,
  IUnassignedEvidence
} from 'store/ducks/Gaps/types';
import { complianceEstOp } from 'store/ducks/ComplienceEst';
import Helpers from 'utils/Helpers';
import SelectModule from '../ModuleSelection/SelectModule';
import i18n from 'components/translation/i18n';
import * as Styled from './EvDragAndDrop.styled';

const { useToken } = theme;

type TMockDataEv = {
  [key: string]: { id: string; text: string }[];
};

interface ISepRegReqItem {
  key: number;
  id: number;
  text: string;
}

const EvDragAndDrop = () => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const i18nInitialization = i18n();

  const [mockDataEv, seTMockDataEv] = useState<TMockDataEv>({
    sepRegReq: []
  });
  const [activeBox, setActiveBox] = useState<null | string>(null);
  const [newArray, setNewArray] = useState<IAssignedEl[]>([]);
  const { assignedEvidences, assignedEvidencesUpdated } = useSelector(
    (state: RootState) => state.gaps
  );

  const { lang, drawTable, saveClicked } = useSelector((state: RootState) => ({
    lang: state.global.lang,
    drawTable: state.complianceEst.drawTable,
    saveClicked: state.complianceEst.saveClicked
  }));

  const { selectedEvidenceModule } = useSelector((state: RootState) => ({
    selectedEvidenceModule: state.gaps.selectedEvidenceModule
  }));

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const getEvidences = async () => {
    gapsOp
      .fetchEvidences(lang)
      .then((data: any) => {
        const unassignedEvidencesData = data?.map(
          (evidence: IUnassignedEvidence) => [
            {
              key: evidence.evd_id,
              id: evidence.evd_id,
              text: evidence.evd_desc_en
            }
          ]
        );

        if (unassignedEvidencesData.length > 0) {
          seTMockDataEv({
            sepRegReq: unassignedEvidencesData
              .flat()
              .map((item: ISepRegReqItem) => ({
                ...item,
                key: item.id.toString(),
                id: item.id.toString(),
                text: item.text
              }))
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleMoveToAlignedEvidences = async () => {
    let assignedEvidence: IAssignedEvidences[] = assignedEvidences
      .map((item: IAssignedEvidence[]) => {
        let result: IAssignedEvidences[] = [];
        let addedTxts: string[] = [];
        let txtIdMap: Map<string, number> = new Map();

        mockDataEv.sepRegReq.forEach((el) => {
          if (
            item[0].txt === el.text &&
            addedTxts.indexOf(item[0].txt) === -1
          ) {
            let id = txtIdMap.get(item[0].txt);
            if (id === undefined) {
              txtIdMap.set(item[0].txt, item[0].id);
              id = item[0].id;
            }
            result.push({
              evd_id: el.id,
              bru_id: id
            });
            addedTxts.push(item[0].txt);
          }
        });
        return result;
      })
      .flat();

    if (assignedEvidence.length > 0) {
      const uniqueEvdIds: Record<string, number> = {};
      assignedEvidence.forEach((item, index) => {
        if (!uniqueEvdIds[item.evd_id]) {
          uniqueEvdIds[item.evd_id] = index;
        } else {
          uniqueEvdIds[item.evd_id] = index;
        }
      });

      try {
        await gapsOp.fetchEvidencesSave(assignedEvidence);
        dispatch(gapsOp.setAssignedEvidencesUpdated(true));
        await getEvidences();
      } catch (error) {
        console.error(error);
      }
    }
    dispatch(gapsOp.setClickedHandleEvidenceMoveButton(true));
  };

  useEffect(() => {
    if (selectedEvidenceModule) {
      drawTable && getEvidences();
    }
    dispatch(gapsOp.setAssignedEvidencesUpdated(false));
  }, [selectedEvidenceModule]);

  useEffect(() => {
    if (assignedEvidencesUpdated) {
      gapsOp.fetchEvidenceMapping(lang).then(() => {
        getEvidences();
      });
    }
  }, [assignedEvidencesUpdated]);

  useEffect(() => {
    if (assignedEvidences.length && saveClicked) {
      handleMoveToAlignedEvidences();
      dispatch(complianceEstOp.setSaveClicked(false));
      dispatch(gapsOp.setAssignedEvidences([]));
    }
  }, [saveClicked]);

  const moveBetweenContainers = (
    colData: TMockDataEv,
    activeContainer: string,
    activeIndex: number,
    overContainer: string,
    overIndex: number,
    item: IAssignedEl
  ) => ({
    ...colData,
    [activeContainer]: Helpers.removeAtIndex(
      colData[activeContainer],
      activeIndex
    ),
    [overContainer]: Helpers.insertAtIndex(
      colData[overContainer],
      overIndex,
      item
    )
  });

  const handleDragStart = (event: DragStartEvent) => {
    if (event.active.data.current) {
      setActiveBox(
        mockDataEv[event.active.data.current.keyName][
          event.active.data.current.index
        ].text
      );
      const selectedElements = [];
      selectedElements.push(
        mockDataEv[event.active.data.current.keyName][
          event.active.data.current.index
        ]
      );
    } else {
      setActiveBox(null);
    }
  };

  const handleDragCancel = () => setActiveBox(null);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.data.current && over.data.current) {
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current.sortable.containerId;
      const overIndex =
        over.id in mockDataEv
          ? mockDataEv[overContainer].length + 1
          : over.data.current.sortable.index;
      const activeIndex = active.data.current.index;

      let newItems: TMockDataEv;

      if (activeContainer === overContainer) {
        newItems = {
          ...mockDataEv,
          [overContainer]: arrayMove(
            mockDataEv[overContainer],
            activeIndex,
            overIndex
          )
        };
      } else {
        const itemToMove = mockDataEv[activeContainer][activeIndex];
        newItems = moveBetweenContainers(
          mockDataEv,
          activeContainer,
          activeIndex,
          overContainer,
          overIndex,
          itemToMove
        );

        setNewArray([...newArray, itemToMove]);
        seTMockDataEv({
          ...newItems,
          [activeContainer]: Helpers.removeAtIndex(
            mockDataEv[activeContainer],
            activeIndex
          )
        });
      }

      seTMockDataEv(newItems);
      setActiveBox(null);
    }
  };

  const renderCols = () => {
    const colNum = Object.keys(mockDataEv).length;
    return Object.keys(mockDataEv).map((key: string, index: number) => (
      <Droppable
        colNum={colNum}
        keyName={key}
        arrayData={mockDataEv.sepRegReq}
        key={index}
      />
    ));
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
    >
      <Row className='justify-content-end m-3'>
        <Styled.StyledButton
          token={token}
          type='primary'
          onClick={handleMoveToAlignedEvidences}
        >
          {i18nInitialization.t('MOVE')}
        </Styled.StyledButton>
        <SelectModule />
      </Row>
      <Styled.StyledRow className='drag-overlay'>
        {renderCols()}
      </Styled.StyledRow>
      <DragOverlay>
        {activeBox ? (
          <DraggableBox isOverlay={true}>
            <EvidenceTextBox>{activeBox}</EvidenceTextBox>
          </DraggableBox>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default EvDragAndDrop;
