import styled from 'styled-components';
import { Button, Row } from 'antd';
import { IToken } from 'store/ducks/globalTypes';

export const StyledButton = styled(Button)<IToken>`
  border: unset;
  margin-bottom: ${(props) => `${props.token.marginSM}px`};
`;

export const StyledRow = styled(Row)`
  padding: 0;
`;
