import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { gapsOp } from 'store/ducks/Gaps';
import { ISelectionOption } from 'store/ducks/globalTypes';
import * as Styled from './DragTextBox.style';

interface ITextBoxProps {
  children: string | ReactNode;
  onIdChangeEv?: (
    newIdEv: number | null,
    dragTextEv: string | null,
    selectedValueEv: string | null
  ) => void;
}

interface ClnReqItem {
  category: string;
  id: number;
  is_app: string;
  level: { level: number; font: string; size: number };
  tech_child: string;
  value: null;
}

const DragTextBox: React.FC<ITextBoxProps> = ({ children, onIdChangeEv }) => {
  const dispatch = useDispatch();

  const [selectedValueEv, setSelectedValueEv] =
    useState<string>('Search for id');
  const [selectedChild, setSelectedChild] = useState<any | null>();
  const [optionsEv, setOptionsEv] = useState<ISelectionOption[]>([]);
  const [assignedEvidence, setAssignedEvidence] = useState({
    evd_id: 0,
    bru_id: ''
  });
  const [assignedEvidences, setAssignedEvidences] = useState<
    { evd_id: number; bru_id: string }[]
  >([]);

  const { evidenceMapping, clickedHandleEvidenceMoveButton } = useSelector(
    (state: RootState) => state.gaps
  );

  const handleSelectChangeEv = (value: any) => {
    setSelectedValueEv(value);
    setSelectedChild(children);
    if (onIdChangeEv) {
      onIdChangeEv(
        parseInt(value) || null,
        children as string,
        selectedValueEv || null
      );
    }
  };

  useEffect(() => {
    setAssignedEvidence((currentState) => ({
      ...currentState,
      bru_id: selectedChild,
      evd_id: parseInt(selectedValueEv)
    }));
  }, [selectedValueEv, selectedChild]);

  useEffect(() => {
    setAssignedEvidences((currentEvidences) => [
      ...currentEvidences,
      assignedEvidence
    ]);
  }, [assignedEvidence]);

  useEffect(() => {
    if (clickedHandleEvidenceMoveButton) {
      setSelectedValueEv('Search for id');
      dispatch(gapsOp.setClickedHandleEvidenceMoveButton(false));
    }
  }, [clickedHandleEvidenceMoveButton]);

  useEffect(() => {
    if (assignedEvidence.bru_id && assignedEvidence.evd_id) {
      dispatch(
        gapsOp.setAssignedEvidences({
          id: assignedEvidence.evd_id,
          txt: assignedEvidence.bru_id
        })
      );
    }
  }, [assignedEvidence, dispatch]);

  useEffect(() => {
    if (evidenceMapping.length) {
      const clnReqs = evidenceMapping.map((item: any) => {
        return item[0].map((clnReqItem: ClnReqItem) => {
          return clnReqItem.id.toString();
        });
      });

      const newOptionsEv = clnReqs.flat().map((id: string) => ({
        value: id,
        label: id
      }));

      setOptionsEv(newOptionsEv);
    }
  }, [evidenceMapping]);

  return (
    <Styled.StyledDiv className='drag-text-box'>
      <div>
        <Styled.StyledSelect
          className='select-search'
          defaultActiveFirstOption
          showSearch
          optionFilterProp='children'
          filterOption={(input, option) => {
            const label = option?.label ?? '';

            if (typeof label !== 'string') {
              return false;
            }

            return (
              label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              label.toLowerCase().indexOf(' ' + input.toLowerCase()) >= 0 ||
              label.toLowerCase().indexOf(input.toLowerCase() + ' ') >= 0
            );
          }}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '')
              .toLowerCase()
              .localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={optionsEv}
          onChange={handleSelectChangeEv}
          value={selectedValueEv}
        />
      </div>
      <Styled.StyledSpan>{children}</Styled.StyledSpan>
    </Styled.StyledDiv>
  );
};

export default DragTextBox;
