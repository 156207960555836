import i18n from 'components/translation/i18n';
import { ICustomMenuItem } from 'store/ducks/globalTypes';

const horizontalNavBar = () => {
  const i18nInitialization = i18n();

  const managementHorizontalNavBar: ICustomMenuItem[] = [
    {
      label: <span> {i18nInitialization.t('DEFINE_CLIENT_REQ')}</span>,
      key: 'clientReq',
      path: '/complianceEst/clientReq'
    },
    {
      label: <span>{i18nInitialization.t('ADD_GAPS_AND_AUDIT_FINDINGS')}</span>,
      key: 'gapsAndAuditFindings',
      path: '/complianceEst/gapsAndAuditFindings'
    },
    {
      label: <span>{i18nInitialization.t('SOLUTION_DEFINITION')}</span>,
      key: 'solutionDefinition',
      path: '/complianceEst/solutionDefinition'
    },
    {
      label: <span>{i18nInitialization.t('TASKS_DEFINITION')}</span>,
      key: 'tasksDefinition',
      path: '/complianceEst/tasksDefinition'
    },
    {
      label: <span>{i18nInitialization.t('EVIDENCE_DEFINITION')}</span>,
      key: 'evidenceDefinition',
      path: '/complianceEst/evidenceDefinition'
    }
  ];
  return managementHorizontalNavBar;
};

export default horizontalNavBar;
