import qs from 'qs';
import AxiosInstance from './AxiosInterceptor';
import {
  IApplicability,
  ICategory,
  IClientReq
} from 'store/ducks/ClientReq/types';
import {
  CommentItem,
  CommentTable,
  IAssignedEls,
  IAssignedEvidences,
  ISelectedOptions
} from 'store/ducks/ComplienceEst/types';
import { IGapsInfo, ISaveInfo } from 'store/ducks/Gaps/types';
import { IResetPass, LanguageList } from 'store/ducks/globalTypes';
import { ISolution } from 'store/ducks/Solutions/types';
import { ITask } from 'store/ducks/Tasks/types';
import { IEvidenceToShow } from 'store/ducks/Evidences/types';
import Helpers from './Helpers';

const ApiService = {
  login: (data: { email: string; password: string }) =>
    AxiosInstance({
      url: '/login',
      method: 'post',
      data
    }),
  forgotPass: (email: string) =>
    AxiosInstance({
      url: '/forgot_password',
      method: 'get',
      params: { email }
    }),
  resetPass: (
    formData: IResetPass,
    token: string | qs.ParsedQs | string[] | qs.ParsedQs[]
  ) =>
    AxiosInstance({
      url: '/reset_password',
      method: 'post',
      params: { token },
      data: formData
    }),
  getPrioritization: (lang: LanguageList) =>
    AxiosInstance({
      url: '/gaps/prioritization',
      method: 'get',
      params: { language: lang }
    }),
  getGapSource: (lang: LanguageList) =>
    AxiosInstance({
      url: '/gaps/gap_source',
      method: 'get',
      params: { language: lang }
    }),
  getOwnersList: () =>
    AxiosInstance({
      url: '/filters/ownership',
      method: 'get'
    }),
  getTypesList: (lang: LanguageList) =>
    AxiosInstance({
      url: '/filters/task_types',
      method: 'get',
      params: { language: lang }
    }),
  getStatuses: (lang: LanguageList) =>
    AxiosInstance({
      url: '/filters/task_statuses',
      method: 'get',
      params: { language: lang }
    }),
  getFilters: (lang: LanguageList) =>
    AxiosInstance({
      url: '/filters',
      method: 'get',
      // params: { language: lang }
      params: { language: LanguageList.EN }
    }),
  addClientReq: (data: IClientReq[], lang: LanguageList) =>
    AxiosInstance({
      url: '/client_input',
      method: 'post',
      params: { language: lang },
      data
    }),
  deleteClnReq: (data: { bru_id: number | string }, lang: LanguageList) =>
    AxiosInstance({
      url: '/client_input',
      method: 'delete',
      params: { language: lang },
      data
    }),
  changeApplicability: (data: IApplicability[], lang: LanguageList) =>
    AxiosInstance({
      url: '/update_applic',
      method: 'post',
      params: { language: lang },
      data
    }),
  changeCategory: (data: ICategory[], lang: LanguageList) =>
    AxiosInstance({
      url: '/update_category',
      method: 'post',
      params: { language: lang },
      data
    }),
  getAuditFindingMapping: (lang: LanguageList) =>
    AxiosInstance({
      url: '/gaps/fetch/afg',
      method: 'get',
      params: { ...Helpers.getFilterParams(), language: lang }
    }),
  getEvidenceMapping: (lang: LanguageList) =>
    AxiosInstance({
      url: '/gaps/fetch/evd',
      method: 'get',
      params: { ...Helpers.getFilterParams(), language: lang }
    }),
  getGapsDescription: (lang: LanguageList) =>
    AxiosInstance({
      url: '/gaps/fetch/all',
      method: 'get',
      params: { ...Helpers.getFilterParams(), language: lang }
    }),
  getAdditionalInfo: (lang: LanguageList) =>
    AxiosInstance({
      url: '/gaps/fetch/info',
      method: 'get',
      params: { ...Helpers.getFilterParams(), language: lang }
    }),
  addGaps: (data: IGapsInfo[], lang: LanguageList) =>
    AxiosInstance({
      url: '/gaps/save',
      method: 'post',
      params: { language: lang },
      data
    }),
  addGapsInfo: (data: ISaveInfo[]) =>
    AxiosInstance({
      url: '/gaps/save/info',
      method: 'post',
      data
    }),
  removeGap: (id: number) =>
    AxiosInstance({
      url: '/gaps/save',
      method: 'delete',
      params: { gap_id: id }
    }),
  getComments: (table: CommentTable) =>
    AxiosInstance({
      url: '/comment',
      method: 'get',
      params: { table }
    }),
  addComment: (data: CommentItem[]) =>
    AxiosInstance({
      url: '/comment',
      method: 'post',
      data
    }),
  removeComment: (comment_id: number) =>
    AxiosInstance({
      url: '/comment',
      method: 'delete',
      data: { comment_id }
    }),
  removeAudits: (params: { afg_id: number }) =>
    AxiosInstance({
      url: '/gaps/delete/afg',
      method: 'post',
      params
    }),
  removeEvidences: (params: { evd_id: number }) =>
    AxiosInstance({
      url: '/gaps/delete/evd',
      method: 'post',
      params
    }),
  applyFilters: (lang: LanguageList) =>
    AxiosInstance({
      url: '/filter',
      method: 'get',
      params: { language: lang, ...Helpers.getFilterParams() }
    }),
  setFiltersModule: (data: ISelectedOptions, lang: LanguageList) =>
    AxiosInstance({
      url: '/gaps/unassigned_evd',
      method: 'get',
      params: { language: lang },
      data
    }),
  saveAudits: (data: IAssignedEls[]) =>
    AxiosInstance({
      url: '/gaps/afg/save',
      method: 'post',
      data
    }),
  saveEvidences: (data: IAssignedEvidences[]) =>
    AxiosInstance({
      url: '/gaps/evd/save',
      method: 'post',
      data
    }),
  getAuditFindings: (lang: LanguageList) =>
    AxiosInstance({
      url: '/gaps/unassigned_audits',
      method: 'get',
      params: { language: lang, ...Helpers.getFilterParams() }
    }),
  getUnassignedEvidences: (lang: LanguageList) =>
    AxiosInstance({
      url: '/gaps/unassigned_evd',
      method: 'get',
      params: { language: lang, ...Helpers.getFilterParams() }
    }),
  getSolutions: (lang: LanguageList) =>
    AxiosInstance({
      url: '/solution/filter',
      method: 'get',
      params: { language: lang, ...Helpers.getFilterParams() }
    }),
  removeSolution: (sol_id: number | string) =>
    AxiosInstance({
      url: '/solution/save',
      method: 'delete',
      data: { sol_id }
    }),
  getSolutionApproverList: () =>
    AxiosInstance({
      url: '/solution/approvers',
      method: 'get'
    }),
  getSolutionTypes: (lang: LanguageList) =>
    AxiosInstance({
      url: '/filters/sol_types',
      method: 'get',
      params: { language: lang }
    }),
  saveSolutions: (solData: ISolution[], lang: LanguageList) =>
    AxiosInstance({
      url: '/solution/save',
      method: 'post',
      params: { language: lang },
      data: solData
    }),
  approveSolution: (solId: number | string) =>
    AxiosInstance({
      url: `/solution/approve/${solId}`,
      method: 'post'
    }),
  rejectSolution: (solId: number | string) =>
    AxiosInstance({
      url: `/solution/reject/${solId}`,
      method: 'post'
    }),
  getTasks: (lang: LanguageList) =>
    AxiosInstance({
      url: '/tasks/filter',
      method: 'get',
      params: { language: lang, ...Helpers.getFilterParams() }
    }),
  removeTask: (tsk_id: number) =>
    AxiosInstance({
      url: '/tasks/save',
      method: 'delete',
      params: { tsk_id }
    }),
  saveTasks: (data: ITask[], lang: LanguageList) =>
    AxiosInstance({
      url: '/tasks/save',
      method: 'post',
      params: { language: lang },
      data
    }),
  getEvidences: (lang: LanguageList) =>
    AxiosInstance({
      url: '/evidences/filter',
      method: 'get',
      params: { language: lang, ...Helpers.getFilterParams() }
    }),
  removeEvidenceDef: (evd_id: number | string) =>
    AxiosInstance({
      url: '/evidences/save',
      method: 'delete',
      params: { evd_id }
    }),
  saveEvidencesDef: (data: IEvidenceToShow[], lang: LanguageList) =>
    AxiosInstance({
      url: '/evidences/save',
      method: 'post',
      params: { language: lang },
      data
    }),
  getEvidenceDefApproverList: () =>
    AxiosInstance({
      url: '/evidences/approvers',
      method: 'get'
    }),
  approveEvidence: (evdId: number | string) =>
    AxiosInstance({
      url: `/evidences/approve/${evdId}`,
      method: 'post'
    }),
  rejectEvidence: (evdId: number | string) =>
    AxiosInstance({
      url: `/evidences/reject/${evdId}`,
      method: 'post'
    }),
  getMngmPrioritization: (lang: LanguageList) =>
    AxiosInstance({
      url: '/filters/prioritization',
      method: 'get',
      params: { language: lang }
    }),
  getAuditSource: (lang: LanguageList) =>
    AxiosInstance({
      url: '/filters/audit_source',
      method: 'get',
      params: { language: lang }
    }),
  getCategory: (lang: LanguageList) =>
    AxiosInstance({
      url: '/mgmt/fetch/categories',
      method: 'get',
      params: { language: lang }
    }),
  getProduct: (lang: LanguageList) =>
    AxiosInstance({
      url: '/mgmt/fetch/product_functions',
      method: 'get',
      params: { language: lang }
    }),
  removeAuditFindings: (params: { afg_id: number }) =>
    AxiosInstance({
      url: '/mgmt/delete/afg',
      method: 'delete',
      params
    }),
  getReports: (lang: LanguageList) =>
    AxiosInstance({
      url: '/report/fetch',
      method: 'get',
      params: { ...Helpers.getFilterParams(), language: lang }
    }),
  getMgmtAudits: (lang: LanguageList) =>
    AxiosInstance({
      url: '/mgmt/fetch/afg',
      method: 'get',
      params: { ...Helpers.getFilterParams(), language: lang }
    })
};

export default ApiService;
